import React from "react";
import Header from "../components/Header";

const styles = {
  fontFamily: "Inter, sans-serif",
};

const Refunds = () => {
  return (
    <div className="font-inter antialiased bg-white text-gray-900 tracking-tight">
      <Header />
      <div className="grow p-4">
        <section className="bg-gradient-to-b from-gray-50 to-blue-100 min-h-screen">
          <div className="max-w-4xl mx-auto py-12">
            <h1 className="text-2xl font-bold mb-4">Subscription Policy</h1>
            <p className="mb-4">
              At Ingena Technology Private Limited, we understand the importance
              of flexibility. That's why we offer a free trial period for our
              services, allowing you to explore and evaluate our offerings
              without any risk.
            </p>
            <p className="mb-4">
              Our subscription plans come with no long-term commitments. You
              have the freedom to upgrade, downgrade, or cancel your
              subscription at any time through your account settings. We want to
              ensure that our services always meet your needs, and we believe in
              giving you the control to make changes as necessary.
            </p>
            <p className="mb-4">
              Please note that while we offer a free trial period, we do not
              provide refunds for paid subscription plans once the trial period
              has ended. This policy helps us maintain our commitment to
              providing continuous and quality service to all our users.
            </p>
            <p className="mb-4">
              If you have any questions or concerns about our subscription
              policy, please feel free to contact us at{" "}
              <a
                href="mailto:business@needfinderai.com"
                className="text-blue-600"
              >
                business@needfinderai.com
              </a>
              . We are here to assist you and address any issues you may have.
            </p>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Refunds;
