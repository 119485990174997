import React, { useState, useEffect } from "react";
import ReportMobileMenu from "./ReportMobileMenu";
import { Link, useParams } from "react-router-dom";
import LogoutButton from "./LogoutButton";
import api from "./axiosConfig"; // Adjust the path as necessary
import Logo from "../images/thumbnail.png";

export default function ReportNavbar({ purchased }) {
  const [user, setUser] = useState(null);
  const [top, setTop] = useState(true);
  const { reportId } = useParams();
  const [isGeneratingPDF, setIsGeneratingPDF] = useState(false);
  const [isGeneratingDocs, setIsGeneratingDocs] = useState(false);
  const [docsUrl, setDocsUrl] = useState(null); // State to hold the URL of the generated Docs file

  // detect whether user has scrolled the page down by 10px
  // const scrollHandler = () => {
  //   window.pageYOffset > 10 ? setTop(false) : setTop(true);
  // };

  useEffect(() => {
    const token = localStorage.getItem("access");
    if (token) {
      setUser(true);
    }
  }, []);

  const saveReportAsPDF = async () => {
    try {
      setIsGeneratingPDF(true); // Set the state to true when starting the PDF generation

      // console.log("purchased is ---->", purchased);
      const response = await api.get(`/report/${reportId}/pdf`, {
        responseType: "blob",
      });
      // console.log("response is ---->", response);

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `report_${reportId}.pdf`);
      link.setAttribute("target", "_blank");
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.error("Error downloading the PDF:", error);
    } finally {
      setIsGeneratingPDF(false); // Reset the state either after successful PDF generation or on failure
    }
  };

  const saveReportAsDocs = async () => {
    try {
      setIsGeneratingDocs(true); // Set the state to true when starting the Docs generation

      const response = await api.get(`/report/${reportId}/docs/`, {
        responseType: "json",
      });

      // Assuming the response.data is the URL of the generated Docs file
      setDocsUrl(response.data.url); // Set the URL of the generated Docs file
    } catch (error) {
      console.error("Error opening the Docs:", error);
    } finally {
      setIsGeneratingDocs(false); // Reset the state either after successful Docs generation or on failure
    }
  };

  useEffect(() => {
    // Check if the Docs URL is available, then open it in a new tab
    if (docsUrl) {
      window.open(docsUrl, "_blank"); // Open the Docs URL in a new tab
    }
  }, [docsUrl]);

  return (
    <header
      className={`top-0 w-full z-30 md:bg-opacity-90 transition duration-300 ease-in-out z-20 ${
        !top ? "bg-white backdrop-blur-sm shadow-lg" : ""
      }`}
    >
      <div className="max-w-7xl mx-auto px-5 sm:px-6">
        <div className="flex items-center justify-between h-16 md:h-20">
          {/* Site branding */}

          <div className="shrink-0 mr-4">
            <Link
              to="/dashboard/"
              className="flex items-center text-2xl font-bold text-gray-800"
            >
              <img src={Logo} alt="Logo" className="h-10 mr-2" />
              <span className="font-bold text-green-600 h-8">FeedbackbyAI</span>
            </Link>
          </div>

          {/* Desktop navigation */}
          <nav className="hidden md:flex md:grow">
            {/* Desktop menu links */}
            <ul className="flex grow justify-end flex-wrap items-center">
              <li>
                <a
                  href="/feedback/"
                  className="bg-green-200 hover:bg-green-300 text-green-800 hover:text-green-900 px-4 py-2 rounded-full flex items-center transition duration-150 ease-in-out shadow"
                >
                  AI Character Feedback
                </a>
              </li>

              {/* <li>
                <a
                  href="/feedback-by-astrology/"
                  className="bg-green-200 hover:bg-green-300 text-green-800 hover:text-green-900 px-4 py-2 rounded-full flex items-center transition duration-150 ease-in-out shadow ml-5"
                >
                  Corporate Astrology
                </a>
              </li> */}
              {/* <li>
                <a
                  href="/it-development/"
                  className="bg-green-200 hover:bg-green-300 text-green-800 hover:text-green-900 px-4 py-2 rounded-full flex items-center transition duration-150 ease-in-out shadow ml-5"
                >
                  IT Development
                </a>
              </li> */}

              <li>
                <a
                  href="/credits/"
                  className="bg-green-200 hover:bg-green-300 text-green-800 hover:text-green-900 px-4 py-2 rounded-full flex items-center transition duration-150 ease-in-out shadow ml-5"
                >
                  Credits & Recharge
                </a>
              </li>

              {/* <li>
                <a
                  href="mailto:business@feedbackbyai.com" // This will open the user's default mail client with your email address
                  className="text-gray-600 hover:text-gray-900 px-3 lg:px-5 py-2 flex items-center transition duration-150 ease-in-out"
                >
                  Contact Us
                </a>
              </li> */}

              <li>
                {purchased ? (
                  isGeneratingPDF ? (
                    <span>PDF preparation is underway, please wait...</span>
                  ) : (
                    <li
                      className="bg-green-200 hover:bg-green-300 text-green-800 hover:text-green-900 px-4 py-2 rounded-full flex items-center transition duration-150 ease-in-out shadow ml-5"
                      onClick={saveReportAsPDF}
                    >
                      Save as PDF
                    </li>
                  )
                ) : null}
              </li>
              {/* New link for saving as Docs */}
              <li>
                {purchased ? (
                  isGeneratingDocs ? (
                    <span>Generating Docs, please wait...</span>
                  ) : (
                    <li
                      className="bg-green-200 hover:bg-green-300 text-green-800 hover:text-green-900 px-4 py-2 rounded-full flex items-center transition duration-150 ease-in-out shadow ml-5"
                      onClick={saveReportAsDocs}
                    >
                      Save as Docs
                    </li>
                  )
                ) : null}
              </li>
            </ul>

            {/* Desktop sign in links */}
            <ul className="flex grow justify-end flex-wrap items-center">
              <li>
                <a
                  href="/signin/"
                  className="btn-sm text-gray-200  bg-green-600 hover:bg-green-700 ml-3"
                >
                  <span>
                    {" "}
                    {user && (
                      <div className="text-right  pr-4">
                        <LogoutButton setUser={setUser} />
                      </div>
                    )}
                  </span>
                </a>
              </li>
            </ul>
          </nav>

          <ReportMobileMenu
            purchased={purchased}
            saveReportAsPDF={saveReportAsPDF}
            isGeneratingPDF={isGeneratingPDF}
          />
        </div>
      </div>
    </header>
  );
}
