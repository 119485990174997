import React, { useState, useEffect } from "react";
import axios from "./axiosConfig";
import { Bookmark, ThumbsUp, Zap, Users, TrendingUp, ChevronDown, ChevronUp, Send } from "lucide-react";

const BookmarksTab = ({ userDetails }) => {
  const [bookmarks, setBookmarks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [expandedCards, setExpandedCards] = useState([]);

  useEffect(() => {
    const fetchBookmarks = async () => {
      try {
        const response = await axios.get("/list-bookmarks/");
        setBookmarks(response.data);
      } catch (error) {
        console.error("Error fetching bookmarks:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchBookmarks();
  }, []);

  const toggleExpand = (index) => {
    setExpandedCards((prev) => (prev.includes(index) ? prev.filter((i) => i !== index) : [...prev, index]));
  };

  const handleToggleLike = async (painpointId) => {
    try {
      await axios.post(`/toggle-like/${painpointId}/`);
      // Update the local state to reflect the change
      setBookmarks(bookmarks.map((bookmark) => (bookmark.id === painpointId ? { ...bookmark, is_liked: !bookmark.is_liked } : bookmark)));
    } catch (error) {
      console.error("Error toggling like:", error);
    }
  };

  const handleToggleBookmark = async (painpointId) => {
    try {
      await axios.post(`/toggle-bookmark/${painpointId}/`);
      // Remove the bookmark from the list
      setBookmarks(bookmarks.filter((bookmark) => bookmark.id !== painpointId));
    } catch (error) {
      console.error("Error toggling bookmark:", error);
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-64">
        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  return (
    <div className="space-y-8 max-w-4xl mx-auto">
      <h2 className="text-4xl font-bold text-gray-900 mb-8">Your Bookmarked Insights</h2>
      {bookmarks.length === 0 ? (
        <div className="text-center py-12">
          <Bookmark className="mx-auto text-gray-400 mb-4" size={48} />
          <p className="text-gray-600 text-lg">You haven't bookmarked any insights yet.</p>
        </div>
      ) : (
        <div className="space-y-8">
          {bookmarks.map((bookmark, index) => (
            <div
              key={bookmark.id}
              className="bg-white rounded-2xl shadow-lg overflow-hidden transition-all duration-300 hover:shadow-xl border border-gray-100"
            >
              <div className="p-8">
                <div className="flex justify-between items-start mb-6">
                  <h3 className="text-2xl font-bold text-gray-900 leading-tight pr-4">{bookmark.details.pain_point}</h3>
                  <div className="flex space-x-2">
                    <button
                      className={`p-2 rounded-full ${
                        bookmark.is_liked ? "bg-green-100 text-green-600" : "bg-gray-100 text-gray-600"
                      } hover:bg-green-200 transition-colors duration-200`}
                      onClick={() => handleToggleLike(bookmark.id)}
                    >
                      <ThumbsUp size={20} />
                    </button>
                    <button
                      className="p-2 rounded-full bg-blue-100 text-blue-600 hover:bg-blue-200 transition-colors duration-200"
                      onClick={() => handleToggleBookmark(bookmark.id)}
                    >
                      <Bookmark size={20} />
                    </button>
                  </div>
                </div>

                <div className="mb-8">
                  <p className="text-gray-700 bg-gray-50 p-6 rounded-xl border-l-4 border-blue-500 text-lg italic">
                    "{bookmark.details.problem_synopsis}"
                  </p>
                </div>

                <div className="grid grid-cols-3 gap-6 mb-8">
                  <div className="bg-blue-50 p-4 rounded-xl text-center">
                    <Zap className="text-blue-500 text-3xl mx-auto mb-2" />
                    <span className="block text-sm font-medium text-gray-600 mb-1">AI Confidence</span>
                    <span className="block text-xl font-bold text-gray-900">{bookmark.details.mentions}</span>
                  </div>
                  <div className="bg-green-50 p-4 rounded-xl text-center">
                    <Users className="text-green-500 text-3xl mx-auto mb-2" />
                    <span className="block text-sm font-medium text-gray-600 mb-1">Users Affected</span>
                    <span className="block text-xl font-bold text-gray-900">{bookmark.details.users_affected}</span>
                  </div>
                  <div className="bg-purple-50 p-4 rounded-xl text-center">
                    <TrendingUp className="text-purple-500 text-3xl mx-auto mb-2" />
                    <span className="block text-sm font-medium text-gray-600 mb-1">Frequency</span>
                    <span className="block text-xl font-bold text-gray-900">{bookmark.details.frequency}</span>
                  </div>
                </div>

                <div className="mb-8">
                  <h4 className="text-lg font-semibold mb-2 text-gray-800">Expert Insights</h4>
                  <p className="text-gray-700">{bookmark.details.expert_insights}</p>
                </div>

                {/* <div className="grid grid-cols-2 gap-8 mb-8">
                  <div>
                    <h4 className="text-lg font-semibold mb-2 text-gray-800">Market Opportunity</h4>
                    <ul className="list-disc pl-6 text-gray-700 space-y-2">
                      {bookmark.details.market_opportunity.map((item, idx) => (
                        <li key={idx}>{item}</li>
                      ))}
                    </ul>
                  </div>
                  <div>
                    <h4 className="text-lg font-semibold mb-2 text-gray-800">Revenue Potential</h4>
                    <ul className="list-disc pl-6 text-gray-700 space-y-2">
                      {bookmark.details.revenue_potential.map((item, idx) => (
                        <li key={idx}>{item}</li>
                      ))}
                    </ul>
                  </div>
                </div> */}

                <div className="flex space-x-4 mb-8">
                  <button
                    onClick={() => toggleExpand(index)}
                    className="flex-1 bg-blue-600 text-white py-3 px-6 rounded-xl hover:bg-blue-700 transition-all duration-200 flex items-center justify-center shadow-md text-lg font-semibold"
                  >
                    {expandedCards.includes(index) ? (
                      <>
                        <ChevronUp size={24} className="mr-2" />
                        Hide Additional Details
                      </>
                    ) : (
                      <>
                        <ChevronDown size={24} className="mr-2" />
                        View Additional Details
                      </>
                    )}
                  </button>
                  {/* <button className="flex-1 bg-green-600 text-white py-3 px-6 rounded-xl hover:bg-green-700 transition-all duration-200 flex items-center justify-center shadow-md text-lg font-semibold">
                    <Send size={24} className="mr-2" /> Spark an Idea
                  </button> */}
                </div>

                {expandedCards.includes(index) && (
                  <div className="space-y-8">
                    <div className="bg-blue-50 p-6 rounded-xl">
                      <h4 className="text-lg font-semibold mb-3 text-gray-800">Competitive Landscape</h4>
                      <ul className="list-disc pl-6 text-gray-700 space-y-2">
                        {bookmark.details.competitive_landscape.map((item, idx) => (
                          <li key={idx}>{item}</li>
                        ))}
                      </ul>
                    </div>
                    <div className="bg-green-50 p-6 rounded-xl">
                      <h4 className="text-lg font-semibold mb-3 text-gray-800">Customer Segmentation</h4>
                      <ul className="list-disc pl-6 text-gray-700 space-y-2">
                        {bookmark.details.customer_segmentation.map((item, idx) => (
                          <li key={idx}>{item}</li>
                        ))}
                      </ul>
                    </div>
                    <div className="bg-yellow-50 p-6 rounded-xl">
                      <h4 className="text-lg font-semibold mb-3 text-gray-800">Implementation Roadmap</h4>
                      <ol className="list-decimal pl-6 text-gray-700 space-y-2">
                        {bookmark.details.implementation_roadmap.map((item, idx) => (
                          <li key={idx}>{item}</li>
                        ))}
                      </ol>
                    </div>
                    <div className="bg-red-50 p-6 rounded-xl">
                      <h4 className="text-lg font-semibold mb-3 text-gray-800">Risk Assessment</h4>
                      <ul className="list-disc pl-6 text-gray-700 space-y-2">
                        {bookmark.details.risk_assessment.map((item, idx) => (
                          <li key={idx}>{item}</li>
                        ))}
                      </ul>
                    </div>
                    <div className="bg-indigo-50 p-6 rounded-xl">
                      <h4 className="text-lg font-semibold mb-3 text-gray-800">Success Metrics</h4>
                      <ul className="list-disc pl-6 text-gray-700 space-y-2">
                        {bookmark.details.success_metrics.map((item, idx) => (
                          <li key={idx}>{item}</li>
                        ))}
                      </ul>
                    </div>
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default BookmarksTab;
