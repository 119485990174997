// import React, { useState } from "react";
// import { Link } from "react-router-dom";
// import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
// import { auth } from "../firebaseConfig"; // Ensure this path is correct
// import firebase from "firebase/app";
// import api from "./axiosConfig"; // Adjust the path as necessary
// import { FcGoogle } from "react-icons/fc"; // Importing the Google icon

// const SignIn = () => {
//   const [email, setEmail] = useState("");
//   const [password, setPassword] = useState("");
//   const [errorMsg, setErrorMsg] = useState("");

//   const handleEmailPasswordSubmit = async (e) => {
//     e.preventDefault();
//     try {
//       const response = await api.post("login/", { email, password });
//       if (response.status === 200) {
//         localStorage.setItem("access", response.data.access);
//         window.location.href = "/dashboard/";
//       } else {
//         setErrorMsg(response.data.error || "Something went wrong.");
//       }
//     } catch (error) {
//       setErrorMsg(error.response?.data.error || "Something went wrong.");
//     }
//   };

//   const handleGoogleSignIn = async () => {
//     // const provider = new firebase.auth.GoogleAuthProvider();
//     const provider = new GoogleAuthProvider();
//     try {
//       // const result = await auth.signInWithPopup(provider);
//       const result = await signInWithPopup(auth, provider);
//       // const token = result.credential.accessToken;
//       const token = result.user.accessToken;
//       const response = await api.post("/google-login/", { token });
//       if (response.status === 200) {
//         localStorage.setItem("access", response.data.access);
//         window.location.href = "/dashboard/";
//       } else {
//         setErrorMsg(response.data.error || "Something went wrong.");
//       }
//     } catch (error) {
//       setErrorMsg(error.message || "Failed to sign in with Google.");
//     }
//   };

//   return (
//     <section className="bg-gradient-to-b from-gray-50 to-green-100 ">
//       <div className="max-w-6xl mx-auto px-4 sm:px-6 flex-1 flex flex-col justify-center">
//         {/* Navigation Bar */}
//         <nav className="py-4 mb-8">
//           <div className="container mx-auto">
//             <div className="flex justify-start items-center">
//               <Link to="/" className="text-2xl font-bold text-green-600">
//                 NeedFinderAI
//               </Link>
//             </div>
//           </div>
//         </nav>

//         {/* Sign In Form */}
//         <div className="pt-12 pb-12 md:pt-10 md:pb-10">
//           <div className="max-w-3xl mx-auto text-center pb-12 md:pb-10">
//             <h1 className="text-3xl font-bold text-gray-800">
//               Welcome back to NeedFinderAI
//             </h1>
//           </div>

//           <div className="max-w-md mx-auto bg-white shadow-lg rounded-lg p-8">
//             {/* Google Sign In Button */}
//             <div className="flex flex-wrap -mx-3 mt-3">
//               <div className="w-full px-3">
//                 <button
//                   onClick={handleGoogleSignIn}
//                   className="flex items-center justify-center text-black bg-white border border-gray-300 hover:bg-gray-100 w-full py-3 px-4 rounded"
//                   style={{ boxShadow: "0 2px 4px 0 rgba(0,0,0,0.1)" }}
//                 >
//                   <FcGoogle className="text-2xl mr-3" /> {/* Google Icon */}
//                   Sign in with Google
//                 </button>
//               </div>
//             </div>

//             {/* Divider */}
//             <div className="flex items-center my-6">
//               <div
//                 className="border-t border-gray-300 flex-grow mr-3"
//                 aria-hidden="true"
//               ></div>
//               <div className="text-gray-600 italic">Or</div>
//               <div
//                 className="border-t border-gray-300 flex-grow ml-3"
//                 aria-hidden="true"
//               ></div>
//             </div>

//             <form onSubmit={handleEmailPasswordSubmit}>
//               {/* Email Field */}
//               <div className="flex flex-wrap -mx-3 mb-4">
//                 <div className="w-full px-3">
//                   {errorMsg && (
//                     <p className="text-red-500 text-sm mt-2">{errorMsg}</p>
//                   )}
//                   <label
//                     className="block text-gray-800 text-sm font-medium mb-1"
//                     htmlFor="email"
//                   >
//                     Email
//                   </label>
//                   <input
//                     id="email"
//                     type="email"
//                     className="form-input w-full text-gray-800 border border-gray-300 rounded-lg h-12"
//                     placeholder="Enter your email address"
//                     value={email}
//                     onChange={(e) => setEmail(e.target.value)}
//                     required
//                   />
//                 </div>
//               </div>

//               {/* Password Field */}
//               <div className="flex flex-wrap -mx-3 mb-4">
//                 <div className="w-full px-3">
//                   <label
//                     className="block text-gray-800 text-sm font-medium mb-1"
//                     htmlFor="password"
//                   >
//                     Password
//                   </label>
//                   <input
//                     id="password"
//                     type="password"
//                     className="form-input w-full text-gray-800 border border-gray-300 rounded-lg h-12"
//                     placeholder="Enter your password"
//                     value={password}
//                     onChange={(e) => setPassword(e.target.value)}
//                     required
//                   />
//                 </div>
//               </div>

//               <div className="flex flex-wrap -mx-3 mb-4">
//                 <div className="w-full px-3">
//                   <div className="flex justify-between items-center">
//                     <Link
//                       to="/forgot-password"
//                       className="text-sm font-medium text-green-600 hover:underline"
//                     >
//                       Having trouble signing in?
//                     </Link>
//                   </div>
//                 </div>
//               </div>

//               {/* Sign In Button */}
//               <div className="flex flex-wrap -mx-3 mt-6">
//                 <div className="w-full px-3">
//                   <button className="btn text-white bg-green-600 hover:bg-green-700 w-full py-3 rounded">
//                     Sign in
//                   </button>
//                 </div>
//               </div>
//             </form>

//             {/* Sign Up Link */}
//             <div className="text-gray-600 text-center mt-6">
//               Don't have an account?{" "}
//               <Link
//                 to="/signup"
//                 className="text-green-600 hover:underline transition duration-150 ease-in-out"
//               >
//                 Sign up
//               </Link>
//             </div>
//           </div>
//         </div>
//       </div>
//     </section>
//   );
// };

// export default SignIn;

import React, { useState } from "react";
import { Link } from "react-router-dom";
import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { auth } from "../firebaseConfig";
import api from "./axiosConfig";
import { FcGoogle } from "react-icons/fc";

const SignIn = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMsg, setErrorMsg] = useState("");

  const handleEmailPasswordSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await api.post("login/", { email, password });
      if (response.status === 200) {
        localStorage.setItem("access", response.data.access);
        window.location.href = "/dashboard/";
      } else {
        setErrorMsg(response.data.error || "Something went wrong.");
      }
    } catch (error) {
      setErrorMsg(error.response?.data.error || "Something went wrong.");
    }
  };

  const handleGoogleSignIn = async () => {
    const provider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);
      const token = result.user.accessToken;
      const response = await api.post("/google-login/", { token });
      if (response.status === 200) {
        localStorage.setItem("access", response.data.access);
        window.location.href = "/dashboard/";
      } else {
        setErrorMsg(response.data.error || "Something went wrong.");
      }
    } catch (error) {
      setErrorMsg(error.message || "Failed to sign in with Google.");
    }
  };

  return (
    <section className="bg-gradient-to-b from-blue-50 via-white to-blue-50 min-h-screen flex items-center justify-center">
      <div className="max-w-lg w-full mx-auto p-8">
        <div className="text-center mb-10">
          <Link to="/" className="inline-block mb-4">
            <span className="text-4xl font-extrabold text-green-500">NeedFinderAI</span>
          </Link>
          <p className="text-gray-600 mt-2"> Signin to NeedFinderAI</p>
        </div>

        <div className="bg-white p-8 rounded-lg shadow-lg">
          <button
            onClick={handleGoogleSignIn}
            className="w-full flex items-center justify-center text-black bg-white border border-gray-300 hover:bg-gray-100 py-3 px-4 rounded-lg shadow-md mb-6 transition-all duration-300"
          >
            <FcGoogle className="text-2xl mr-2" />
            Sign in with Google
          </button>

          <div className="flex items-center justify-center mb-6">
            <span className="h-px w-full bg-gray-300"></span>
            <span className="px-4 text-gray-600">Or</span>
            <span className="h-px w-full bg-gray-300"></span>
          </div>
          {/* 
          <form onSubmit={handleEmailPasswordSubmit}>
            {errorMsg && <div className="text-red-500 text-sm mb-4 text-center">{errorMsg}</div>}
            <div className="mb-4">
              <label htmlFor="email" className="block text-sm font-semibold text-gray-700">
                Email
              </label>
              <input
                id="email"
                type="email"
                className="form-input mt-1 block w-full h-12 rounded-lg border border-gray-300 p-3 text-gray-800"
                placeholder="Enter your email address"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>

            <div className="mb-4">
              <label htmlFor="password" className="block text-sm font-semibold text-gray-700">
                Password
              </label>
              <input
                id="password"
                type="password"
                className="form-input mt-1 block w-full h-12 rounded-lg border border-gray-300 p-3 text-gray-800"
                placeholder="Enter your password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>

            <div className="flex items-center justify-between mb-4">
              <Link to="/forgot-password" className="text-sm font-medium text-green-500 hover:underline">
                Forgot Password?
              </Link>
            </div>

            <button
              type="submit"
              className="w-full py-3 px-4 text-white bg-green-500 hover:bg-green-600 rounded-lg font-semibold shadow-md transition-all duration-300"
            >
              Sign In
            </button>
          </form> */}

          <div className="flex items-center justify-center mt-8">
            <span className="text-sm text-gray-600">Don't have an account?</span>
            <Link to="/signup" className="text-sm font-medium text-green-500 hover:underline ml-1">
              Sign up
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SignIn;
