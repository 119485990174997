import React, { useState, useEffect } from "react";
import axios from "./axiosConfig";
import { Zap, Briefcase, Coffee, CheckCircle, MessageCircle, Rocket, Loader2, Check } from "lucide-react";

const SubscriptionsTab = ({ userDetails, setUserDetails }) => {
  const [currentPlan, setCurrentPlan] = useState(userDetails?.current_plan || "Free");
  const [isAnnual, setIsAnnual] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [plans, setPlans] = useState([]);

  useEffect(() => {
    if (userDetails) {
      setCurrentPlan(userDetails.current_plan || "Free");
    }

    // Fetch available plans from the backend
    const fetchPlans = async () => {
      try {
        const response = await axios.get("plans/");
        const filteredPlans = response.data.filter((plan) => !["Free", "Starter"].includes(plan.name));

        // Hardcode the features based on plan name
        const updatedPlans = filteredPlans.map((plan) => {
          let features = [];
          if (plan.name.includes("Innovator")) {
            features = [
              // { icon: MessageCircle, text: "2 Keywords Monitored" },
              // { icon: Check, text: "250 AI Insights per month" },
              // { icon: Check, text: "5 Full Business Report" },
              // { icon: Check, text: "10 Spark an Idea Credits" },
              // { icon: Check, text: "10 Feedback Submissions" },
              // { icon: Check, text: "14 day access to virtual coworking space" },
              { icon: Check, text: "120 AI Mentions per month" },
              { icon: Check, text: "3 keywords" },
              { icon: Check, text: "120 AI suggested Replies" },
              { icon: Check, text: "Tracks both Reddit & Twitter" },
              // { icon: Rocket, text: "Instant Alerts (Coming Soon)" },
            ];
          } else if (plan.name.includes("Starter")) {
            features = [
              // { icon: MessageCircle, text: "2 Keywords Monitored" },
              { icon: Check, text: "50 AI Insights per month" },
              { icon: Check, text: "1 Full Business Report" },
              { icon: Check, text: "3 Spark an Idea Credits" },
              { icon: Check, text: "3 Feedback Submissions" },
              { icon: Check, text: "7 day access to virtual coworking space" },
              { icon: Check, text: "120 AI Mentions per month" },
              // { icon: MessageCircle, text: "Instant Alerts (Coming Soon)" },
              // { icon: Rocket, text: "AI-Powered Roadmap (Coming Soon)" },
            ];
          } else if (plan.name.includes("Visionary")) {
            features = [
              // { icon: Check, text: "500 AI Insights per month" },
              // { icon: Check, text: "10 Full Business Report" },
              // { icon: Check, text: "20 Spark an Idea Credits" },
              // { icon: Check, text: "15 Feedback Submissions" },
              // { icon: Check, text: "21 day access to virtual coworking space" },
              { icon: Check, text: "200 AI Mentions per month" },
              { icon: Check, text: "5 keywords" },
              { icon: Check, text: "200 AI suggested Replies" },
              { icon: Check, text: "Tracks both Reddit & Twitter" },
            ];
          } else if (plan.name.includes("Pioneer")) {
            features = [
              // { icon: Check, text: "1500 AI Insights per month" },
              // { icon: Check, text: "25 Full Business Report" },
              // { icon: Check, text: "50 Spark an Idea Credits" },
              // { icon: Check, text: "30 Feedback Submissions" },
              // { icon: Check, text: "30 day access to virtual coworking space" },
              { icon: Check, text: "500 AI Mentions per month" },
              { icon: Check, text: "10 keywords" },
              { icon: Check, text: "500 AI suggested Replies" },
              { icon: Check, text: "Tracks both Reddit & Twitter" },
            ];
          }

          return { ...plan, features };
        });

        setPlans(updatedPlans);
      } catch (error) {
        console.error("Failed to fetch plans:", error);
      }
    };

    fetchPlans();
  }, [userDetails]);

  const handleUpgrade = async (planName) => {
    setLoading(true);
    setSelectedPlan(planName);

    const newPlanIndex = plans.findIndex((plan) => plan.name === planName);
    const currentPlanIndex = plans.findIndex((plan) => plan.name === currentPlan);
    const action = newPlanIndex > currentPlanIndex ? "Upgrade" : "Downgrade";

    console.log(`${action} to ${planName} plan (${isAnnual ? "Annual" : "Monthly"})`);

    try {
      const response = await axios.post("manage-subscription/", {
        plan_id: plans.find((plan) => plan.name === planName).id,
        action: action.toLowerCase(),
        billing_cycle: isAnnual ? "annual" : "monthly",
      });

      // Initiate Razorpay checkout
      const options = {
        key: response.data.razorpay_key,
        subscription_id: response.data.subscription_id,
        name: response.data.subscription_name,
        description: response.data.subscription_description,
        prefill: {
          // name: response.data.user_name,
          email: response.data.user_email,
        },
        notes: {
          user_id: userDetails.id,
        },
        theme: {
          color: "#22C55E",
        },
        handler: function (response) {
          alert("Payment successful!");
          setUserDetails((prevDetails) => ({
            ...prevDetails,
            current_plan: planName.replace("Yearly", ""),
          }));
          setCurrentPlan(planName.replace("Yearly", ""));
        },
        modal: {
          ondismiss: function () {
            console.log("Checkout form closed");
          },
        },
      };

      const rzp1 = new window.Razorpay(options);
      rzp1.open();
      setLoading(false);
    } catch (error) {
      console.error(`Failed to ${action.toLowerCase()} subscription:`, error);
      setLoading(false);
    }
  };

  const handleCancelSubscription = async () => {
    setLoading(true);
    try {
      const response = await axios.post("manage-subscription/", { action: "cancel" });
      alert("Subscription cancelled successfully");
      setCurrentPlan("Free");
      setUserDetails((prevDetails) => ({
        ...prevDetails,
        current_plan: "Free",
      }));
    } catch (error) {
      console.error("Failed to cancel subscription:", error);
      alert("Failed to cancel subscription");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      <div className="text-center mb-16">
        <h2 className="text-4xl sm:text-5xl md:text-5xl font-bold text-gray-900 mb-4">Choose Your Plan</h2>
        {/* <p className="text-xl text-gray-600 max-w-3xl mx-auto">
          Uncover hidden opportunities, outpace competitors, and build products your customers love.
        </p> */}
      </div>

      <div className="bg-green-50 border border-green-200 rounded-2xl p-6 mb-12">
        <p className="text-sm sm:text-base text-green-800 font-semibold flex items-center">
          <CheckCircle className="mr-2 text-green-600" size={20} />
          {/* Money-back guarantee if you don't find at least one game-changing insight in your first week! */}
          Money-back guarantee if you don't find at least one customer in your first week!
        </p>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        {/* {plans.map((plan) => {
          const newPlanIndex = plans.findIndex((p) => p.name === plan.name);
          const currentPlanIndex = plans.findIndex((p) => p.name === currentPlan);
          return (
            <div
              key={plan.name}
              className={`bg-white rounded-3xl shadow-xl overflow-hidden transition-all duration-300 ${
                currentPlan === plan.name ? "ring-4 ring-blue-400" : "hover:shadow-2xl hover:-translate-y-1"
              }`}
            >
              <div className="p-8">
                <h3 className="text-2xl font-bold text-gray-900 mb-4">{plan.name.replace("Yearly", "")}</h3>
                <p className="text-gray-600 mb-6 h-20 text-sm sm:text-base">{plan.description}</p>
                <p className="text-4xl font-bold text-gray-900 mb-6">
                  <p className="text-4xl font-bold text-gray-900 mb-6">
                    ${isAnnual ? plan.price_annual : plan.price_monthly}
                    <span className="text-xl font-normal text-gray-500">/{isAnnual ? "yr" : "mo"}</span>
                  </p>
                </p>
                <ul className="space-y-4 mb-8">
                  {plan.features.map((feature, index) => (
                    <li key={index} className="flex items-start">
                      <feature.icon className="text-blue-500 mr-3 flex-shrink-0 mt-1" size={18} />
                      <span className="text-gray-700 text-sm sm:text-base">{feature.text}</span>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="px-8 pb-8">
                <button
                  onClick={() => handleUpgrade(plan.name)}
                  className={`w-full py-4 rounded-full font-bold text-sm sm:text-base transition-all duration-300 ${
                    currentPlan === plan.name ? "bg-gray-200 text-gray-500 cursor-not-allowed" : "bg-blue-600 text-white hover:bg-blue-700"
                  }`}
                  disabled={currentPlan === plan.name || loading}
                >
                  {loading && selectedPlan === plan.name ? (
                    <Loader2 className="animate-spin mx-auto" size={24} />
                  ) : currentPlan === plan.name ? (
                    "Current Plan"
                  ) : (
                    `${newPlanIndex > currentPlanIndex ? "Upgrade" : "Switch Plan"}`
                  )}
                </button>
              </div>
            </div>
          );
        })} */}
        {plans.map((plan) => {
          const newPlanIndex = plans.findIndex((p) => p.name === plan.name);
          const currentPlanIndex = plans.findIndex((p) => p.name === currentPlan);
          const isPopular = plan.name.includes("Visionary");
          return (
            <div
              key={plan.name}
              className={`bg-white rounded-3xl shadow-xl overflow-hidden transition-all duration-300 relative ${
                currentPlan === plan.name ? "ring-4 ring-blue-400" : "hover:shadow-2xl hover:-translate-y-1"
              }`}
            >
              {isPopular && (
                <div className="absolute top-0 right-0 bg-green-500 text-white py-1 px-4 rounded-bl-lg text-sm font-bold">Most Popular</div>
              )}
              <div className="p-8">
                <h3 className="text-2xl font-bold text-gray-900 mb-4">{plan.name.replace("Yearly", "")}</h3>
                <p className="text-gray-600 mb-6 h-20 text-sm sm:text-base">{plan.description}</p>
                <p className="text-4xl font-bold text-gray-900 mb-6">
                  ${isAnnual ? plan.price_annual : plan.price_monthly}
                  <span className="text-xl font-normal text-gray-500">/{isAnnual ? "yr" : "mo"}</span>
                </p>
                <ul className="space-y-4 mb-8">
                  {plan.features.map((feature, index) => (
                    <li key={index} className="flex items-start">
                      <feature.icon className="w-5 h-5 text-green-500 mr-2 flex-shrink-0" size={18} />
                      <span className="text-gray-700 text-sm sm:text-base">{feature.text}</span>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="px-8 pb-8">
                <button
                  onClick={() => handleUpgrade(plan.name)}
                  className={`w-full py-4 rounded-full font-bold text-sm sm:text-base transition-all duration-300 ${
                    currentPlan === plan.name
                      ? "bg-gray-200 text-gray-500 cursor-not-allowed"
                      : "bg-green-500 text-white hover:bg-green-700"
                  }`}
                  disabled={currentPlan === plan.name || loading}
                >
                  {loading && selectedPlan === plan.name ? (
                    <Loader2 className="animate-spin mx-auto" size={24} />
                  ) : currentPlan === plan.name ? (
                    "Current Plan"
                  ) : (
                    `${newPlanIndex > currentPlanIndex ? "Upgrade" : "Switch Plan"}`
                  )}
                </button>
              </div>
            </div>
          );
        })}
      </div>

      {currentPlan !== "Free" && (
        <div className="mt-16 text-center">
          <button
            onClick={handleCancelSubscription}
            className="bg-white text-red-600 border border-red-600 py-3 px-8 rounded-full font-bold text-sm sm:text-base hover:bg-red-50 transition-all duration-300"
            disabled={loading}
          >
            {loading ? <Loader2 className="animate-spin mx-auto" size={24} /> : "Cancel Subscription"}
          </button>
        </div>
      )}

      {/* <div className="mt-20 bg-gray-50 rounded-3xl p-8 sm:p-12">
        <h4 className="text-2xl font-bold text-gray-900 mb-6">Why NeedFinderAI?</h4>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
          <div className="flex items-start">
            <Zap className="text-yellow-500 mr-4 flex-shrink-0" size={24} />
            <div>
              <h5 className="font-semibold text-gray-900 mb-1">Uncover Hidden Gems</h5>
              <p className="text-gray-600 text-sm">Find untapped market opportunities your competitors haven't spotted.</p>
            </div>
          </div>
          <div className="flex items-start">
            <Briefcase className="text-blue-500 mr-4 flex-shrink-0" size={24} />
            <div>
              <h5 className="font-semibold text-gray-900 mb-1">Data-Driven Decisions, Not Guesswork</h5>
              <p className="text-gray-600 text-sm">Base your strategy on real-time market intelligence and customer insights.</p>
            </div>
          </div>
          <div className="flex items-start">
            <Coffee className="text-brown-500 mr-4 flex-shrink-0" size={24} />
            <div>
              <h5 className="font-semibold text-gray-900 mb-1">Fuel Your Innovation Engine</h5>
              <p className="text-gray-600 text-sm">Generate breakthrough ideas and validate concepts with AI-powered suggestions</p>
            </div>
          </div>
          <div className="flex items-start">
            <Rocket className="text-red-500 mr-4 flex-shrink-0" size={24} />
            <div>
              <h5 className="font-semibold text-gray-900 mb-1">Grow at Your Own Pace</h5>
              <p className="text-gray-600 text-sm">Flexible plans that scale with your business, from startup to enterprise.</p>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default SubscriptionsTab;
