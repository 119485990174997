// import React, { useState, useEffect } from "react";
// import { motion, AnimatePresence } from "framer-motion";
// import {
//   ArrowRight,
//   Menu,
//   X,
//   Search,
//   Zap,
//   Shield,
//   RefreshCw,
//   Users,
//   Target,
//   Bell,
//   Rocket,
//   DollarSign,
//   ChevronDown,
//   ChevronUp,
//   Check,
//   Shuffle,
//   Layers,
//   Database,
//   BarChart,
//   Clock,
//   Smile,
//   Timer,
// } from "lucide-react";
// import { Link } from "react-router-dom";

// // Assuming you have these images in your project
// import img1 from "../images/img311.png";
// import Logo from "../images/thumbnail.png";
// import FeatureImage1 from "../images/marketresearch3.png";
// import FeatureImage2 from "../images/coworking2.png";
// import FeatureImage3 from "../images/feedback2.png";
// import FeatureImage4 from "../images/aimarketing1.png";
// import FeatureImage5 from "../images/businessplan.png";
// import IframeResizer from "iframe-resizer-react";

// const NavLink = ({ href, children }) => (
//   <motion.a
//     href={href}
//     className="text-gray-800 hover:text-blue-600 transition-colors duration-300"
//     whileHover={{ scale: 1.05 }}
//     whileTap={{ scale: 0.95 }}
//   >
//     {children}
//   </motion.a>
// );

// const FeatureCard = ({ icon, title, description }) => (
//   <div className="flex items-start space-x-4">
//     <div className="flex-shrink-0">
//       <div className="bg-green-100 rounded-full p-3">{icon}</div>
//     </div>
//     <div>
//       <h3 className="text-lg font-semibold text-gray-800 mb-1">{title}</h3>
//       <p className="text-gray-600">{description}</p>
//     </div>
//   </div>
// );

// const PricingCard = ({ name, price, description, features, isPopular }) => {
//   const [currency, setCurrency] = useState("$");
//   const exchangeRates = {
//     $: 1,
//     "€": 0.91,
//     "£": 0.79,
//     "₹": 82.98,
//   };

//   const convertPrice = (priceInUSD) => {
//     const convertedPrice = priceInUSD * exchangeRates[currency];
//     return currency === "₹" ? Math.round(convertedPrice) : convertedPrice.toFixed(2);
//   };

//   return (
//     <motion.div
//       className={`bg-white rounded-2xl shadow-lg p-8 flex flex-col transition-all duration-300 hover:shadow-xl border ${
//         isPopular ? "border-blue-500" : "border-gray-100"
//       }`}
//       whileHover={{ y: -10 }}
//       initial={{ opacity: 0, y: 20 }}
//       animate={{ opacity: 1, y: 0 }}
//       transition={{ duration: 0.5 }}
//     >
//       {isPopular && (
//         <div className="bg-green-500 text-white py-1 px-4 rounded-full text-sm font-semibold self-start mb-4">Most Popular</div>
//       )}
//       <h3 className="text-2xl font-bold mb-2">{name}</h3>
//       <p className="text-gray-600 mb-6">{description}</p>
//       <div className="flex items-center mb-6">
//         <select className="mr-2 p-1 border rounded text-sm" value={currency} onChange={(e) => setCurrency(e.target.value)}>
//           <option value="$">$</option>
//           <option value="€">€</option>
//           <option value="£">£</option>
//           <option value="₹">₹</option>
//         </select>
//         <p className="text-4xl font-bold">
//           {convertPrice(parseInt(price))}
//           <span className="text-xl font-normal text-gray-600">/mo</span>
//         </p>
//       </div>
//       <p className="text-xs text-gray-500 mb-6">*Approx. conversion</p>
//       <ul className="mb-8 flex-grow">
//         {features.map((feature, i) => (
//           <li key={i} className="mb-3 flex items-center">
//             <Check className="w-5 h-5 text-green-500 mr-2 flex-shrink-0" />
//             <span className="text-gray-600">{feature}</span>
//           </li>
//         ))}
//       </ul>
//       <motion.a
//         href="/signup/"
//         className="bg-green-500 text-white px-6 py-3 rounded-full font-semibold hover:bg-green-700 transition-colors duration-300 text-center text-lg"
//         whileHover={{ scale: 1.05 }}
//         whileTap={{ scale: 0.95 }}
//       >
//         Get Started
//       </motion.a>
//     </motion.div>
//   );
// };

// const FAQItem = ({ question, answer }) => {
//   const [isOpen, setIsOpen] = useState(false);

//   return (
//     <motion.div
//       className="border-b border-gray-200 py-4"
//       initial={false}
//       animate={{ backgroundColor: isOpen ? "rgba(59, 130, 246, 0.1)" : "transparent" }}
//       transition={{ duration: 0.3 }}
//     >
//       <button className="flex justify-between items-center w-full text-left" onClick={() => setIsOpen(!isOpen)}>
//         <span className="text-lg font-semibold">{question}</span>
//         <motion.div animate={{ rotate: isOpen ? 180 : 0 }} transition={{ duration: 0.3 }}>
//           <ChevronDown />
//         </motion.div>
//       </button>
//       <AnimatePresence>
//         {isOpen && (
//           <motion.p
//             className="mt-2 text-gray-600"
//             initial={{ opacity: 0, height: 0 }}
//             animate={{ opacity: 1, height: "auto" }}
//             exit={{ opacity: 0, height: 0 }}
//             transition={{ duration: 0.3 }}
//           >
//             {answer}
//           </motion.p>
//         )}
//       </AnimatePresence>
//     </motion.div>
//   );
// };

// const UserTypeCard = ({ icon: Icon, title, description }) => (
//   <motion.div
//     className="bg-white rounded-xl shadow-lg p-8 transition-all duration-300 hover:shadow-xl border border-gray-100"
//     whileHover={{ y: -5 }}
//     initial={{ opacity: 0, y: 20 }}
//     animate={{ opacity: 1, y: 0 }}
//     transition={{ duration: 0.5 }}
//   >
//     <Icon className="w-12 h-12 text-blue-500 mb-4" />
//     <h3 className="text-2xl font-bold text-gray-800 mb-3">{title}</h3>
//     <p className="text-gray-600 leading-relaxed">{description}</p>
//   </motion.div>
// );

// const FeatureItem = ({ icon: Icon, title, description }) => (
//   <motion.div
//     className="flex items-start space-x-4"
//     initial={{ opacity: 0, x: -20 }}
//     animate={{ opacity: 1, x: 0 }}
//     transition={{ duration: 0.5 }}
//   >
//     <div className="flex-shrink-0">
//       <div className="bg-blue-100 rounded-full p-3">
//         <Icon className="w-6 h-6 text-blue-500" />
//       </div>
//     </div>
//     <div>
//       <h4 className="text-lg font-semibold text-gray-800 mb-1">{title}</h4>
//       <p className="text-gray-600">{description}</p>
//     </div>
//   </motion.div>
// );

// export default function LandingPage() {
//   const [menuOpen, setMenuOpen] = useState(false);
//   const [scrolled, setScrolled] = useState(false);
//   const [hoverFeature, setHoverFeature] = useState(null);

//   const features = [
//     { icon: <Search className="w-6 h-6" />, text: "Real-time user pain point identification" },
//     { icon: <Zap className="w-6 h-6" />, text: "Feature request quantification and prioritization" },
//     { icon: <Target className="w-6 h-6" />, text: "Daily actionable insights" },
//   ];

//   useEffect(() => {
//     const handleScroll = () => {
//       setScrolled(window.scrollY > 50);
//     };

//     window.addEventListener("scroll", handleScroll);
//     return () => window.removeEventListener("scroll", handleScroll);
//   }, []);

//   return (
//     <div className="min-h-screen font-sans text-gray-900">
//       <motion.header
//         className={`bg-white backdrop-blur-md bg-opacity-80 shadow-sm sticky top-0 z-50 transition-all duration-300 ${
//           scrolled ? "py-2" : "py-4"
//         }`}
//       >
//         <nav className="container mx-auto px-6">
//           <div className="flex justify-between items-center">
//             <div className="flex items-center">
//               <Link to="/" className="flex items-center text-2xl font-bold text-gray-800">
//                 <img src={Logo} alt="NeedFinderAI Logo" className="h-10 w-auto mr-2" />
//                 <span className="text-2xl font-bold text-blue-600">NeedFinderAI</span>
//               </Link>
//             </div>
//             <div className="hidden md:flex space-x-8">
//               {/* <NavLink href="#features">Features</NavLink>
//               <NavLink href="#how-it-works">How It Works</NavLink> */}
//               {/* <NavLink href="#pricing">Pricing</NavLink> */}
//               <motion.a
//                 href="/signin/"
//                 className="bg-green-500 text-white px-6 py-2 rounded-full font-semibold hover:bg-blue-700 transition-colors duration-300"
//                 whileHover={{ scale: 1.05 }}
//                 whileTap={{ scale: 0.95 }}
//               >
//                 Signin
//               </motion.a>
//             </div>
//             <motion.button
//               className="md:hidden"
//               onClick={() => setMenuOpen(!menuOpen)}
//               whileHover={{ scale: 1.1 }}
//               whileTap={{ scale: 0.9 }}
//             >
//               {menuOpen ? <X /> : <Menu />}
//             </motion.button>
//           </div>
//           <AnimatePresence>
//             {menuOpen && (
//               <motion.div
//                 className="mt-4 md:hidden space-y-4"
//                 initial={{ opacity: 0, height: 0 }}
//                 animate={{ opacity: 1, height: "auto" }}
//                 exit={{ opacity: 0, height: 0 }}
//                 transition={{ duration: 0.3 }}
//               >
//                 <NavLink href="#features">Features</NavLink>
//                 <NavLink href="#how-it-works">How It Works</NavLink>
//                 <NavLink href="#pricing">Pricing</NavLink>
//                 <motion.a
//                   href="/signup/"
//                   className="block bg-blue-600 text-white px-6 py-2 rounded-full font-semibold hover:bg-blue-700 transition-colors duration-300 text-center"
//                   whileHover={{ scale: 1.05 }}
//                   whileTap={{ scale: 0.95 }}
//                 >
//                   Try it free
//                 </motion.a>
//               </motion.div>
//             )}
//           </AnimatePresence>
//         </nav>
//       </motion.header>

//       <main>
//         {/* Hero Section */}
//         <section className="relative py-20 lg:py-32 overflow-hidden bg-gradient-to-br from-blue-50 via-white to-blue-50">
//           {/* Abstract background shapes */}
//           <div className="absolute inset-0 overflow-hidden opacity-20">
//             <motion.div
//               className="absolute -left-1/4 -top-1/4 w-1/2 h-1/2 bg-blue-200 rounded-full mix-blend-multiply filter blur-xl"
//               animate={{
//                 x: [0, 100, 0],
//                 y: [0, 100, 0],
//               }}
//               transition={{
//                 duration: 20,
//                 repeat: Infinity,
//                 repeatType: "reverse",
//               }}
//             />
//             <motion.div
//               className="absolute -right-1/4 -bottom-1/4 w-1/2 h-1/2 bg-indigo-200 rounded-full mix-blend-multiply filter blur-xl"
//               animate={{
//                 x: [0, -100, 0],
//                 y: [0, -100, 0],
//               }}
//               transition={{
//                 duration: 20,
//                 repeat: Infinity,
//                 repeatType: "reverse",
//               }}
//             />
//             <motion.div
//               className="absolute left-1/4 bottom-1/4 w-1/2 h-1/2 bg-pink-200 rounded-full mix-blend-multiply filter blur-xl"
//               animate={{
//                 x: [0, 100, 0],
//                 y: [0, -100, 0],
//               }}
//               transition={{
//                 duration: 20,
//                 repeat: Infinity,
//                 repeatType: "reverse",
//               }}
//             />
//           </div>

//           <div className="container mx-auto px-4 sm:px-6 lg:px-8 relative z-10">
//             <div className="text-center max-w-5xl mx-auto">
//               <motion.h1
//                 className="text-6xl sm:text-6xl lg:text-6xl font-extrabold mb-6 leading-tight tracking-tight text-gray-900"
//                 initial={{ opacity: 0, y: 20 }}
//                 animate={{ opacity: 1, y: 0 }}
//                 transition={{ duration: 0.8, ease: "easeOut" }}
//               >
//                 <span className="block mt-2 bg-clip-text text-transparent bg-gray-800">
//                   {/* From Idea to Market in Record Time */}
//                   {/* Meet Your AI Co-Founder */}
//                   From Idea to Market in Record Time
//                   {/* Stop Doing It All Alone: Meet Your AI Co-Founder */}
//                   {/* Solopreneurs & Startups: Your AI Co-Founder is Here */}
//                   {/* NeedFinderAI: Your AI Co-Founder for Startup Success */}
//                 </span>
//               </motion.h1>
//               <motion.p
//                 className="text-xl sm:text-2xl text-gray-600 mb-10 max-w-3xl mx-auto leading-relaxed"
//                 initial={{ opacity: 0, y: 20 }}
//                 animate={{ opacity: 1, y: 0 }}
//                 transition={{ duration: 0.8, delay: 0.2, ease: "easeOut" }}
//               >
//                 {/* Unlock instant insights into unmet needs, feature requests, pain points, competitor strategies, and emerging trends in any
//                 product category. */}
//                 {/* Imagine having a co-founder who never sleeps, constantly analyzes market trends, and provides data-driven insights 24/7.
//                 That's NeedFinderAI - your AI-powered product co-founder. */}
//                 {/* Imagine a co-founder who never sleeps—handling market research, product roadmaps, feedback, and even marketing. That’s
//                 NeedFinderAI—your 24/7 AI-powered partner. */}
//                 {/* Imagine having a co-founder who never sleeps, handles market research, business planning, product feedback, networking, and
//                 even marketing. That's NeedFinderAI - your AI-powered product co-founder. */}
//                 {/* Imagine having a tireless partner who's always got your back. NeedFinderAI is your 24/7 support system, handling market
//                 research, business planning, product feedback, networking, and marketing. */}
//                 {/* From Idea to Launch, We've Got Your Back. */}
//                 {/* Imagine having a co-founder who's never sleeps, helps you get new product ,feature ideas, does market research for you, tracks your focus while you work inn coworkimg cafes with other makers, takes note tirelessly analyzing the market, tracks  gathering user
//                 feedback, and even handling marketing. */}
//                 {/* NeedFinderAI is the only platform offering real-time AI market research, coworking spaces, live user feedback on prototypes,
//                 and an AI marketing assistant that identifies the best places online to promote your product. */}
//                 {/* NeedFinderAI is the only platform offering real-time AI market research, coworking spaces, user feedback on prototypes ,AI
//                 business plan and an AI marketing assistant to help you launch your product faster than ever before. */}
//                 {/* NeedFinderAI is the all-in-one platform that gives solo founders and startups the power of an entire team. Get real-time
//                 market research, connect with other makers, gather user feedback, and promote your product - all powered by cutting-edge AI. */}
//                 NeedFinderAI is the all-in-one platform to accelerate your journey from idea to market, providing real-time AI market
//                 research, virtual coworking, live user feedback, AI business planning, and an AI marketing assistant to help you launch
//                 faster.
//               </motion.p>

//               <motion.div
//                 initial={{ opacity: 0, y: 20 }}
//                 animate={{ opacity: 1, y: 0 }}
//                 transition={{ duration: 0.8, delay: 0.6, ease: "easeOut" }}
//               >
//                 <motion.a
//                   href="/signup/"
//                   className="bg-green-500 text-white px-8 py-4 rounded-full text-lg sm:text-xl font-semibold shadow-lg hover:shadow-xl transition-all duration-300 inline-flex items-center group"
//                   whileHover={{ scale: 1.05 }}
//                   whileTap={{ scale: 0.95 }}
//                 >
//                   Signup For Free
//                   <ArrowRight className="ml-2 w-5 h-5 group-hover:translate-x-1 transition-transform duration-300" />
//                 </motion.a>
//                 <p className="mt-4 text-sm text-gray-500">No credit card required • Cancel anytime</p>
//               </motion.div>
//             </div>

//             {/* <motion.div
//               className="mt-20 relative max-w-6xl mx-auto"
//               initial={{ opacity: 0, y: 40 }}
//               animate={{ opacity: 1, y: 0 }}
//               transition={{ duration: 1, delay: 0.8, ease: "easeOut" }}
//             >
//               <div className="absolute inset-0 bg-gradient-to-r from-blue-400 to-indigo-600 rounded-3xl rotate-2 scale-105 opacity-20 blur-2xl transform -translate-x-4 translate-y-4"></div>
//               <div className="relative z-10 rounded-3xl overflow-hidden shadow-2xl transition-all duration-300 hover:shadow-blue-400/30 hover:shadow-2xl">
//                 <div className="aspect-w-16 aspect-h-9">
//                   <iframe
//                     src="https://www.youtube.com/embed/_gzr0tO-in8"
//                     title="NeedFinderAI in Action"
//                     frameBorder="0"
//                     allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
//                     allowFullScreen
//                     className="w-full h-full"
//                   ></iframe>
//                 </div>
//               </div>
//             </motion.div> */}

//             {/* <div className="text-center">
//               <h3 className="text-3xl md:text-4xl font-bold text-gray-800 mb-8">Trusted by Innovative Entrepreneurs</h3>
//               <p className="text-xl text-gray-600 mb-12">
//                 Join thousands of visionaries who've turned their ideas into successful businesses with FeedbackbyAI.
//               </p>
//               <IframeResizer
//                 src="https://senja.io/p/needfinderai/r/DkCpUM?mode=embed&nostyle=true"
//                 style={{ width: "1px", minWidth: "100%" }}
//               />
//             </div> */}
//           </div>
//         </section>

//         {/* Subheading and Body Section */}
//         {/* <section className="py-20 bg-gradient-to-b from-blue-50 to-white">
//           <div className="container mx-auto px-4 sm:px-6 lg:px-8">
//             <div className="text-center mb-16">
//               <motion.h2
//                 className="text-4xl md:text-5xl font-bold text-gray-900 mb-4"
//                 initial={{ opacity: 0, y: 20 }}
//                 animate={{ opacity: 1, y: 0 }}
//                 transition={{ duration: 0.8 }}
//               >
//                 Tailored for Your Success
//               </motion.h2>
//             </div>

//             <div className="grid md:grid-cols-2 gap-8 mb-20">
//               <UserTypeCard
//                 icon={Users}
//                 title="For Solopreneurs"
//                 description="Juggling everything alone? NeedFinderAI gives you the breathing room you need with data-driven insights and expert guidance, so you can make confident decisions and grow your business faster."
//               />
//               <UserTypeCard
//                 icon={Rocket}
//                 title="For Early-Stage Startups"
//                 description="Need to stay ahead of the curve? NeedFinderAI is your secret weapon, helping you validate ideas, refine your product, and reach the right customers."
//               />
//             </div>
//           </div>
//         </section> */}

//         {/* How It Works Section */}
//         {/* <section id="how-it-works" className="py-20 bg-gradient-to-b from-gray-50 to-white">
//           <div className="container mx-auto px-6">
//             <motion.h2
//               className="text-4xl font-bold text-center mb-10"
//               initial={{ opacity: 0, y: 20 }}
//               animate={{ opacity: 1, y: 0 }}
//               transition={{ duration: 0.8 }}
//             >
//               How NeedFinderAI Works
//             </motion.h2>

//             <div className="grid md:grid-cols-3 gap-8 relative">
//               <div className="absolute top-1/2 left-0 right-0 h-0.5 bg-green-200 hidden md:block"></div>
//               <FeatureCard
//                 icon={<Search className="w-12 h-12" />}
//                 title="1. Define Your Market Focus"
//                 description="Select your product category or industry to tailor the insights you receive."
//                 delay={0.3}
//               />
//               <FeatureCard
//                 icon={<Zap className="w-12 h-12" />}
//                 title="2. Continuous AI Monitoring"
//                 description="Our AI scans reviews, social media, news, and more—24/7—to bring you the most up-to-date market data."
//                 // Our AI tirelessly monitors reviews, social media, forums, and more - everywhere your customers are talking."
//                 delay={0.5}
//               />
//               <FeatureCard
//                 icon={<Target className="w-12 h-12" />}
//                 title="3. Work in Coworking Spaces"
//                 description="Work with a new maker daily in coworking space."
//                 // Receive a constant stream of actionable insights: unmet needs, trends, feature requests, and competitor analysis."
//                 delay={0.7}
//               />

//               <FeatureCard
//                 icon={<Zap className="w-12 h-12" />}
//                 title="4. Product Feedback Area"
//                 description="Share early prototypes, concepts to get feedback from live users"
//                 // Our AI tirelessly monitors reviews, social media, forums, and more - everywhere your customers are talking."
//                 delay={0.5}
//               />

//               <FeatureCard
//                 icon={<Zap className="w-12 h-12" />}
//                 title="5. AI Marketing Assistant"
//                 description="AI automatically finds the best place of internet where  your product deserves shoutout"
//                 // Our AI tirelessly monitors reviews, social media, forums, and more - everywhere your customers are talking."
//                 delay={0.5}
//               />
//             </div>
//           </div>
//         </section> */}

//         {/* Features Section */}

//         {/* New AI Cofounder Superpowers Section */}
//         <section className="py-20 bg-gradient-to-b from-gray-50 to-white">
//           <div className="max-w-6xl mx-auto px-4 sm:px-6">
//             <h2 className="text-4xl lg:text-5xl font-bold text-gray-900 mb-6 text-center">
//               Get to Market Faster with These Powerful Features
//               {/* Feature Highlights */}
//               {/* Unlock Your Startup's Potential */}
//             </h2>
//             <hr />
//           </div>
//         </section>

//         <section className="py-20 bg-gradient-to-b from-white to-blue-50">
//           <div className="max-w-6xl mx-auto px-4 sm:px-6">
//             <div className="mb-10">
//               <div className="grid md:grid-cols-2 gap-12 items-center">
//                 <div>
//                   <motion.h2
//                     className="text-4xl lg:text-5xl font-bold text-gray-900 mb-6"
//                     initial={{ opacity: 0, y: 20 }}
//                     animate={{ opacity: 1, y: 0 }}
//                     transition={{ duration: 0.8 }}
//                   >
//                     {/* Realtime AI Market Research */}
//                     Real-time AI Market Research
//                   </motion.h2>
//                   <motion.p
//                     className="text-xl text-gray-700"
//                     initial={{ opacity: 0, y: 20 }}
//                     animate={{ opacity: 1, y: 0 }}
//                     transition={{ duration: 0.8, delay: 0.2 }}
//                   >
//                     {/* While you sleep, your AI co-founder tirelessly analyzes market trends, competitor moves, and customer sentiments. Wake
//                     up to fresh, actionable insights every day. */}
//                     {/* Stay on top of trends, competitor moves, and customer sentiment without lifting a finger. */}
//                     {/* Every few hours, AI Cofounder scans online discussions, delivering fresh insights on customer needs, feature requests,
//                     and emerging trends directly to your dashboard. */}
//                     Stay ahead with insights that matter. Your AI co-founder scans online conversations, uncovering customer needs, feature
//                     requests, and market trends every few hours, delivered directly to your dashboard.
//                     {/* Uncover unmet needs, feature requests, emerging trends, and competitor strategies across any product category. */}
//                   </motion.p>
//                 </div>
//                 <div className="flex justify-center md:justify-end">
//                   <img
//                     src={FeatureImage1}
//                     alt="Realtime AI Market Research illustration"
//                     className="rounded-lg shadow-xl max-w-full h-auto"
//                   />
//                 </div>
//               </div>
//             </div>

//             <div className="mt-24">
//               <div className="grid md:grid-cols-2 gap-12 items-center">
//                 <div className="order-2 md:order-1">
//                   <img src={FeatureImage2} alt=" Virtual Co-Working Space" className="rounded-lg shadow-xl max-w-full h-auto" />
//                 </div>
//                 <div className="order-1 md:order-2">
//                   <motion.h2
//                     className="text-4xl lg:text-5xl font-bold text-gray-900 mb-6"
//                     initial={{ opacity: 0, y: 20 }}
//                     animate={{ opacity: 1, y: 0 }}
//                     transition={{ duration: 0.8 }}
//                   >
//                     {/* Build your network in Virtual Co-Working Space */}
//                     Virtual Co-Working Space
//                   </motion.h2>
//                   <motion.p
//                     className="text-xl text-gray-700"
//                     initial={{ opacity: 0, y: 20 }}
//                     animate={{ opacity: 1, y: 0 }}
//                     transition={{ duration: 0.8, delay: 0.2 }}
//                   >
//                     Connect daily with fellow makers, expand your network, and stay productive with our built-in Pomodoro timer, creating an
//                     environment that keeps you motivated and accountable.
//                     {/* Stay focused and accountable with a built-in Pomodoro timer and connect with like-minded entrepreneurs who push you
//                     forward. */}
//                     {/* Your AI co-founder connects you with a new maker daily in our virtual coworking space. Work together, Network
//                     effortlessly, save valuable contacts, and track your daily output with our built-in Pomodoro timer. */}
//                     {/* Connect with a new maker every day, network effortlessly, and stay productive with our built-in Pomodoro timer. */}
//                   </motion.p>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </section>

//         <section className="py-20 bg-gradient-to-b from-white to-blue-50">
//           <div className="max-w-6xl mx-auto px-4 sm:px-6">
//             <div className="mb-20">
//               <div className="grid md:grid-cols-2 gap-12 items-center">
//                 <div>
//                   <motion.h2
//                     className="text-4xl lg:text-5xl font-bold text-gray-900 mb-6"
//                     initial={{ opacity: 0, y: 20 }}
//                     animate={{ opacity: 1, y: 0 }}
//                     transition={{ duration: 0.8 }}
//                   >
//                     {/* Product Feedback Area */}
//                     Product Feedback
//                   </motion.h2>
//                   <motion.p
//                     className="text-xl text-gray-700"
//                     initial={{ opacity: 0, y: 20 }}
//                     animate={{ opacity: 1, y: 0 }}
//                     transition={{ duration: 0.8, delay: 0.2 }}
//                   >
//                     {/* Get real feedback on your prototypes from live users. Iterate quickly and build products that truly resonate with your
//                     target audience. */}
//                     Build products that resonate. Gather real feedback on prototypes from live users, iterate quickly, and make data-driven
//                     decisions to refine your product based on actual user input.
//                     {/* Get real feedback from live users, iterate quickly, and build products that truly resonate with your target audience. */}
//                   </motion.p>
//                 </div>
//                 <div className="flex justify-center md:justify-end">
//                   <img src={FeatureImage3} alt="Product Feedback" className="rounded-lg shadow-xl max-w-full h-auto" />
//                 </div>
//               </div>
//             </div>

//             <div className="mt-24">
//               <div className="grid md:grid-cols-2 gap-12 items-center">
//                 <div className="order-2 md:order-1">
//                   <img src={FeatureImage4} alt="AI Characters illustration" className="rounded-lg shadow-xl max-w-full h-auto" />
//                 </div>
//                 <div className="order-1 md:order-2">
//                   <motion.h2
//                     className="text-4xl lg:text-5xl font-bold text-gray-900 mb-6"
//                     initial={{ opacity: 0, y: 20 }}
//                     animate={{ opacity: 1, y: 0 }}
//                     transition={{ duration: 0.8 }}
//                   >
//                     {/* AI Marketing Assistant */}
//                     AI Marketing Assistant
//                   </motion.h2>
//                   <motion.p
//                     className="text-xl text-gray-700"
//                     initial={{ opacity: 0, y: 20 }}
//                     animate={{ opacity: 1, y: 0 }}
//                     transition={{ duration: 0.8, delay: 0.2 }}
//                   >
//                     {/* Discover prime online spots to showcase your product. Our AI pinpoints discussions and platforms where your innovation
//                     truly deserves attention. */}
//                     {/* Discover the best online spots to showcase your product and reach the right people. */}
//                     {/* AI Co-founder monitors the internet every few hours to identify high-impact conversations where your product naturally
//                     fits. */}
//                     Find your audience faster. Your AI co-founder tracks the internet for high-impact conversations, identifying the best
//                     places to talk about your product for maximum reach and visibility.
//                   </motion.p>
//                 </div>
//               </div>
//             </div>

//             <div className="mt-24">
//               <div className="grid md:grid-cols-2 gap-12 items-center">
//                 <div>
//                   <motion.h2
//                     className="text-4xl lg:text-5xl font-bold text-gray-900 mb-6"
//                     initial={{ opacity: 0, y: 20 }}
//                     animate={{ opacity: 1, y: 0 }}
//                     transition={{ duration: 0.8 }}
//                   >
//                     Lightning-Fast Business Planning
//                   </motion.h2>
//                   <motion.p
//                     className="text-xl text-gray-700"
//                     initial={{ opacity: 0, y: 20 }}
//                     animate={{ opacity: 1, y: 0 }}
//                     transition={{ duration: 0.8, delay: 0.2 }}
//                   >
//                     {/* High quality business plans based on your market research data. */}
//                     {/* Say goodbye to weeks of number crunching. Your AI co-founder crafts comprehensive business plans in minutes, adapting
//                     strategies as market conditions shift. */}
//                     {/* Create comprehensive business plans in minutes, adapting your strategy as the market evolves. */}
//                     Get your business plan ready in minutes. Adapt and adjust as your market evolves, with AI-generated strategies that help
//                     you stay on course and meet your milestones efficiently.
//                   </motion.p>
//                 </div>
//                 <div className="flex justify-center md:justify-end">
//                   <img src={FeatureImage5} alt="Product Feedback" className="rounded-lg shadow-xl max-w-full h-auto" />
//                 </div>
//               </div>
//             </div>
//           </div>
//         </section>

//         {/* Pricing Section */}
// <section id="pricing" className="py-20 bg-gray-50">
//   <div className="container mx-auto px-4">
//     <motion.h2
//       className="text-4xl font-bold text-center mb-16"
//       initial={{ opacity: 0, y: 20 }}
//       animate={{ opacity: 1, y: 0 }}
//       transition={{ duration: 0.8 }}
//     >
//       {/* Choose Your Perfect AI Co-Founder Package */}
//       From Idea to Launch – Plans for Every Stage
//     </motion.h2>
//     <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
//       <PricingCard
//         name="Starter"
//         price="29"
//         description="Ideal for founders in the idea phase"
//         features={[
//           "50 AI Insights per month",
//           "1 Full Business Reports",
//           "3 Spark an Idea Credits",
//           "3 Feedback Subsmissions per month",
//           "7 Day Access to Virtual Coworking Space",
//           "5 AI Marketing Credits ",
//         ]}
//       />

//       <PricingCard
//         name="Innovator"
//         price="59"
//         description="For solopreneurs & early-stage startups."
//         features={[
//           "250 AI Insights per month",
//           "5 Full Business Reports",
//           "10 Spark an Idea Credits",
//           "10 Feedback Submissions per month",
//           "14 Day Access to Virtual Coworking Space",
//           "50 AI Marketing Credits ",
//         ]}
//       />
//       <PricingCard
//         name="Visionary"
//         price="99"
//         description="Perfect for teams launching new products"
//         features={[
//           "500 AI Insights per month",
//           "10 Full Business Reports",
//           "20 Spark an Idea Credits",
//           "15 Feedback Submissions per month",
//           "21 Day Access to Virtual Coworking Space",
//           "100 AI Marketing Credits ",
//         ]}
//         isPopular={true}
//       />
//       <PricingCard
//         name="Pioneer"
//         price="248"
//         description="Designed for scaling startups & businesses"
//         features={[
//           "1500 AI Insights per month",
//           "25 Full Reports",
//           "50 Spark an Idea Credits",
//           "30 Feedback Submissions per month",
//           "30 Day Access to Virtual Coworking Space",
//           "250 AI Marketing Credits ",
//         ]}
//       />
//     </div>
//   </div>
// </section>

//         {/* FAQ Section */}
//         <section id="faq" className="py-20 bg-white">
//           <div className="container mx-auto px-6">
//             <motion.h2
//               className="text-4xl font-bold text-center mb-16"
//               initial={{ opacity: 0, y: 20 }}
//               animate={{ opacity: 1, y: 0 }}
//               transition={{ duration: 0.8 }}
//             >
//               Frequently Asked Questions
//             </motion.h2>
//             <div className="max-w-3xl mx-auto">
//               <FAQItem
//                 question="How does NeedFinderAI compare to a human co-founder?"
//                 answer="NeedFinderAI works 24/7, processes vast amounts of data instantly, and never gets tired or biased. It complements human creativity with data-driven insights and strategies. Unlike a human co-founder, it doesn't require equity but still provides invaluable support in market research, business planning, and marketing."
//               />
//               <FAQItem
//                 question="How often is the AI updated with new market data and trends?"
//                 answer="NeedFinderAI's market intelligence is updated in real-time. Our AI constantly scans and analyzes various data sources, including news outlets, social media, industry reports, and economic indicators. This ensures you always have the most current insights to inform your business decisions.

// "
//               />

//               <FAQItem
//                 question="How does the virtual coworking space work, and how does it benefit me?"
//                 answer="Our virtual coworking space connects you with a new maker every day. You can collaborate on projects, share ideas, and build valuable relationships. The built-in Pomodoro timer helps you stay focused and productive, even when working remotely. It's a great way to combat isolation and stay motivated."
//               />
//               <FAQItem
//                 question="How does the AI Marketing Assistant help promote my product?"
//                 answer="The AI Marketing Assistant analyzes online conversations and identifies the platforms where your product is most likely to resonate. It helps you target the right audience, saving you time and money on marketing efforts.

// "
//               />
//               <FAQItem
//                 question=" How does the AI Assisted Business Planning feature work?"
//                 answer="The AI Assisted Business Planning generates high-quality business plans on the market research data."
//               />

//               <FAQItem question="How to get support or help?" answer="Contact us at business@needfinderai.com or use our chatbox." />
//             </div>
//           </div>
//         </section>

//         {/* CTA Section */}
//         <section className="py-20 bg-gray-50">
//           <div className="container mx-auto px-6">
//             <motion.div
//               className="bg-blue-600 text-white p-16 rounded-2xl text-center"
//               initial={{ opacity: 0, y: 20 }}
//               animate={{ opacity: 1, y: 0 }}
//               transition={{ duration: 0.8 }}
//             >
//               <h2 className="text-4xl font-bold mb-8">
//                 {/* Ready to Meet Your AI Co-Founder? */}
//                 {/* Stop building alone. Let NeedFinderAI be the co-founder that propels you to success. */}
//                 {/* Stop Building Alone. Let NeedFinderAI Be Your AI Co-Founder. */}
//                 Stop Building Alone. Bring Your Product to Market in Record Time with NeedFinderAI.
//               </h2>
//               <p className="text-xl mb-12">
//                 {/* Stop doing it all alone. Get the support, insights, and strategies you need to turn your vision into reality. Let
//                 NeedFinderAI be the co-founder that propels you to success. */}
//               </p>
//               <motion.a
//                 href="/signup/"
//                 className=" bg-green-500 text-white px-10 py-5 rounded-full text-xl font-semibold hover:bg-green-600 transition-all duration-300 inline-flex items-center"
//                 whileHover={{ scale: 1.05 }}
//                 whileTap={{ scale: 0.95 }}
//               >
//                 Start Your Free Trial Today <ArrowRight className="ml-3" />
//               </motion.a>
//               <p className="mt-6 text-sm">No credit card required </p>
//             </motion.div>
//           </div>
//         </section>
//       </main>

// <footer className="bg-gray-100 py-16">
//   <div className="container mx-auto px-6">
//     <div className="grid md:grid-cols-3 gap-8">
//       <div>
//         <h3 className="text-lg font-semibold mb-4">Company</h3>
//         <ul className="space-y-2">
//           <li>
//             <a href="/about/" className="text-gray-600 hover:text-blue-600">
//               About Us
//             </a>
//           </li>
//           <li>
//             <a href="/terms/" className="text-gray-600 hover:text-blue-600">
//               Terms & Conditions
//             </a>
//           </li>
//           <li>
//             <a href="/privacy" className="text-gray-600 hover:text-blue-600">
//               Privacy Policy
//             </a>
//           </li>
//         </ul>
//       </div>
//       <div>
//         <h3 className="text-lg font-semibold mb-4">Support</h3>
//         <ul className="space-y-2">
//           <li>
//             <a href="/contact" className="text-gray-600 hover:text-blue-600">
//               Contact Us
//             </a>
//           </li>
//           <li>
//             <a href="/refunds/" className="text-gray-600 hover:text-blue-600">
//               Refund Policy
//             </a>
//           </li>
//           <li>
//             <a href="/shipping" className="text-gray-600 hover:text-blue-600">
//               Shipping And Delivery
//             </a>
//           </li>
//         </ul>
//       </div>
//       <div>
//         <h3 className="text-lg font-semibold mb-4">Connect</h3>
//         <ul className="space-y-2">
//           <li>
//             <a
//               href="https://twitter.com/needfinderai"
//               target="_blank"
//               rel="noopener noreferrer"
//               className="text-gray-600 hover:text-blue-600"
//             >
//               Twitter
//             </a>
//           </li>
//         </ul>
//       </div>
//     </div>
//     <div className="mt-12 text-center text-gray-500 text-sm">
//       <p>&copy; 2024 NeedFinderAI. All rights reserved.</p>
//       <p className="mt-2">Registered Address: INGENA TECHNOLOGY PRIVATE LIMITED, PIPRA, MANOHARPUR, India, 800016</p>
//     </div>
//   </div>
// </footer>
//     </div>
//   );
// }

// import React, { useState, useEffect } from "react";
// import { motion, AnimatePresence } from "framer-motion";
// import {
//   ArrowRight,
//   Menu,
//   X,
//   Search,
//   Zap,
//   Shield,
//   RefreshCw,
//   Users,
//   Target,
//   Bell,
//   Rocket,
//   DollarSign,
//   ChevronDown,
//   ChevronUp,
//   Check,
//   Shuffle,
//   Layers,
//   Database,
//   BarChart,
//   Clock,
//   Smile,
//   Timer,
//   Lightbulb,
//   User,
// } from "lucide-react";
// import { Link } from "react-router-dom";
// import MainImage from "../images/img23.png";

// // Assuming you have these images in your project
// import Logo from "../images/thumbnail.png";
// import FeatureImage1 from "../images/marketresearch3.png";
// import FeatureImage2 from "../images/coworking2.png";
// import FeatureImage3 from "../images/feedback2.png";
// import FeatureImage4 from "../images/aimarketing1.png";
// import FeatureImage5 from "../images/businessplan.png";

// const FAQItem = ({ question, answer }) => {
//   const [isOpen, setIsOpen] = useState(false);

//   return (
//     <motion.div
//       className="border-b border-gray-200 py-4"
//       initial={false}
//       animate={{ backgroundColor: isOpen ? "rgba(59, 130, 246, 0.1)" : "transparent" }}
//       transition={{ duration: 0.3 }}
//     >
//       <button className="flex justify-between items-center w-full text-left" onClick={() => setIsOpen(!isOpen)}>
//         <span className="text-lg font-semibold">{question}</span>
//         <motion.div animate={{ rotate: isOpen ? 180 : 0 }} transition={{ duration: 0.3 }}>
//           <ChevronDown />
//         </motion.div>
//       </button>
//       <AnimatePresence>
//         {isOpen && (
//           <motion.p
//             className="mt-2 text-gray-600"
//             initial={{ opacity: 0, height: 0 }}
//             animate={{ opacity: 1, height: "auto" }}
//             exit={{ opacity: 0, height: 0 }}
//             transition={{ duration: 0.3 }}
//           >
//             {answer}
//           </motion.p>
//         )}
//       </AnimatePresence>
//     </motion.div>
//   );
// };

// const NavLink = ({ href, children }) => (
//   <motion.a
//     href={href}
//     className="text-gray-800 hover:text-blue-600 transition-colors duration-300"
//     whileHover={{ scale: 1.05 }}
//     whileTap={{ scale: 0.95 }}
//   >
//     {children}
//   </motion.a>
// );

// const PricingCard = ({ name, price, description, features, isPopular }) => {
//   const [currency, setCurrency] = useState("$");
//   const exchangeRates = {
//     $: 1,
//     "€": 0.91,
//     "£": 0.79,
//     "₹": 82.98,
//   };

//   const convertPrice = (priceInUSD) => {
//     const convertedPrice = priceInUSD * exchangeRates[currency];
//     return currency === "₹" ? Math.round(convertedPrice) : convertedPrice.toFixed(2);
//   };

//   return (
//     <motion.div
//       className={`bg-white rounded-2xl shadow-lg p-8 flex flex-col transition-all duration-300 hover:shadow-xl border ${
//         isPopular ? "border-blue-500" : "border-gray-100"
//       }`}
//       whileHover={{ y: -10 }}
//       initial={{ opacity: 0, y: 20 }}
//       animate={{ opacity: 1, y: 0 }}
//       transition={{ duration: 0.5 }}
//     >
//       {isPopular && (
//         <div className="bg-green-500 text-white py-1 px-4 rounded-full text-sm font-semibold self-start mb-4">Most Popular</div>
//       )}
//       <h3 className="text-2xl font-bold mb-2">{name}</h3>
//       <p className="text-gray-600 mb-6">{description}</p>
//       <div className="flex items-center mb-6">
//         <select className="mr-2 p-1 border rounded text-sm" value={currency} onChange={(e) => setCurrency(e.target.value)}>
//           <option value="$">$</option>
//           <option value="€">€</option>
//           <option value="£">£</option>
//           <option value="₹">₹</option>
//         </select>
//         <p className="text-4xl font-bold">
//           {convertPrice(parseInt(price))}
//           <span className="text-xl font-normal text-gray-600">/mo</span>
//         </p>
//       </div>
//       <p className="text-xs text-gray-500 mb-6">*Approx. conversion</p>
//       <ul className="mb-8 flex-grow">
//         {features.map((feature, i) => (
//           <li key={i} className="mb-3 flex items-center">
//             <Check className="w-5 h-5 text-green-500 mr-2 flex-shrink-0" />
//             <span className="text-gray-600">{feature}</span>
//           </li>
//         ))}
//       </ul>
//       <motion.a
//         href="/signup/"
//         className="bg-green-500 text-white px-6 py-3 rounded-full font-semibold hover:bg-green-700 transition-colors duration-300 text-center text-lg"
//         whileHover={{ scale: 1.05 }}
//         whileTap={{ scale: 0.95 }}
//       >
//         Get Started
//       </motion.a>
//     </motion.div>
//   );
// };

// const FeatureCard = ({ icon: Icon, title, description }) => (
//   <motion.div
//     className="bg-white rounded-xl shadow-lg p-8 transition-all duration-300 hover:shadow-xl border border-gray-100"
//     whileHover={{ y: -5 }}
//     initial={{ opacity: 0, y: 20 }}
//     animate={{ opacity: 1, y: 0 }}
//     transition={{ duration: 0.5 }}
//   >
//     <Icon className="w-12 h-12 text-blue-500 mb-4" />
//     <h3 className="text-2xl font-bold text-gray-800 mb-3">{title}</h3>
//     <p className="text-gray-600 leading-relaxed">{description}</p>
//   </motion.div>
// );

// const AspirationCard = ({ icon: Icon, title, description, author }) => (
//   <motion.div
//     className="bg-white rounded-xl shadow-lg p-6 border border-blue-100"
//     whileHover={{ y: -5 }}
//     initial={{ opacity: 0, y: 20 }}
//     animate={{ opacity: 1, y: 0 }}
//     transition={{ duration: 0.5 }}
//   >
//     <Icon className="w-12 h-12 text-blue-500 mb-4" />
//     <h3 className="text-xl font-semibold mb-2">{title}</h3>
//     <p className="text-gray-600 italic mb-4">"{description}"</p>
//     <p className="text-sm text-blue-600 font-medium">{author}</p>
//   </motion.div>
// );

// const TestimonialsSection = () => (
//   <section id="testimonials" className="py-20 bg-gradient-to-b from-white to-blue-50">
//     <div className="container mx-auto px-4 sm:px-6 lg:px-8">
//       <motion.h2
//         className="text-4xl font-bold text-center mb-4"
//         initial={{ opacity: 0, y: 20 }}
//         animate={{ opacity: 1, y: 0 }}
//         transition={{ duration: 0.8 }}
//       >
//         {/* Envision Your Success with NeedFinderAI */}
//         Benefits
//       </motion.h2>

//       <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
//         <AspirationCard
//           icon={Lightbulb}
//           title="Uncover Hidden Opportunities"
//           description="NeedFinderAI helps you identify gaps in the market you never knew existed. It's like having a seasoned co-founder by your side 24/7."
//           author=" For startup founders"
//         />
//         <AspirationCard
//           icon={Target}
//           title="Precision Marketing"
//           description="Imagine AI Marketing Assistant as your secret weapon, finding perfect conversations for your product and potentially tripling your signups. Pure magic 🎉 !"
//           author=" For growth-focused entrepreneurs"
//         />
//         <AspirationCard
//           icon={User}
//           title="Accelerated Product Development"
//           description="With AI-driven user feedback, you could iterate your product 5x faster, ensuring you're always aligned with market needs."
//           author="For product managers"
//         />
//       </div>
//     </div>
//   </section>
// );
// const StepCard = ({ icon: Icon, title, description, step }) => (
//   <motion.div
//     className="flex items-start space-x-4 bg-white rounded-xl shadow-lg p-6 border-l-4 border-blue-500"
//     initial={{ opacity: 0, x: -50 }}
//     animate={{ opacity: 1, x: 0 }}
//     transition={{ duration: 0.5, delay: step * 0.1 }}
//   >
//     <div className="flex-shrink-0">
//       <div className="bg-blue-100 rounded-full p-3">
//         <Icon className="w-6 h-6 text-blue-600" />
//       </div>
//     </div>
//     <div>
//       <h3 className="text-xl font-bold mb-2">{title}</h3>
//       <p className="text-gray-600">{description}</p>
//     </div>
//   </motion.div>
// );

// const LandingPage = () => {
//   const [menuOpen, setMenuOpen] = useState(false);
//   const [scrolled, setScrolled] = useState(false);

//   useEffect(() => {
//     const handleScroll = () => {
//       setScrolled(window.scrollY > 50);
//     };

//     window.addEventListener("scroll", handleScroll);
//     return () => window.removeEventListener("scroll", handleScroll);
//   }, []);

//   return (
//     <div className="min-h-screen font-sans text-gray-900">
//       {/* Header */}
//       <motion.header
//         className={`bg-white backdrop-blur-md bg-opacity-80 shadow-sm sticky top-0 z-50 transition-all duration-300 ${
//           scrolled ? "py-2" : "py-4"
//         }`}
//       >
//         <nav className="container mx-auto px-6">
//           <div className="flex justify-between items-center">
//             <div className="flex items-center">
//               <Link to="/" className="flex items-center text-2xl font-bold text-gray-800">
//                 <img src={Logo} alt="NeedFinderAI Logo" className="h-10 w-auto mr-2" />
//                 <span className="text-2xl font-bold text-blue-600">NeedFinderAI</span>
//               </Link>
//             </div>
//             <div className="hidden md:flex space-x-8">
//               <NavLink href="#features">Features</NavLink>
//               <NavLink href="#how-it-works">How It Works</NavLink>
//               <NavLink href="#testimonials">Benefits</NavLink>
//               <motion.a
//                 href="/signup/"
//                 className="bg-green-500 text-white px-6 py-2 rounded-full font-semibold hover:bg-blue-700 transition-colors duration-300"
//                 whileHover={{ scale: 1.05 }}
//                 whileTap={{ scale: 0.95 }}
//               >
//                 SignIn
//               </motion.a>
//             </div>
//             <motion.button
//               className="md:hidden"
//               onClick={() => setMenuOpen(!menuOpen)}
//               whileHover={{ scale: 1.1 }}
//               whileTap={{ scale: 0.9 }}
//             >
//               {menuOpen ? <X /> : <Menu />}
//             </motion.button>
//           </div>
//           <AnimatePresence>
//             {menuOpen && (
//               <motion.div
//                 className="mt-4 md:hidden space-y-4"
//                 initial={{ opacity: 0, height: 0 }}
//                 animate={{ opacity: 1, height: "auto" }}
//                 exit={{ opacity: 0, height: 0 }}
//                 transition={{ duration: 0.3 }}
//               >
//                 <NavLink href="#features">Features</NavLink>
//                 <NavLink href="#how-it-works">How It Works</NavLink>
//                 <NavLink href="#testimonials">Testimonials</NavLink>
//                 <motion.a
//                   href="/signup/"
//                   className="block bg-blue-600 text-white px-6 py-2 rounded-full font-semibold hover:bg-blue-700 transition-colors duration-300 text-center"
//                   whileHover={{ scale: 1.05 }}
//                   whileTap={{ scale: 0.95 }}
//                 >
//                   Get Started
//                 </motion.a>
//               </motion.div>
//             )}
//           </AnimatePresence>
//         </nav>
//       </motion.header>

//       {/* Hero Section */}
//       <section className="bg-gradient-to-br from-blue-50 via-white to-blue-50 py-20 lg:py-32">
//         <div className="container mx-auto px-4 sm:px-6 lg:px-8">
//           <div className="text-center max-w-5xl mx-auto">
//             <motion.h1
//               className="text-5xl sm:text-6xl lg:text-7xl font-extrabold mb-6 leading-tight tracking-tight text-gray-900"
//               initial={{ opacity: 0, y: 20 }}
//               animate={{ opacity: 1, y: 0 }}
//               transition={{ duration: 0.8, ease: "easeOut" }}
//             >
//               From Idea to Market in Record Time
//             </motion.h1>
//             <motion.p
//               className="text-xl sm:text-2xl text-gray-700 mb-12 max-w-3xl mx-auto leading-relaxed"
//               initial={{ opacity: 0, y: 20 }}
//               animate={{ opacity: 1, y: 0 }}
//               transition={{ duration: 0.8, delay: 0.2, ease: "easeOut" }}
//             >
//               Realtime Market Research, AI Business Planner, Virtual Coworking, Live User Feedback, and AI Mention tool - all in one
//               platform to accelerate your product launch.
//             </motion.p>
//             <motion.div
//               initial={{ opacity: 0, y: 20 }}
//               animate={{ opacity: 1, y: 0 }}
//               transition={{ duration: 0.8, delay: 0.4, ease: "easeOut" }}
//               className="mb-16"
//             >
//               <motion.a
//                 href="/signup/"
//                 className="bg-green-500 text-white px-8 py-4 rounded-full text-lg sm:text-xl font-semibold shadow-lg hover:bg-green-600 transition-all duration-300 inline-flex items-center group"
//                 whileHover={{ scale: 1.05 }}
//                 whileTap={{ scale: 0.95 }}
//               >
//                 Launch Your Product Faster
//                 <ArrowRight className="ml-2 w-5 h-5 group-hover:translate-x-1 transition-transform duration-300" />
//               </motion.a>
//               <p className="mt-4 text-sm text-gray-500 font-bold">Subscription required. Serious entrepreneurs only.</p>
//             </motion.div>

//             {/* Enhanced Hero Image */}
//             <motion.div
//               initial={{ opacity: 0, y: 40 }}
//               animate={{ opacity: 1, y: 0 }}
//               transition={{ duration: 1, delay: 0.6, ease: "easeOut" }}
//               className="relative max-w-6xl mx-auto"
//             >
//               <div className="absolute inset-0 bg-gradient-to-r from-blue-400 to-green-400 opacity-50 blur-2xl rounded-3xl transform rotate-2 scale-105"></div>
//               <motion.div
//                 className="relative z-10 bg-white rounded-3xl shadow-2xl overflow-hidden"
//                 animate={{ y: [0, -10, 0] }}
//                 transition={{ repeat: Infinity, duration: 5, ease: "easeInOut" }}
//               >
//                 <div className="bg-gray-100 px-4 py-2 flex items-center space-x-2 border-b">
//                   <div className="w-3 h-3 rounded-full bg-red-500"></div>
//                   <div className="w-3 h-3 rounded-full bg-yellow-500"></div>
//                   <div className="w-3 h-3 rounded-full bg-green-500"></div>
//                 </div>
//                 <img src={MainImage} alt="NeedFinderAI Dashboard" className="w-full h-auto object-cover" />
//               </motion.div>
//             </motion.div>
//           </div>
//         </div>
//       </section>

//       {/* Features Section */}
//       {/* Features Section */}
//       <section id="features" className="py-20 bg-white">
//         <div className="container mx-auto px-4 sm:px-6 lg:px-8">
//           <h2 className="text-4xl font-bold text-center mb-16">All-in-One Tools to Turn Ideas Into Profitable Businesses</h2>
//           <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
//             <FeatureCard
//               icon={Search}
//               title="Realtime AI Market Research"
//               description="Uncover opportunities, outsmart competitors, and validate your idea with real-time market insights."
//               image={FeatureImage1}
//             />
//             <FeatureCard
//               icon={Users}
//               title="Virtual Coworking Spaces"
//               description="Network, brainstorm, and work alongside fellow founders and AI experts in our virtual coworking rooms."
//               image={FeatureImage2}
//             />
//             <FeatureCard
//               icon={RefreshCw}
//               title="Live User Feedback"
//               description="Test your ideas quickly with live user feedback. Refine your product based on real-world reactions."
//               image={FeatureImage3}
//             />
//             <FeatureCard
//               icon={BarChart}
//               title="AI Business Planning"
//               description="Build a robust business plan effortlessly with our AI-guided step-by-step process."
//               image={FeatureImage5}
//             />
//             <FeatureCard
//               icon={Rocket}
//               title="AI Marketing Assistant"
//               description="Find your audience faster 24/7. AI tracks the internet identifying the best places to talk about your product."
//               image={FeatureImage4}
//             />
//           </div>
//         </div>
//       </section>

//       {/* How It Works Section */}
//       <section id="how-it-works" className="py-20 bg-gray-50">
//         <div className="container mx-auto px-4 sm:px-6 lg:px-8">
//           <h2 className="text-4xl font-bold text-center mb-16">From Concept to Launch in 4 Simple Steps</h2>
//           <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8">
//             <div className="text-center">
//               <div className="bg-blue-100 rounded-full w-16 h-16 flex items-center justify-center mx-auto mb-4">
//                 <Layers className="w-8 h-8 text-blue-600" />
//               </div>
//               <h3 className="text-xl font-semibold mb-2">Share Your Idea</h3>
//               <p className="text-gray-600">Input your concept for instant market insights and analysis.</p>
//             </div>
//             <div className="text-center">
//               <div className="bg-blue-100 rounded-full w-16 h-16 flex items-center justify-center mx-auto mb-4">
//                 <Users className="w-8 h-8 text-blue-600" />
//               </div>
//               <h3 className="text-xl font-semibold mb-2">Build & Collaborate</h3>
//               <p className="text-gray-600">Work with AI and peers in virtual coworking spaces.</p>
//             </div>
//             <div className="text-center">
//               <div className="bg-blue-100 rounded-full w-16 h-16 flex items-center justify-center mx-auto mb-4">
//                 <RefreshCw className="w-8 h-8 text-blue-600" />
//               </div>
//               <h3 className="text-xl font-semibold mb-2">Refine with Feedback</h3>
//               <p className="text-gray-600">Gather user feedback and make data-driven adjustments.</p>
//             </div>
//             <div className="text-center">
//               <div className="bg-blue-100 rounded-full w-16 h-16 flex items-center justify-center mx-auto mb-4">
//                 <Rocket className="w-8 h-8 text-blue-600" />
//               </div>
//               <h3 className="text-xl font-semibold mb-2">Plan & Launch</h3>
//               <p className="text-gray-600">Generate a business plan and marketing strategy for success.</p>
//             </div>
//           </div>
//         </div>
//       </section>

//       {/* Testimonials Section */}
//       {/* <section id="testimonials" className="py-20 bg-white">
//         <div className="container mx-auto px-4 sm:px-6 lg:px-8">
//           <h2 className="text-4xl font-bold text-center mb-16">Hear From Our Early Users </h2>
//           <div className="grid md:grid-cols-2 gap-8">
//             <TestimonialCard
//               quote="NeedFinderAI helped me identify a gap in the market I never knew existed. It's like having a seasoned co-founder by your side 24/7."
//               author=" Michael Chen,Startup Founder"
//             />
//             <TestimonialCard
//               quote="The AI Marketing Assistant is my secret weapon. It finds perfect conversations for our product, tripling our signups. Pure magic! 🚀"
//               author=" Emily Rodriguez, Wellness Startup Founder"
//             />
//           </div>
//         </div>
//       </section> */}
//       <TestimonialsSection />

//       <section id="pricing" className="py-20 bg-gray-50">
//         <div className="container mx-auto px-4">
//           <motion.h2
//             className="text-4xl font-bold text-center mb-16"
//             initial={{ opacity: 0, y: 20 }}
//             animate={{ opacity: 1, y: 0 }}
//             transition={{ duration: 0.8 }}
//           >
//             {/* Choose Your Perfect AI Co-Founder Package */}
//             From Idea to Launch – Plans for Every Stage
//           </motion.h2>
//           <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
//             {/* <PricingCard
//               name="Starter"
//               price="29"
//               description="Ideal for founders in the idea phase"
//               features={[
//                 "50 AI Insights per month",
//                 "1 Full Business Reports",
//                 "3 Spark an Idea Credits",
//                 "3 Feedback Subsmissions per month",
//                 "7 Day Access to Virtual Coworking Space",
//                 "5 AI Marketing Credits ",
//               ]}
//             /> */}

//             <PricingCard
//               name="Innovator"
//               price="59"
//               description="For solopreneurs & early-stage startups."
//               features={[
//                 "250 AI Insights per month",
//                 "5 Full Business Reports",
//                 "10 Spark an Idea Credits",
//                 "10 Feedback Submissions per month",
//                 "14 Day Access to Virtual Coworking Space",
//                 "50 AI Marketing Credits ",
//               ]}
//             />
//             <PricingCard
//               name="Visionary"
//               price="99"
//               description="Perfect for teams launching new products"
//               features={[
//                 "500 AI Insights per month",
//                 "10 Full Business Reports",
//                 "20 Spark an Idea Credits",
//                 "15 Feedback Submissions per month",
//                 "21 Day Access to Virtual Coworking Space",
//                 "100 AI Marketing Credits ",
//               ]}
//               isPopular={true}
//             />
//             <PricingCard
//               name="Pioneer"
//               price="248"
//               description="Designed for scaling startups & businesses"
//               features={[
//                 "1500 AI Insights per month",
//                 "25 Full Reports",
//                 "50 Spark an Idea Credits",
//                 "30 Feedback Submissions per month",
//                 "30 Day Access to Virtual Coworking Space",
//                 "250 AI Marketing Credits ",
//               ]}
//             />
//           </div>
//         </div>
//       </section>

//       {/* Final CTA */}
//       {/* <section className="py-20 bg-gradient-to-r from-blue-600 to-blue-800 text-white">
//         <div className="container mx-auto px-4 sm:px-6 lg:px-8 text-center">
//           <h2 className="text-4xl font-bold mb-8">From Idea to Market. Faster. Better. Smarter.</h2>
//           <motion.a
//             href="/signup/"
//             className="bg-white text-blue-600 px-10 py-5 rounded-full text-xl font-semibold hover:bg-gray-100 transition-all duration-300 inline-flex items-center"
//             whileHover={{ scale: 1.05 }}
//             whileTap={{ scale: 0.95 }}
//           >
//             Start For Free <ArrowRight className="ml-3" />
//           </motion.a>
//         </div>
//       </section> */}

//       {/* FAQ Section */}
//       <section id="faq" className="py-20 bg-white">
//         <div className="container mx-auto px-6">
//           <motion.h2
//             className="text-4xl font-bold text-center mb-16"
//             initial={{ opacity: 0, y: 20 }}
//             animate={{ opacity: 1, y: 0 }}
//             transition={{ duration: 0.8 }}
//           >
//             Frequently Asked Questions
//           </motion.h2>
//           <div className="max-w-3xl mx-auto">
//             <FAQItem
//               question="How does NeedFinderAI compare to a human co-founder?"
//               answer="NeedFinderAI works 24/7, processes vast amounts of data instantly, and never gets tired or biased. It complements human creativity with data-driven insights and strategies. Unlike a human co-founder, it doesn't require equity but still provides invaluable support in market research, business planning, and marketing."
//             />
//             <FAQItem
//               question="How often is the AI updated with new market data and trends?"
//               answer="NeedFinderAI's market intelligence is updated in real-time. Our AI constantly scans and analyzes various data sources, including news outlets, social media, industry reports, and economic indicators. This ensures you always have the most current insights to inform your business decisions.

// "
//             />

//             <FAQItem
//               question="How does the virtual coworking space work, and how does it benefit me?"
//               answer="Our virtual coworking space connects you with a new maker every day. You can collaborate on projects, share ideas, and build valuable relationships. The built-in Pomodoro timer helps you stay focused and productive, even when working remotely. It's a great way to combat isolation and stay motivated."
//             />
//             <FAQItem
//               question="How does the AI Marketing Assistant help promote my product?"
//               answer="The AI Marketing Assistant analyzes online conversations and identifies the platforms where your product is most likely to resonate. It helps you target the right audience, saving you time and money on marketing efforts.

// "
//             />
//             <FAQItem
//               question=" How does the AI Assisted Business Planning feature work?"
//               answer="The AI Assisted Business Planning generates high-quality business plans on the market research data."
//             />

//             <FAQItem question="How to get support or help?" answer="Contact us at business@needfinderai.com or use our chatbox." />
//           </div>
//         </div>
//       </section>
//       {/* CTA Section */}
//       <section className="py-20 bg-blue-600 text-white">
//         <div className="container mx-auto px-4 sm:px-6 lg:px-8 text-center">
//           <h2 className="text-4xl font-bold mb-8">Your Breakthrough Awaits. Let's Make It Happen.</h2>
//           <p className="text-xl mb-12 max-w-3xl mx-auto">
//             Transform your idea into a thriving business faster than you ever imagined. Start for free today.
//           </p>
//           <motion.a
//             href="/signup/"
//             className="bg-green-500 text-white px-10 py-5 rounded-full text-xl font-semibold hover:bg-green-600 transition-all duration-300 inline-flex items-center"
//             whileHover={{ scale: 1.05 }}
//             whileTap={{ scale: 0.95 }}
//           >
//             Begin Your Journey <ArrowRight className="ml-3" />
//           </motion.a>
//           <p className="mt-6 text-sm">No credit card required • Unlimited potential</p>
//         </div>
//       </section>

//       {/* Footer */}
//       <footer className="bg-gray-100 py-16">
//         <div className="container mx-auto px-6">
//           <div className="grid md:grid-cols-3 gap-8">
//             <div>
//               <h3 className="text-lg font-semibold mb-4">Company</h3>
//               <ul className="space-y-2">
//                 <li>
//                   <a href="/about/" className="text-gray-600 hover:text-blue-600">
//                     About Us
//                   </a>
//                 </li>
//                 <li>
//                   <a href="/terms/" className="text-gray-600 hover:text-blue-600">
//                     Terms & Conditions
//                   </a>
//                 </li>
//                 <li>
//                   <a href="/privacy" className="text-gray-600 hover:text-blue-600">
//                     Privacy Policy
//                   </a>
//                 </li>
//               </ul>
//             </div>
//             <div>
//               <h3 className="text-lg font-semibold mb-4">Support</h3>
//               <ul className="space-y-2">
//                 <li>
//                   <a href="/contact" className="text-gray-600 hover:text-blue-600">
//                     Contact Us
//                   </a>
//                 </li>
//                 <li>
//                   <a href="/refunds/" className="text-gray-600 hover:text-blue-600">
//                     Refund Policy
//                   </a>
//                 </li>
//                 {/* <li>
//                   <a href="/shipping" className="text-gray-600 hover:text-blue-600">
//                     Shipping And Delivery
//                   </a>
//                 </li> */}
//               </ul>
//             </div>
//             <div>
//               <h3 className="text-lg font-semibold mb-4">Connect</h3>
//               <ul className="space-y-2">
//                 <li>
//                   <a
//                     href="https://twitter.com/needfinderai"
//                     target="_blank"
//                     rel="noopener noreferrer"
//                     className="text-gray-600 hover:text-blue-600"
//                   >
//                     Twitter
//                   </a>
//                 </li>
//               </ul>
//             </div>
//           </div>
//           <div className="mt-12 text-center text-gray-500 text-sm">
//             <p>&copy; 2024 NeedFinderAI. All rights reserved.</p>
//             <p className="mt-2">Registered Address: INGENA TECHNOLOGY PRIVATE LIMITED, PIPRA, MANOHARPUR, India, 800016</p>
//           </div>
//         </div>
//       </footer>
//     </div>
//   );
// };

// export default LandingPage;

import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import {
  ArrowRight,
  Menu,
  X,
  Search,
  Bell,
  ChevronDown,
  Check,
  MessageCircle,
  Send,
  CheckCircleIcon,
  ClockIcon,
  XCircleIcon,
  ChartBarIcon,
  LucideCloudLightning,
  SearchIcon,
  BriefcaseIcon,
  RocketIcon,
} from "lucide-react";
import { Link } from "react-router-dom";
// import MainImage from "../images/img23.png";
import MainImage from "../images/img33.png";
// import Logo from "../images/thumbnail.png";
// import FeatureImage1 from "../images/ai-mention-feature.png"; // Update this image
// import FeatureImage2 from "../images/market-research-feature.png"; // Update this image

import Logo from "../images/thumbnail.png";
import FeatureImage1 from "../images/marketresearch3.png";
import FeatureImage2 from "../images/coworking2.png";
import FeatureImage3 from "../images/feedback2.png";
import FeatureImage4 from "../images/aimarketing1.png";
import FeatureImage5 from "../images/businessplan.png";

// ... (keep the existing component definitions like FAQItem, NavLink, PricingCard, etc.)

const FAQItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <motion.div
      className="border-b border-gray-200 py-4"
      initial={false}
      animate={{ backgroundColor: isOpen ? "rgba(59, 130, 246, 0.1)" : "transparent" }}
      transition={{ duration: 0.3 }}
    >
      <button className="flex justify-between items-center w-full text-left" onClick={() => setIsOpen(!isOpen)}>
        <span className="text-lg font-semibold">{question}</span>
        <motion.div animate={{ rotate: isOpen ? 180 : 0 }} transition={{ duration: 0.3 }}>
          <ChevronDown />
        </motion.div>
      </button>
      <AnimatePresence>
        {isOpen && (
          <motion.p
            className="mt-2 text-gray-600"
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: "auto" }}
            exit={{ opacity: 0, height: 0 }}
            transition={{ duration: 0.3 }}
          >
            {answer}
          </motion.p>
        )}
      </AnimatePresence>
    </motion.div>
  );
};

const NavLink = ({ href, children }) => (
  <motion.a
    href={href}
    className="text-gray-800 hover:text-blue-600 transition-colors duration-300"
    whileHover={{ scale: 1.05 }}
    whileTap={{ scale: 0.95 }}
  >
    {children}
  </motion.a>
);

const PricingCard = ({ name, price, description, features, isPopular }) => {
  const [currency, setCurrency] = useState("$");
  const exchangeRates = {
    $: 1,
    "€": 0.91,
    "£": 0.79,
    "₹": 82.98,
  };

  const convertPrice = (priceInUSD) => {
    const convertedPrice = priceInUSD * exchangeRates[currency];
    return currency === "₹" ? Math.round(convertedPrice) : convertedPrice.toFixed(2);
  };

  return (
    <motion.div
      className={`bg-white rounded-2xl shadow-lg p-8 flex flex-col transition-all duration-300 hover:shadow-xl border ${
        isPopular ? "border-blue-500" : "border-gray-100"
      }`}
      whileHover={{ y: -10 }}
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
    >
      {isPopular && (
        <div className="bg-green-500 text-white py-1 px-4 rounded-full text-sm font-semibold self-start mb-4">Most Popular</div>
      )}
      <h3 className="text-2xl font-bold mb-2">{name}</h3>
      <p className="text-gray-600 mb-6">{description}</p>
      <div className="flex items-center mb-6">
        <select className="mr-2 p-1 border rounded text-sm" value={currency} onChange={(e) => setCurrency(e.target.value)}>
          <option value="$">$</option>
          <option value="€">€</option>
          <option value="£">£</option>
          <option value="₹">₹</option>
        </select>
        <p className="text-4xl font-bold">
          {convertPrice(parseInt(price))}
          <span className="text-xl font-normal text-gray-600">/mo</span>
        </p>
      </div>
      <p className="text-xs text-gray-500 mb-6">*Approx. conversion</p>
      <ul className="mb-8 flex-grow">
        {features.map((feature, i) => (
          <li key={i} className="mb-3 flex items-center">
            <Check className="w-5 h-5 text-green-500 mr-2 flex-shrink-0" />
            <span className="text-gray-600">{feature}</span>
          </li>
        ))}
      </ul>
      <motion.a
        href="/signup/"
        className="bg-green-500 text-white px-6 py-3 rounded-full font-semibold hover:bg-green-700 transition-colors duration-300 text-center text-lg"
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
      >
        Get Started
      </motion.a>
    </motion.div>
  );
};

const WhyUseNeedFinderAI = () => {
  const manualSteps = [
    "🕒 Hours daily monitoring social networks",
    "👀 Manually reading every mention",
    "✍️ Crafting individual responses",
  ];

  const aiSteps = ["⚡ 2-minute one-time setup", "🤖 AI-powered relevance filtering", "🎯 Auto-generated, personalized replies"];

  return (
    <section className="py-24 bg-gradient-to-br from-blue-50 to-indigo-100">
      <div className="container mx-auto px-4 sm:px-6 lg:px-8">
        <motion.h2
          className="text-5xl font-bold text-center mb-20 text-indigo-900"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        >
          Why NeedFinderAI?
        </motion.h2>

        <div className="grid md:grid-cols-2 gap-16">
          <motion.div
            className="bg-white rounded-2xl shadow-xl p-8"
            initial={{ opacity: 0, x: -50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.8, delay: 0.2 }}
          >
            <h3 className="text-2xl font-semibold mb-6 text-red-600 flex items-center">
              <XCircleIcon className="w-8 h-8 mr-2" />
              Manual Process
            </h3>
            <ul className="space-y-6">
              {manualSteps.map((step, index) => (
                <li key={index} className="flex items-start">
                  <ClockIcon className="w-6 h-6 text-red-500 mr-3 flex-shrink-0" />
                  <span>{step}</span>
                </li>
              ))}
            </ul>
            <p className="mt-8 font-semibold text-lg text-red-700">💼 1-2 hours daily per project</p>
          </motion.div>

          <motion.div
            className="bg-white rounded-2xl shadow-xl p-8"
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.8, delay: 0.4 }}
          >
            <h3 className="text-2xl font-semibold mb-6 text-green-600 flex items-center">
              <CheckCircleIcon className="w-8 h-8 mr-2" />
              With NeedFinderAI
            </h3>
            <ul className="space-y-6">
              {aiSteps.map((step, index) => (
                <li key={index} className="flex items-start">
                  <CheckCircleIcon className="w-6 h-6 text-green-500 mr-3 flex-shrink-0" />
                  <span>{step}</span>
                </li>
              ))}
            </ul>
            <p className="mt-8 font-semibold text-lg text-green-700">Total: 2 minutes one-time setup</p>
            <motion.p
              className="mt-6 text-xl text-green-600 font-bold bg-green-100 p-4 rounded-lg"
              whileHover={{ scale: 1.05 }}
              transition={{ type: "spring", stiffness: 400, damping: 10 }}
            >
              Save 30 - 60 hours monthly per project!
            </motion.p>
          </motion.div>
        </div>
      </div>
    </section>
  );
};

const RealTimeSearch = () => {
  const traditionalMethod = ["Wait for periodic reports", "Analyze historical data", "React to outdated information"];

  const realTimeMethod = ["Get instant market insights", "Identify trends as they emerge", "Make data-driven decisions in real-time"];

  return (
    <section className="py-24 bg-gradient-to-br from-purple-50 to-indigo-100">
      <div className="container mx-auto px-4 sm:px-6 lg:px-8">
        <motion.h2
          className="text-5xl font-bold text-center mb-20 text-indigo-900"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        >
          Why Real-Time Market Research?
        </motion.h2>

        <div className="grid md:grid-cols-2 gap-16">
          <motion.div
            className="bg-white rounded-2xl shadow-xl p-8"
            initial={{ opacity: 0, x: -50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.8, delay: 0.2 }}
          >
            <h3 className="text-2xl font-semibold mb-6 text-orange-600 flex items-center">
              <ClockIcon className="w-8 h-8 mr-2" />
              Traditional Market Research
            </h3>
            <ul className="space-y-6">
              {traditionalMethod.map((step, index) => (
                <li key={index} className="flex items-start">
                  <ChartBarIcon className="w-6 h-6 text-orange-500 mr-3 flex-shrink-0" />
                  <span>{step}</span>
                </li>
              ))}
            </ul>
            <p className="mt-8 font-semibold text-lg text-orange-700">Result: Delayed insights, missed opportunities</p>
          </motion.div>

          <motion.div
            className="bg-white rounded-2xl shadow-xl p-8"
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.8, delay: 0.4 }}
          >
            <h3 className="text-2xl font-semibold mb-6 text-blue-600 flex items-center">
              <LucideCloudLightning className="w-8 h-8 mr-2" />
              With NeedFinderAI Real-Time Search
            </h3>
            <ul className="space-y-6">
              {realTimeMethod.map((step, index) => (
                <li key={index} className="flex items-start">
                  <SearchIcon className="w-6 h-6 text-blue-500 mr-3 flex-shrink-0" />
                  <span>{step}</span>
                </li>
              ))}
            </ul>
            <p className="mt-8 font-semibold text-lg text-blue-700">Result: Stay ahead of the curve, capitalize on trends</p>
            <motion.p
              className="mt-6 text-xl text-blue-600 font-bold bg-blue-100 p-4 rounded-lg"
              whileHover={{ scale: 1.05 }}
              transition={{ type: "spring", stiffness: 400, damping: 10 }}
            >
              Gain a competitive edge with real-time insights!
            </motion.p>
          </motion.div>
        </div>
      </div>
    </section>
  );
};

const HowItWorksRealTimeSearch = () => {
  const steps = [
    {
      icon: <SearchIcon className="w-8 h-8 text-indigo-600" />,
      title: "1. Define Your Focus",
      description: "Specify your industry, product, or area of interest for targeted insights.",
    },
    {
      icon: <ChartBarIcon className="w-8 h-8 text-indigo-600" />,
      title: "2. Data Collection Begins",
      description: "Our AI starts gathering real-time data from various sources across the internet.",
    },
    {
      icon: <LucideCloudLightning className="w-8 h-8 text-indigo-600" />,
      title: "3. Real-Time Analysis",
      description: "Advanced algorithms analyze the data instantly, identifying trends and patterns.",
    },
    {
      icon: <BriefcaseIcon className="w-8 h-8 text-indigo-600" />,
      title: "4. Insight Generation",
      description: "The AI transforms raw data into actionable insights and recommendations.",
    },
    {
      icon: <RocketIcon className="w-8 h-8 text-indigo-600" />,
      title: "5. Continuous Updates",
      description: "Receive ongoing, real-time updates to stay ahead of market changes and opportunities.",
    },
  ];

  return (
    <section id="how-it-works-real-time" className="py-20 bg-gradient-to-br from-indigo-50 to-purple-100">
      <div className="container mx-auto px-4 sm:px-6 lg:px-8">
        <motion.h2
          className="text-4xl font-bold text-center mb-16 text-indigo-900"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        >
          How Real-Time Market Research Works
        </motion.h2>
        <div className="grid md:grid-cols-3 lg:grid-cols-5 gap-8">
          {steps.map((step, index) => (
            <motion.div
              key={index}
              className="text-center"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
            >
              <motion.div
                className="bg-white rounded-full w-20 h-20 flex items-center justify-center mx-auto mb-4 shadow-lg"
                whileHover={{ scale: 1.05, boxShadow: "0 0 15px rgba(79, 70, 229, 0.3)" }}
              >
                {step.icon}
              </motion.div>
              <h3 className="text-xl font-semibold mb-2 text-indigo-800">{step.title}</h3>
              <p className="text-gray-600">{step.description}</p>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};

const FeatureCard = ({ icon: Icon, title, description }) => (
  <motion.div
    className="bg-white rounded-xl shadow-lg p-8 transition-all duration-300 hover:shadow-xl border border-gray-100"
    whileHover={{ y: -5 }}
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.5 }}
  >
    <Icon className="w-12 h-12 text-blue-500 mb-4" />
    <h3 className="text-2xl font-bold text-gray-800 mb-3">{title}</h3>
    <p className="text-gray-600 leading-relaxed">{description}</p>
  </motion.div>
);

const LandingPage = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 50);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <div className="min-h-screen font-sans text-gray-900">
      {/* Header */}
      <motion.header
        className={`bg-white backdrop-blur-md bg-opacity-80 shadow-sm sticky top-0 z-50 transition-all duration-300 ${
          scrolled ? "py-2" : "py-4"
        }`}
      >
        <nav className="container mx-auto px-6">
          <div className="flex justify-between items-center">
            <div className="flex items-center">
              <Link to="/" className="flex items-center text-2xl font-bold text-gray-800">
                <img src={Logo} alt="NeedFinderAI Logo" className="h-10 w-auto mr-2" />
                <span className="text-2xl font-bold text-blue-600">NeedFinderAI</span>
              </Link>
            </div>
            <div className="hidden md:flex space-x-8">
              <NavLink href="#features">Features</NavLink>
              <NavLink href="#how-it-works">How It Works</NavLink>
              <NavLink href="#pricing">Pricing</NavLink>
              <motion.a
                href="/signin/"
                className="bg-green-500 text-white px-6 py-2 rounded-full font-semibold hover:bg-blue-700 transition-colors duration-300"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                Signin
              </motion.a>
            </div>
            <motion.button
              className="md:hidden"
              onClick={() => setMenuOpen(!menuOpen)}
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
            >
              {menuOpen ? <X /> : <Menu />}
            </motion.button>
          </div>
          <AnimatePresence>
            {menuOpen && (
              <motion.div
                className="mt-4 md:hidden space-y-4"
                initial={{ opacity: 0, height: 0 }}
                animate={{ opacity: 1, height: "auto" }}
                exit={{ opacity: 0, height: 0 }}
                transition={{ duration: 0.3 }}
              >
                <NavLink href="#features">Features</NavLink>
                <NavLink href="#how-it-works">How It Works</NavLink>
                <NavLink href="#pricing">Pricing</NavLink>
                <motion.a
                  href="/signup/"
                  className="block bg-blue-600 text-white px-6 py-2 rounded-full font-semibold hover:bg-blue-700 transition-colors duration-300 text-center"
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                >
                  Get Started
                </motion.a>
              </motion.div>
            )}
          </AnimatePresence>
        </nav>
      </motion.header>
      {/* Hero Section */}
      <section className="bg-gradient-to-br from-blue-50 via-white to-blue-50 py-20 lg:py-32">
        <div className="container mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center max-w-5xl mx-auto">
            <motion.h1
              className="text-5xl sm:text-6xl lg:text-6xl font-extrabold mb-6 leading-tight tracking-tight text-gray-900"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8, ease: "easeOut" }}
            >
              {/* Find Perfect Conversations to Mention Your Product */}
              {/* AI Finds Your Perfect Customers in Seconds */}
              Find Your Perfect Customers in Seconds
            </motion.h1>
            <motion.p
              className="text-xl sm:text-2xl text-gray-600 mb-10 max-w-3xl mx-auto leading-relaxed"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8, delay: 0.2, ease: "easeOut" }}
            >
              {/* NeedFinderAI finds perfect conversations for mentioning your product and crafts engaging replies. Boost your online presence
              and grow your audience effortlessly. */}
              {/* NeedFinderAI scans social media conversations to find you the best discussions to mention your product. Get your first
              customers this week. */}
              NeedFinderAI scan millions of social media conversations, identifying the exact moments when potential customers need your
              product. Stop chasing leads. Let them come to you.
            </motion.p>
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8, delay: 0.4, ease: "easeOut" }}
              className="mb-16"
            >
              <motion.a
                href="/signup/"
                className="bg-green-500 text-white px-8 py-4 rounded-full text-lg sm:text-xl font-semibold shadow-lg hover:bg-green-600 transition-all duration-300 inline-flex items-center group"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                Sign Up for Free
                <ArrowRight className="ml-2 w-5 h-5 group-hover:translate-x-1 transition-transform duration-300" />
              </motion.a>
              <p className="mt-4 text-sm text-gray-500">No credit card required </p>
            </motion.div>

            {/* Enhanced Hero Image */}
            <motion.div
              initial={{ opacity: 0, y: 40 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 1, delay: 0.6, ease: "easeOut" }}
              className="relative max-w-6xl mx-auto"
            >
              <div className="absolute inset-0 bg-gradient-to-r from-blue-400 to-green-400 opacity-50 blur-2xl rounded-3xl transform rotate-2 scale-105"></div>
              <motion.div
                className="relative z-10 bg-white rounded-3xl shadow-2xl overflow-hidden"
                animate={{ y: [0, -10, 0] }}
                transition={{ repeat: Infinity, duration: 5, ease: "easeInOut" }}
              >
                <div className="bg-gray-100 px-4 py-2 flex items-center space-x-2 border-b">
                  <div className="w-3 h-3 rounded-full bg-red-500"></div>
                  <div className="w-3 h-3 rounded-full bg-yellow-500"></div>
                  <div className="w-3 h-3 rounded-full bg-green-500"></div>
                </div>
                <img src={MainImage} alt="NeedFinderAI Dashboard" className="w-full h-auto object-cover" />
              </motion.div>
            </motion.div>
          </div>
        </div>
      </section>
      {/* Features Section */}
      {/* <section id="features" className="py-20 bg-white">
        <div className="container mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className="text-4xl font-bold text-center mb-16">Two game changing features in one product</h2>
          <div className="grid md:grid-cols-2 gap-8">
            <FeatureCard
              icon={Bell}
              title="AI Mention"
              description="Our AI scans the internet 24/7, identifying relevant conversations where your product could add value. Never miss an opportunity to engage with potential customers."
              image={FeatureImage1}
            />
            <FeatureCard
              icon={Search}
              title="Real-time AI Market Research"
              description="Know what's on your users mind in realtime. Pain points, feature requests, competitor mention everything."
              image={FeatureImage2}
            />
          </div>
        </div>
      </section> */}
      <WhyUseNeedFinderAI />
      {/* <RealTimeSearch /> */}
      {/* How It Works Section */}
      {/* How It Works Section */}
      <section id="how-it-works" className="py-20 bg-gray-50">
        <div className="container mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className="text-4xl font-bold text-center mb-16">How AI Mention Works</h2>
          <div className="grid md:grid-cols-3 lg:grid-cols-5 gap-8">
            <div className="text-center">
              <div className="bg-blue-100 rounded-full w-16 h-16 flex items-center justify-center mx-auto mb-4">
                <Search className="w-8 h-8 text-blue-600" />
              </div>
              <h3 className="text-xl font-semibold mb-2">1. Add your product</h3>
              <p className="text-gray-600">Add your product info in detail</p>
            </div>
            <div className="text-center">
              <div className="bg-blue-100 rounded-full w-16 h-16 flex items-center justify-center mx-auto mb-4">
                <Bell className="w-8 h-8 text-blue-600" />
              </div>
              <h3 className="text-xl font-semibold mb-2">2. Mention tracking begins</h3>
              <p className="text-gray-600">Our platform tracks mentions of your keywords across the internet.</p>
            </div>
            <div className="text-center">
              <div className="bg-blue-100 rounded-full w-16 h-16 flex items-center justify-center mx-auto mb-4">
                <Check className="w-8 h-8 text-blue-600" />
              </div>
              <h3 className="text-xl font-semibold mb-2">3. Post selection</h3>
              <p className="text-gray-600">
                Our AI chooses high quality, recent, and relevant posts where your product deserves a shoutout in the replies.
              </p>
            </div>
            <div className="text-center">
              <div className="bg-blue-100 rounded-full w-16 h-16 flex items-center justify-center mx-auto mb-4">
                <MessageCircle className="w-8 h-8 text-blue-600" />
              </div>
              <h3 className="text-xl font-semibold mb-2">4. Reply Generation</h3>
              <p className="text-gray-600">
                Our AI generates relevant, useful replies to selected mentions, that aim to genuinely help the original poster and also
                mention your product when relevant.
              </p>
            </div>
            <div className="text-center">
              <div className="bg-blue-100 rounded-full w-16 h-16 flex items-center justify-center mx-auto mb-4">
                <Send className="w-8 h-8 text-blue-600" />
              </div>
              <h3 className="text-xl font-semibold mb-2">5. Sent!</h3>
              <p className="text-gray-600">
                That's it, and then on to the next one. While you are doing more important things, we work 24/7 to bring you new customers.
              </p>
            </div>
          </div>
        </div>
      </section>
      {/* <HowItWorksRealTimeSearch /> */}
      {/* Pricing Section */}
      <section id="pricing" className="py-20 bg-white">
        <div className="container mx-auto px-4">
          <motion.h2
            className="text-4xl font-bold text-center mb-16"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
          >
            Choose Your Perfect Plan
          </motion.h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {/* <PricingCard
              name="Starter"
              price="29"
              description="For individuals and small startups"
              features={["50 AI Mentions per month", "10 AI Realtime Insights per month", "3 Spark an Idea"]}
            /> */}
            <PricingCard
              name="🌱 Innovator"
              price="59"
              description="For just launched businesses"
              features={[
                "120 AI Mentions per month",
                "3 keywords",
                "120 AI suggested Replies",
                "Tracks both Reddit & Twitter",

                //  "40 AI Realtime Insights per month", "3 Spark an Idea", "1 Full Business Report"
              ]}
              // isPopular={true}
            />

            <PricingCard
              name="🚀 Visionary"
              price="99"
              description="For scaling businesses"
              features={[
                "200 AI Mentions per month",
                "5 keywords",
                "200 AI suggested Replies",
                "Tracks both Reddit & Twitter",
                // "75 AI Realtime Insights per month", "10 Spark an Idea", "3 Full Business Report"
              ]}
              isPopular={true}
            />

            <PricingCard
              name="🌟 Pioneer"
              price="248"
              description="For large teams and organizations"
              features={[
                "500 AI Mentions per month",
                "10 keywords",
                "500 AI suggested Replies",
                "Tracks both Reddit & Twitter",
                // "100 AI Realtime Insights per month", "20 Spark an Idea", "5 Full Business Report"
              ]}
            />
          </div>
        </div>
      </section>
      {/* FAQ Section */}
      <section id="faq" className="py-20 bg-gray-50">
        <div className="container mx-auto px-6">
          <motion.h2
            className="text-4xl font-bold text-center mb-16"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
          >
            Frequently Asked Questions
          </motion.h2>
          <div className="max-w-3xl mx-auto">
            <FAQItem
              question="How does NeedFinderAI differ from traditional social listening tools?"
              answer="NeedFinderAI goes beyond listening – it actively identifies opportunities and engages potential customers with personalized, AI-generated responses."
            />
            <FAQItem
              question="Can NeedFinderAI track conversations in multiple languages?"
              answer="Yes, our AI is multilingual and can track and respond in over 100 languages."
            />
            {/* <FAQItem
              question="How often is the market research data updated?"
              answer="Our AI continuously scans and analyzes various data sources in real-time, including news outlets, social media, industry reports, and economic indicators. This ensures you always have the most current insights to inform your business decisions."
            /> */}
            <FAQItem
              question="How accurate is the AI in identifying relevant mentions?"
              answer="Our AI boasts an accuracy rate of over 95% in identifying relevant mentions. We constantly fine-tune our algorithms based on user feedback to improve accuracy further. You can also train the AI on your specific use case for even better results."
            />
            {/* <FAQItem
              question="Can I integrate AI Mention with my existing tools?"
              answer="Yes, AI Mention offers API access on our Enterprise plan, allowing you to integrate our powerful AI capabilities into your existing workflow and tools. We also offer native integrations with popular platforms like Slack, Trello, and Zapier."
            /> */}
            {/* <FAQItem
              question="How does the free trial work?"
              answer="Our 14-day free trial gives you full access to all features of the Growth plan. No credit card is required to start, and you can cancel anytime. If you decide to continue, you'll be billed at the end of the trial period."
            /> */}
          </div>
        </div>
      </section>
      {/* CTA Section */}
      <section className="py-20 bg-blue-600 text-white">
        <div className="container mx-auto px-4 sm:px-6 lg:px-8 text-center">
          <h2 className="text-4xl font-bold mb-8">Ready to Revolutionize Your Customer Acquisition?</h2>
          <p className="text-xl mb-12 max-w-3xl mx-auto">
            Don't let another opportunity slip away. Let NeedFinderAI connect you with the customers who need you most.
          </p>
          <motion.a
            href="/signup/"
            className="bg-green-500 text-white px-10 py-5 rounded-full text-xl font-semibold hover:bg-green-600 transition-all duration-300 inline-flex items-center"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            Signup For Free <ArrowRight className="ml-3" />
          </motion.a>
          <p className="mt-6 text-sm">No credit card required </p>
        </div>
      </section>
      {/* Footer */}

      <footer className="bg-gray-100 py-16">
        <div className="container mx-auto px-6">
          {" "}
          <div className="grid md:grid-cols-3 gap-8">
            <div>
              <ul className="space-y-2">
                <li>
                  <a href="/about/" className="text-gray-600 hover:text-blue-600">
                    About Us
                  </a>
                </li>
                <li>
                  <a href="/terms/" className="text-gray-600 hover:text-blue-600">
                    Terms & Conditions
                  </a>
                </li>
                <li>
                  <a href="/privacy" className="text-gray-600 hover:text-blue-600">
                    Privacy Policy
                  </a>
                </li>
              </ul>
            </div>
            <div>
              <h3 className="text-lg font-semibold mb-4">Support</h3>
              <ul className="space-y-2">
                <li>
                  <a href="/contact" className="text-gray-600 hover:text-blue-600">
                    Contact Us
                  </a>
                </li>
                <li>
                  <a href="/refunds/" className="text-gray-600 hover:text-blue-600">
                    Refund Policy
                  </a>
                </li>
              </ul>
            </div>
            <div>
              <h3 className="text-lg font-semibold mb-4">Connect</h3>{" "}
              <ul className="space-y-2">
                <li>
                  <a
                    href="https://twitter.com/needfinderai"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-gray-600 hover:text-blue-600"
                  >
                    Twitter
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="mt-12 text-center text-gray-500 text-sm">
            <p>&copy; 2024 NeedFinderAI. All rights reserved.</p>
            <p className="mt-2">Registered Address: INGENA TECHNOLOGY PRIVATE LIMITED, PIPRA, MANOHARPUR, India, 800016</p>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default LandingPage;
