import React from "react";
import Header from "../components/Header";

const ContactUs = () => {
  return (
    <div className="font-inter antialiased bg-white text-gray-900 tracking-tight">
      <Header />
      <div className="grow p-4">
        <section className="bg-gradient-to-b from-gray-50 to-blue-100 min-h-screen">
          <div className="max-w-4xl mx-auto py-12">
            <h1 className="text-2xl font-bold mb-4">Contact Us</h1>
            <p className="mb-4">
              We would love to hear from you! If you have any questions,
              feedback, or need assistance with Ingena Technology Private
              Limited, feel free to reach out to us.
            </p>
            <h2 className="text-xl font-semibold mb-3">Email Us</h2>
            <p className="mb-4">
              For any inquiries, please email us at{" "}
              <a
                href="mailto:business@needfinderai.com"
                className="text-blue-600 underline"
              >
                business@needfinderai.com
              </a>
              . We aim to respond within 24-48 hours.
            </p>
            <h2 className="text-xl font-semibold mb-3">Chat With Us</h2>
            <p className="mb-4">
              Use our chatbox on the bottom right corner of the page for
              immediate assistance. Our support team is available during regular
              business hours.
            </p>
            <h2 className="text-xl font-semibold mb-3">Social Media</h2>
            <p className="mb-4">
              Connect with us on social media to stay updated:
              <ul className="list-disc list-inside">
                <li>
                  <a
                    href="https://twitter.com/NeedFinderAI"
                    className="text-blue-600 underline"
                  >
                    Twitter
                  </a>
                </li>
                {/* Add more social links as needed */}
              </ul>
            </p>

            <h2 className="text-xl font-semibold mb-3">Support Hours</h2>
            <p className="mb-4">
              Our support team is available Monday through Friday, from 9 AM to
              6 PM (IST).
            </p>
          </div>
        </section>
      </div>
    </div>
  );
};

export default ContactUs;
