import React, { useState, useEffect } from "react";
import {
  MessageSquare,
  RefreshCw,
  PlusCircle,
  Clock,
  User,
  Link,
  Image as ImageIcon,
  X,
  Mail,
  Eye,
  ThumbsUp,
  ThumbsDown,
  Heart,
  Sparkles,
  Lightbulb,
  Users,
  Zap,
} from "lucide-react";
import instance from "./axiosConfig"; // DRF API instance
import { motion, AnimatePresence } from "framer-motion";
import { isValid, parseISO, formatDistanceToNow } from "date-fns";

const PrivateFeedbackPlatform = () => {
  const [showNewPostModal, setShowNewPostModal] = useState(false);
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);
  const [showFeedbackListModal, setShowFeedbackListModal] = useState(false);
  const [selectedPost, setSelectedPost] = useState(null);
  const [posts, setPosts] = useState([]); // For storing posts data
  const [feedback, setFeedback] = useState({}); // For storing feedback per post
  const [errorMessage, setErrorMessage] = useState(null); // General error message
  const [fieldErrors, setFieldErrors] = useState({}); // Specific field errors
  const [creditWarning, setCreditWarning] = useState(null); // Credit-related error

  // Fetch posts from the backend
  useEffect(() => {
    instance
      .get("posts/")
      .then((response) => {
        setPosts(response.data);
      })
      .catch((error) => {
        console.error("Failed to fetch posts:", error);
      });
  }, []);

  // Fetch feedback for the selected post
  useEffect(() => {
    if (selectedPost) {
      instance
        .get("feedback/", { params: { post_id: selectedPost.id } })
        .then((feedbackResponse) => {
          setFeedback((prevFeedback) => ({
            ...prevFeedback,
            [selectedPost.id]: feedbackResponse.data.map((item) => ({
              ...item,
              reaction: item.reaction || null, // Ensure reaction is included
            })),
          }));
        })
        .catch((error) => {
          console.error("Error fetching feedback:", error);
        });
    }
  }, [selectedPost]);

  // Add a new post to the backend
  const addNewPost = async (newPost) => {
    try {
      const postData = {
        product_name: newPost.productName,
        product_url: newPost.url,
        feedback_question: newPost.question,
        image_or_video_url: newPost.image_or_video_url, // Image/Video URL
      };

      // Create the post
      const postResponse = await instance.post("/posts/", postData);
      const newPostData = postResponse.data;

      // Update the posts state without refreshing the page
      setPosts((prevPosts) => [newPostData, ...prevPosts]);

      // Close the modal after successfully creating the post
      setShowNewPostModal(false);
      setErrorMessage(null); // Clear any error message
      setCreditWarning(null); // Clear any credit warning
      setFieldErrors({}); // Clear field errors
    } catch (error) {
      // Handle the errors based on response
      if (error.response) {
        const { data } = error.response;

        // Handle credit-related errors
        if (data.credits_error) {
          setCreditWarning(data.error); // Show credit warning
        }

        // Handle field validation errors
        if (data.error) {
          setErrorMessage(data.error); // Set the general error message
        }

        if (data.errors) {
          setFieldErrors(data.errors); // Set field-specific errors if available
        }
      } else {
        // For network or unexpected errors
        setErrorMessage("An unexpected error occurred. Please try again.");
      }

      setShowNewPostModal(true); // Keep modal open in case of errors
    }
  };

  // Add feedback to a post
  const addFeedback = (postId, newFeedback) => {
    const feedbackData = {
      post_id: postId,
      text: newFeedback.text,
    };

    instance
      .post("feedback/", feedbackData)
      .then((response) => {
        const feedbackId = response.data.feedback_id;

        instance
          .get("feedback/", { params: { post_id: postId } }) // Refetch feedback for the post
          .then((feedbackResponse) => {
            setFeedback((prevFeedback) => ({
              ...prevFeedback,
              [postId]: feedbackResponse.data,
            }));
            setShowFeedbackModal(false);
          });
      })
      .catch((error) => {
        console.error("Error adding feedback:", error);
        setShowFeedbackModal(false);
      });
  };

  // React to feedback (like/dislike)
  const reactToFeedback = async (postId, feedbackId, reactionType) => {
    try {
      const reaction = reactionType === "like" ? 1 : 2;

      // Update the feedback reaction in the backend
      await instance.patch(`feedback/${feedbackId}/`, { reaction });

      // Update the feedback state in the frontend
      setFeedback((prevFeedback) => ({
        ...prevFeedback,
        [postId]: prevFeedback[postId].map((item) => (item.id === feedbackId ? { ...item, reaction } : item)),
      }));
    } catch (error) {
      console.error("Error reacting to feedback:", error);
    }
  };
  const FeatureIcon = ({ Icon, text }) => (
    <div className="flex items-center space-x-2">
      <Icon className="w-5 h-5" />
      <span className="text-sm">{text}</span>
    </div>
  );

  return (
    <div className="min-h-screen bg-gray-50 font-sans">
      {/* Navbar */}
      <nav className="bg-white shadow-sm">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-4 py-2 space-y-2 mb-16">
            <motion.div
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              className="bg-gradient-to-br from-blue-800 via-indigo-900 to-purple-900 rounded-3xl p-8 sm:p-12 text-white shadow-xl transform transition-all duration-500 hover:shadow-2xl hover:scale-[1.02]"
            >
              {/* Section Title */}
              <div className="flex items-center space-x-4 mb-8">
                <Sparkles className="w-10 h-10 text-yellow-300 animate-pulse" />
                <h2 className="text-3xl sm:text-3xl font-bold tracking-tight">What to Expect In Private Feedback Area</h2>
              </div>

              {/* Feature Grid */}
              <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
                {/* <FeatureIcon Icon={Zap} text="Add your product" /> */}
                <FeatureIcon Icon={Users} text="Post your product or idea and get private feedback from other makers." />
                <FeatureIcon Icon={Lightbulb} text="Least popular posts on top so nobody is left without a response " />
                <FeatureIcon Icon={Zap} text="Share changes to the app daily to request new feedback " />
                {/* <FeatureIcon Icon={Zap} text="Add your product" /> */}
              </div>
            </motion.div>
          </div>
          <div className="flex justify-between items-center h-20">
            {/* <div className="flex flex-col">
              <span className="text-gray-900 text-2xl font-bold tracking-tight">Product Feedback Area</span>
              <span className="text-gray-500 text-sm mt-1">Get free private feedback in every stage of development</span>
            </div> */}
            <button
              onClick={() => setShowNewPostModal(true)}
              className="bg-blue-500 text-white px-6 py-2.5 rounded-full font-medium hover:bg-blue-600 transition duration-300 ease-in-out flex items-center shadow-md hover:shadow-lg transform hover:scale-105"
            >
              <PlusCircle className="mr-2 h-5 w-5" />
              Submit Your Product
            </button>
          </div>
        </div>
      </nav>

      <main className="max-w-5xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
        {/* General Error Message Section */}
        {/* {errorMessage && <div className="bg-red-100 text-red-600 border border-red-500 rounded-lg p-4 mb-6">{errorMessage}</div>} */}

        <div className="bg-gradient-to-br from-blue-50 to-white rounded-3xl shadow-lg p-8">
          <h2 className="text-3xl font-bold text-gray-900 mb-2"> Products needing your Feedback Today</h2>
          {/* <p className="text-gray-600 mb-8">Your feedback is invaluable. Help innovators refine their ideas.</p> */}

          <ul className="space-y-8">
            {posts.map((post) => (
              <li key={post.id} className="bg-white rounded-2xl shadow-sm overflow-hidden transition-all duration-300 hover:shadow-md">
                <div className="p-6 sm:p-8">
                  <div className="flex items-center mb-4">
                    <div className="w-16 h-16 bg-gradient-to-br from-blue-100 to-blue-200 rounded-full flex items-center justify-center mr-4 overflow-hidden">
                      {post.image_or_video_url ? (
                        <img src={post.image_or_video_url} alt={post.product_name} className="w-full h-full object-cover" />
                      ) : (
                        <ImageIcon className="h-8 w-8 text-blue-500" />
                      )}
                    </div>
                    <div>
                      <h3 className="text-xl font-semibold text-gray-900">{post.product_name}</h3>
                      <div className="flex items-center text-sm text-gray-500 mt-1">
                        {/* <User className="h-4 w-4 mr-1" />
                        <span className="mr-3">{post.author}</span>
                        <Clock className="h-4 w-4 mr-1" /> */}
                        {/* <span>
                          {isValid(parseISO(post.timestamp))
                            ? formatDistanceToNow(parseISO(post.timestamp), { addSuffix: true })
                            : "Invalid date"}
                        </span> */}
                      </div>
                    </div>
                  </div>
                  <div className="bg-gray-50 rounded-xl p-4 mb-6">
                    <p className="text-gray-700 text-lg">{post.feedback_question}</p>
                  </div>
                  <div className="flex flex-col sm:flex-row gap-4">
                    <button
                      onClick={() => {
                        setSelectedPost(post);
                        setShowFeedbackModal(true);
                      }}
                      className="flex-1 inline-flex justify-center items-center px-6 py-3 text-base font-medium text-white bg-blue-600 rounded-full hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition-all duration-200 transform hover:scale-105"
                    >
                      <MessageSquare className="h-5 w-5 mr-2" />
                      Provide Feedback
                    </button>
                    {post.product_url && (
                      <a
                        href={post.product_url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="flex-1 inline-flex justify-center items-center px-6 py-3 text-base font-medium text-blue-600 bg-blue-100 rounded-full hover:bg-blue-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition-all duration-200 transform hover:scale-105"
                      >
                        <Link className="h-5 w-5 mr-2" />
                        View Prototype
                      </a>
                    )}
                    {post.is_author && (
                      <button
                        onClick={() => {
                          setSelectedPost(post);
                          setShowFeedbackListModal(true);
                        }}
                        className="flex-1 inline-flex justify-center items-center px-6 py-3 text-base font-medium text-gray-700 bg-gray-100 rounded-full hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 transition-all duration-200 transform hover:scale-105"
                      >
                        <Eye className="h-5 w-5 mr-2" />
                        View Feedback
                      </button>
                    )}
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </main>

      {/* New Post Modal */}
      {showNewPostModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
          <div className="bg-white rounded-3xl shadow-xl max-w-md w-full max-h-[90vh] overflow-y-auto">
            {/* Credit Warning Section */}
            {creditWarning && (
              <div className="bg-yellow-100 text-yellow-600 border border-yellow-500 rounded-lg p-4 mb-6">{creditWarning}</div>
            )}
            <div className="p-8">
              <div className="flex justify-between items-center mb-6">
                <h3 className="text-2xl font-bold text-gray-900">Get Valuable Feedback on Your Product!</h3>
                <button onClick={() => setShowNewPostModal(false)} className="text-gray-400 hover:text-gray-500">
                  <X className="h-6 w-6" />
                </button>
              </div>
              <form
                onSubmit={(e) => {
                  e.preventDefault();

                  const newPost = {
                    productName: e.target.productName?.value || "", // Check if it exists
                    url: e.target.url?.value || null, // If URL is optional, allow null
                    question: e.target.question?.value || "", // Ensure the question field exists
                    image_or_video_url: e.target.image_or_video_url?.value || null, // Handle optional field properly
                  };

                  addNewPost(newPost);
                }}
                className="space-y-6"
              >
                <div>
                  <label htmlFor="productName" className="block text-sm font-medium text-gray-700 mb-1">
                    Product Name
                  </label>
                  <input
                    type="text"
                    id="productName"
                    name="productName"
                    className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                    required
                  />
                </div>
                {/* <div>
                  <label htmlFor="url" className="block text-sm font-medium text-gray-700 mb-1">
                    Product URL (optional)
                  </label>
                  <input
                    type="url"
                    id="url"
                    name="url"
                    className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                  />
                </div> */}
                <div>
                  <label htmlFor="image_or_video_url" className="block text-sm font-medium text-gray-700 mb-1">
                    Prototype Link
                  </label>
                  <input
                    type="url"
                    id="image_or_video_url"
                    name="image_or_video_url"
                    className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                    required
                  />
                </div>

                <div>
                  <label htmlFor="question" className="block text-sm font-medium text-gray-700 mb-1">
                    What would you like feedback on?
                  </label>
                  <textarea
                    id="question"
                    name="question"
                    rows="3"
                    className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                    required
                  ></textarea>
                </div>

                <div className="flex justify-end space-x-3 mt-8">
                  <button
                    type="button"
                    onClick={() => setShowNewPostModal(false)}
                    className="px-4 py-2 border border-gray-300 rounded-lg text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                  >
                    Submit for Feedback
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}

      {/* Feedback Modals */}
      {showFeedbackModal && selectedPost && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
          <div className="bg-white rounded-3xl shadow-xl max-w-md w-full">
            <div className="p-8">
              <div className="flex justify-between items-center mb-6">
                <h3 className="text-2xl font-bold text-gray-900">Send Private Feedback</h3>
                <button onClick={() => setShowFeedbackModal(false)} className="text-gray-400 hover:text-gray-500">
                  <X className="h-6 w-6" />
                </button>
              </div>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  const newFeedback = {
                    text: e.target.feedback.value,
                  };
                  addFeedback(selectedPost.id, newFeedback);
                }}
                className="space-y-6"
              >
                <div>
                  <label htmlFor="feedback" className="block text-sm font-medium text-gray-700 mb-1">
                    Your Feedback
                  </label>
                  <textarea
                    id="feedback"
                    name="feedback"
                    rows="4"
                    className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                    required
                  ></textarea>
                </div>
                <div className="flex justify-end space-x-3">
                  <button
                    type="button"
                    onClick={() => setShowFeedbackModal(false)}
                    className="px-4 py-2 border border-gray-300 rounded-lg text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                  >
                    Send Feedback
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}

      {showFeedbackListModal && selectedPost && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
          <div className="bg-white rounded-3xl shadow-xl max-w-md w-full max-h-[90vh] overflow-y-auto">
            <div className="p-8">
              <div className="flex justify-between items-center mb-6">
                <h3 className="text-2xl font-bold text-gray-900">Feedback for {selectedPost.product_name}</h3>
                <button onClick={() => setShowFeedbackListModal(false)} className="text-gray-400 hover:text-gray-500">
                  <X className="h-6 w-6" />
                </button>
              </div>
              <ul className="space-y-4">
                {feedback[selectedPost.id]?.map((item) => (
                  <li key={item.id} className="bg-gray-50 p-4 rounded-lg">
                    <p className="text-gray-800 mb-2">{item.text}</p>
                    <div className="flex justify-between items-center">
                      <p className="text-sm text-gray-500">- {item.author}</p>
                      <div className="flex space-x-2">
                        <button
                          onClick={() => reactToFeedback(selectedPost.id, item.id, "like")}
                          className={`p-1 rounded-full ${item.reaction === 1 ? "bg-blue-100" : "hover:bg-gray-200"}`}
                        >
                          <ThumbsUp className={`h-5 w-5 ${item.reaction === 1 ? "text-blue-500" : "text-gray-500"}`} />
                        </button>
                        <button
                          onClick={() => reactToFeedback(selectedPost.id, item.id, "dislike")}
                          className={`p-1 rounded-full ${item.reaction === 2 ? "bg-red-100" : "hover:bg-gray-200"}`}
                        >
                          <ThumbsDown className={`h-5 w-5 ${item.reaction === 2 ? "text-red-500" : "text-gray-500"}`} />
                        </button>
                      </div>
                    </div>
                  </li>
                )) || <li className="text-gray-600">No feedback yet.</li>}
              </ul>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PrivateFeedbackPlatform;
