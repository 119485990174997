// import React from "react";
// import Header from "../components/Header";

// const styles = {
//   fontFamily: "Inter, sans-serif",
// };

// const AboutUs = () => {
//   return (
//     <div className="font-inter antialiased bg-white text-gray-900 tracking-tight">
//       <Header />
//       <div className="grow p-4">
//         <section className="bg-gradient-to-b from-gray-50 to-blue-100 min-h-screen">
//           <div className="max-w-4xl mx-auto py-12">
//             <h1 className="text-3xl font-bold mb-6">About Us</h1>
//             <p className="mb-6 text-lg">
//               Welcome to NeedFinderAI, your ultimate platform for discovering
//               customer needs, collaborating with other innovators, and marketing
//               your products effectively. Our mission is to empower businesses to
//               continuously launch successful products by leveraging AI-driven
//               insights and community collaboration.
//             </p>
//             <h2 className="text-2xl font-semibold mb-4">Our Vision</h2>
//             <p className="mb-6 text-lg">
//               At NeedFinderAI, we envision a world where innovation is
//               democratized, where anyone with a great idea has the tools and
//               support to bring it to life. We aim to be the leading platform
//               where innovators find inspiration, collaborate seamlessly, and
//               market their products authentically.
//             </p>
//             <h2 className="text-2xl font-semibold mb-4">Our Mission</h2>
//             <p className="mb-6 text-lg">
//               Our mission is to provide the tools and insights necessary for
//               businesses to understand customer needs, generate innovative
//               ideas, and engage their target audience effectively. We strive to
//               create an ecosystem where ideas flourish and become reality
//               through collaboration and cutting-edge technology.
//             </p>
//             <h2 className="text-2xl font-semibold mb-4">Our Values</h2>
//             <p className="mb-4 text-lg">
//               <strong>Innovation:</strong> We believe in the power of continuous
//               improvement and pushing the boundaries of what's possible. Our
//               AI-driven tools are designed to uncover hidden opportunities and
//               spark new ideas.
//             </p>
//             <p className="mb-4 text-lg">
//               <strong>Collaboration:</strong> We foster a community where ideas
//               and teamwork thrive. Our virtual coworking cafes are designed to
//               bring innovators together, allowing them to brainstorm, iterate,
//               and refine their ideas in real-time.
//             </p>
//             <p className="mb-4 text-lg">
//               <strong>Customer-Centricity:</strong> Our users' needs drive
//               everything we do. By continuously scouring the web for customer
//               insights, we ensure that your products and features are always
//               aligned with real customer pain points and desires.
//             </p>
//             <p className="mb-6 text-lg">
//               <strong>Integrity:</strong> We operate with honesty and
//               transparency. Our commitment to data privacy and security ensures
//               that your information is always protected.
//             </p>
//             <h2 className="text-2xl font-semibold mb-4">Our Services</h2>
//             <p className="mb-6 text-lg">
//               NeedFinderAI offers a comprehensive suite of services designed to
//               help you at every stage of your product development journey:
//               <ul className="list-disc ml-6 mt-2">
//                 <li className="mb-2">
//                   <strong>AI Need Finder:</strong> Our AI continuously scours
//                   the web for customer insights, revealing pain points, desires,
//                   and trends you can't afford to miss.
//                 </li>
//                 <li className="mb-2">
//                   <strong>Virtual Coworking Spaces:</strong> Collaborate with
//                   fellow innovators in our virtual coworking cafes. Brainstorm,
//                   iterate, and refine your ideas in real-time.
//                 </li>
//                 <li className="mb-2">
//                   <strong>AI-Powered Marketing:</strong> Our AI identifies the
//                   most relevant conversations happening online and crafts
//                   personalized comments that seamlessly introduce your product.
//                 </li>
//               </ul>
//             </p>
//             <h2 className="text-2xl font-semibold mb-4">Join Us</h2>
//             <p className="mb-6 text-lg">
//               Whether you're a seasoned entrepreneur or just starting out,
//               NeedFinderAI is your partner in innovation. Join us and be part of
//               a community dedicated to launching awesome products and features.
//               Start your free trial today and discover how NeedFinderAI can
//               transform your product development process.
//             </p>
//             <h2 className="text-2xl font-semibold mb-4">Contact Us</h2>
//             <p className="mb-6 text-lg">
//               For more information about our company, please visit our website
//               or contact us at{" "}
//               <a
//                 href="mailto:business@needfinderai.com"
//                 className="text-blue-600"
//               >
//                 business@needfinderai.com
//               </a>
//               . We are here to help you on your journey to innovation.
//             </p>
//           </div>
//         </section>
//       </div>
//     </div>
//   );
// };

// export default AboutUs;

import React from "react";
import Header from "../components/Header";

const AboutUs = () => {
  return (
    <div className="font-inter antialiased bg-white text-gray-900 tracking-tight">
      <Header />
      <div className="grow p-4">
        <section className="bg-gradient-to-b from-white to-gray-100 min-h-screen">
          <div className="max-w-5xl mx-auto py-16 px-6">
            <h1 className="text-5xl font-extrabold mb-8 text-center text-gray-800">About Us</h1>
            <p className="mb-8 text-xl text-center text-gray-600">
              Welcome to NeedFinderAI. Discover how we empower businesses to build products that people actually want.
            </p>
            <div className="bg-white p-8 rounded-lg shadow-lg mb-12">
              <h2 className="text-3xl font-semibold mb-4 text-gray-800">Our Vision</h2>
              <p className="mb-6 text-lg text-gray-600">
                At NeedFinderAI, we envision a world where innovation is democratized, where anyone with a great idea has the tools and
                support to bring it to life. We aim to be the leading platform where innovators find inspiration, collaborate seamlessly,
                and market their products authentically.
              </p>
            </div>
            <div className="bg-white p-8 rounded-lg shadow-lg mb-12">
              <h2 className="text-3xl font-semibold mb-4 text-gray-800">Our Mission</h2>
              <p className="mb-6 text-lg text-gray-600">
                Our mission is to provide the tools and insights necessary for businesses to understand customer needs, generate innovative
                ideas, and engage their target audience effectively. We strive to create an ecosystem where ideas flourish and become
                reality through collaboration and cutting-edge technology.
              </p>
            </div>
            <div className="bg-white p-8 rounded-lg shadow-lg mb-12">
              <h2 className="text-3xl font-semibold mb-4 text-gray-800">Our Values</h2>
              <p className="mb-6 text-lg text-gray-600">
                <strong className="text-gray-800">Innovation:</strong> We believe in the power of continuous improvement and pushing the
                boundaries of what's possible. Our AI-driven tools are designed to uncover hidden opportunities and spark new ideas.
              </p>
              <p className="mb-6 text-lg text-gray-600">
                <strong className="text-gray-800">Collaboration:</strong> We foster a community where ideas and teamwork thrive. Our virtual
                coworking cafes are designed to bring innovators together, allowing them to brainstorm, iterate, and refine their ideas in
                real-time.
              </p>
              <p className="mb-6 text-lg text-gray-600">
                <strong className="text-gray-800">Customer-Centricity:</strong> Our users' needs drive everything we do. By continuously
                scouring the web for customer insights, we ensure that your products and features are always aligned with real customer pain
                points and desires.
              </p>
              <p className="text-lg text-gray-600">
                <strong className="text-gray-800">Integrity:</strong> We operate with honesty and transparency. Our commitment to data
                privacy and security ensures that your information is always protected.
              </p>
            </div>
            {/* <div className="bg-white p-8 rounded-lg shadow-lg mb-12">
              <h2 className="text-3xl font-semibold mb-4 text-gray-800">Our Services</h2>
              <ul className="list-disc pl-5 text-lg text-gray-600">
                <li className="mb-4">
                  <strong className="text-gray-800">AI Need Finder:</strong> Our AI continuously scours the web for customer insights,
                  revealing pain points, desires, and trends you can't afford to miss.
                </li>
                <li className="mb-4">
                  <strong className="text-gray-800">Virtual Coworking Spaces:</strong> Collaborate with fellow innovators in our virtual
                  coworking cafes. Brainstorm, iterate, and refine your ideas in real-time.
                </li>
                <li className="mb-4">
                  <strong className="text-gray-800">AI-Powered Marketing:</strong> Our AI identifies the most relevant conversations
                  happening online and crafts personalized comments that seamlessly introduce your product.
                </li>
              </ul>
            </div> */}
            <div className="bg-white p-8 rounded-lg shadow-lg mb-12">
              <h2 className="text-3xl font-semibold mb-4 text-gray-800">Join Us</h2>
              <p className="mb-6 text-lg text-gray-600">
                Whether you're a seasoned entrepreneur or just starting out, NeedFinderAI is your partner in innovation. Join us and be part
                of a community dedicated to launching awesome products and features. Start your free trial today and discover how
                NeedFinderAI can transform your product development process.
              </p>
            </div>
            <div className="bg-white p-8 rounded-lg shadow-lg">
              <h2 className="text-3xl font-semibold mb-4 text-gray-800">Contact Us</h2>
              <p className="text-lg text-gray-600">
                For more information about our company, please visit our website or contact us at{" "}
                <a href="mailto:business@needfinderai.com" className="text-green-600 font-semibold hover:underline">
                  business@needfinderai.com
                </a>
                . We are here to help you on your journey to innovation.
              </p>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default AboutUs;
