import React, { useState, useEffect } from "react";
import axios from "axios";
import DashboardHeader from "./DashboardHeader";
import { Link } from "react-router-dom";
import TopBanner from "./TopBanner";
import Satisfaction from "../images/satisfaction.png";
import SerpAiLogo from "../images/serpai.png";
import TopAiToolsLogo from "../images/topaitools.png";
import ThereIsAnAIForThatLogo from "../images/thereisanaiforthat.png";
import ToolifyLogo from "../images/toolify.png";
import IframeResizer from "iframe-resizer-react";
import api from "./axiosConfig"; // Adjust the path as necessary
import {
  FaQuoteLeft,
  FaEdit,
  FaCalendar,
  FaChartLine,
  FaUnlock,
  FaBookmark,
  FaFileAlt,
  FaSync,
  FaLightbulb,
  FaBullhorn,
} from "react-icons/fa";

const UnpurchasedBanner = () => {
  return (
    <div className="bg-indigo-50 border-t-4 border-indigo-500 rounded-lg text-indigo-900 px-6 py-4 shadow-lg my-4 mx-4">
      <div className="flex flex-col md:flex-row items-center">
        <div className="shrink-0">
          <svg className="fill-current h-8 w-8 text-indigo-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
            <path d="M8.707 14.707a1 1 0 0 1-1.414 0L2 9.414 3.414 8l4.293 4.293L14.586 5H16v1.586l-7.293 7.293z" />
          </svg>
        </div>
        <div className="mt-4 md:mt-0 md:ml-4">
          <h3 className="font-semibold text-lg md:text-4xl mb-5">Unlock Full Potential Of Your Business Idea</h3>
          <p className="mt-1 text-sm md:text-base">
            100+ Page Comprehensive Analysis to turn your rough concept into a bulletproof business plan.
          </p>
          <hr />
          <p className="mt-2 text-md md:text-lg font-semibold italic">
            ⭐⭐⭐⭐⭐ Trusted by over 5,000 businesses, investors, and analysts
          </p>
          <div className="flex flex-wrap justify-center md:justify-start items-center gap-4 mt-4">
            <img src={TopAiToolsLogo} alt="TopAiTools Logo" className="h-10" />
            <span className="font-semibold text-gray-800">TopAiTools</span>
            <img src={SerpAiLogo} alt="Serp.ai Logo" className="h-10" />
            <span className="font-semibold text-gray-800">SERPAI</span>
            <img src={ThereIsAnAIForThatLogo} alt="ThereIsAnAIForThat Logo" className="h-10" />
            <span className="font-semibold text-gray-800">ThereIsAnAIForThat</span>
            <img src={ToolifyLogo} alt="ToolifyLogo" className="h-10" />
            <span className="font-semibold text-gray-800">Toolify.AI</span>
          </div>
        </div>
      </div>
    </div>
  );
};

const formatDate = (dateString) => {
  const options = { year: "numeric", month: "long", day: "numeric" };
  return new Date(dateString).toLocaleDateString(undefined, options);
};

function Credits() {
  const [usage, setUsage] = useState({});
  const [planName, setPlanName] = useState("");
  const [portalUrl, setPortalUrl] = useState("");
  const [email, setEmail] = useState("");
  const [userID, setUserID] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [discountInfo, setDiscountInfo] = useState({});
  const [planStatus, setPlanStatus] = useState("");
  const [planDate, setPlanDate] = useState("");
  const formattedDate = formatDate(planDate);

  const environment = process.env.REACT_APP_ENV;

  const [currency, setCurrency] = useState("USD");
  const [exchangeRates, setExchangeRates] = useState({});
  const [convertedPrices, setConvertedPrices] = useState({
    micro: 29,
    starter: 59,
    pro: 248,
  });

  const currencySymbol = {
    USD: "$",
    EUR: "€",
    GBP: "£",
    INR: "₹",
  };

  useEffect(() => {
    const fetchExchangeRates = async () => {
      try {
        const response = await axios.get("https://v6.exchangerate-api.com/v6/f7fb0657b6f390d95d641701/latest/USD");
        setExchangeRates(response.data.conversion_rates);
      } catch (error) {
        console.error("Error fetching exchange rates:", error);
      }
    };
    fetchExchangeRates();
  }, []);

  useEffect(() => {
    if (exchangeRates[currency]) {
      setConvertedPrices({
        micro: (29 * exchangeRates[currency]).toFixed(2),
        starter: (59 * exchangeRates[currency]).toFixed(2),
        pro: (248 * exchangeRates[currency]).toFixed(2),
      });
    }
  }, [currency, exchangeRates]);

  useEffect(() => {
    const fetchInitialData = async () => {
      setIsLoading(true);
      const token = localStorage.getItem("access");
      if (!token) {
        alert("Please log in first!");
        setIsLoading(false);
        return;
      }

      try {
        const useremailresponse = await api.get("get-user-email/");
        const { email, id } = useremailresponse.data;
        setEmail(email);
        setUserID(id);

        // Fetch user usage and plan details
        const response = await api.get("api/usage/");
        if (response.data) {
          setUsage(response.data.remaining_usage);
          setPlanName(response.data.remaining_usage["product_name"]);
          setPlanStatus(response.data.remaining_usage["status"]);
          setPlanDate(response.data.remaining_usage["billing_date"]);
        }

        // Fetch customer portal URL
        const portalResponse = await api.get("customer-portal-url/");
        if (portalResponse.data) {
          setPortalUrl(portalResponse.data.portal_url);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchInitialData();
  }, []);

  useEffect(() => {
    convertPrices();
  }, [currency, exchangeRates]);

  const convertPrices = () => {
    if (exchangeRates[currency]) {
      setConvertedPrices({
        micro: (29 * exchangeRates[currency]).toFixed(2),
        starter: (59 * exchangeRates[currency]).toFixed(2),
        pro: (248 * exchangeRates[currency]).toFixed(2),
      });
    }
  };

  const calculatePricing = (basePrice) => {
    if (!discountInfo || !discountInfo.discount) return { originalPrice: basePrice, discountedPrice: basePrice };

    const discountedPrice = basePrice * (1 - discountInfo.discount / 100);
    return {
      originalPrice: basePrice,
      discountedPrice: discountedPrice.toFixed(2),
    };
  };

  return (
    <div className="font-inter antialiased bg-gradient-to-b from-gray-50 to-green-100 text-gray-900 tracking-tight">
      <DashboardHeader />

      <div className="min-h-screen px-4 py-20 md:px-16">
        <div className="max-w-8xl mx-auto bg-gradient-to-b from-gray-50 to-green-100 rounded-xl p-6 md:p-8 space-y-6">
          {isLoading ? (
            <div className="text-center text-2xl font-semibold">Loading...</div>
          ) : (
            <>
              {/* Plan Details Section */}
              <div className="max-w-3xl mx-auto mt-5">
                <div className="bg-white shadow-lg rounded-lg overflow-hidden">
                  <div className="bg-gradient-to-r from-green-400 to-green-600 p-2 text-white text-center">
                    <h1 className="text-4xl font-extrabold">Your Plan: {planName}</h1>
                    <p className="mt-2 text-lg">
                      Status: {planStatus === "active" ? "Active" : "Cancelled"}
                      {planStatus === "active" && ` • Renews on ${formattedDate}`}
                    </p>
                  </div>

                  <div className="p-8">
                    <div className="grid grid-cols-1 md:grid-cols-4 gap-2 mb-8">
                      <CreditBox title="Idea Refresh" credit={usage.idea_refresh} icon={<FaSync className="text-green-500" />} />
                      <CreditBox title="Spark an Idea" credit={usage.spark_an_idea} icon={<FaLightbulb className="text-yellow-500" />} />
                      <CreditBox title="Report " credit={usage.mini_report} icon={<FaFileAlt className="text-blue-500" />} />
                      <CreditBox
                        title="Marketing Credits"
                        credit={usage.product_marketing}
                        icon={<FaBullhorn className="text-red-500" />}
                      />
                    </div>

                    <div className="border-t border-gray-200 pt-8">
                      <a
                        href={portalUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="block w-full bg-gradient-to-r from-green-500 to-green-700 hover:from-green-600 hover:to-green-800 text-white font-bold py-4 rounded-lg text-center transition duration-300 ease-in-out transform hover:-translate-y-1 hover:shadow-xl"
                      >
                        Manage Subscription
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div className="space-y-8">
                <h1 className="text-4xl font-bold text-center mt-20" id="pricing-card">
                  Pricing For Every Business*
                </h1>

                <div className="mb-6 text-center">
                  <label htmlFor="currency" className="mr-4">
                    Choose Currency:
                  </label>
                  <select value={currency} onChange={(e) => setCurrency(e.target.value)} className="border rounded p-2">
                    <option value="USD">USD</option>
                    <option value="EUR">EUR</option>
                    <option value="GBP">GBP</option>
                    <option value="INR">INR</option>
                  </select>
                </div>

                <div className="grid grid-cols-1 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-3 gap-8">
                  {[
                    {
                      price: convertedPrices.micro,
                      plan: "Micro",
                      basePrice: 29,
                      benefits: [
                        "1 daily idea refreshes",
                        "25 Spark an Idea requests Per Month",
                        "Access to Coworking Cafes",
                        "No 100+ Page Business Plan",
                        " No AI Marketing ",
                        "No Save/Download Your Report as Docs & PDF",
                      ],
                      linkTo: {
                        local: `https://needfinderai.lemonsqueezy.com/buy/5496cded-a0df-437f-a9f7-dd9ed92f0084?checkout[email]=${email}&checkout[custom][idea_refresh]=1&checkout[custom][spark_an_idea]=25&checkout[custom][mini_report]=0&checkout[custom][product_marketing]=0&checkout[custom][userId]=${userID}`,
                        production: `https://needfinderai.lemonsqueezy.com/buy/257767c8-9cb3-4b39-9f4d-39370b0157f6?checkout[email]=${email}&checkout[custom][idea_refresh]=1&checkout[custom][spark_an_idea]=25&checkout[custom][mini_report]=0&checkout[custom][product_marketing]=0&checkout[custom][userId]=${userID}`,
                      },
                    },
                    {
                      price: convertedPrices.starter,
                      plan: "Starter",
                      basePrice: 59,
                      benefits: [
                        "2 Daily Idea Refreshes",
                        "100 Spark an Idea requests Per Month",
                        "2 Full 100+ Page Business Plan",
                        "100 Reply Per Month",
                        "Access to Coworking Cafes",
                        "Save/Download Your Report as Docs & PDF",
                      ],
                      linkTo: {
                        local: `https://needfinderai.lemonsqueezy.com/buy/94a30d9f-9945-4429-a782-35cee3cc5f95?checkout[email]=${email}&checkout[custom][idea_refresh]=2&checkout[custom][spark_an_idea]=50&checkout[custom][mini_report]=2&checkout[custom][product_marketing]=25&checkout[custom][userId]=${userID}`,
                        production: `https://needfinderai.lemonsqueezy.com/buy/4fb4ab68-d35c-4bfd-8521-3f8d29c083de?checkout[email]=${email}&checkout[custom][idea_refresh]=2&checkout[custom][spark_an_idea]=50&checkout[custom][mini_report]=2&checkout[custom][product_marketing]=25&checkout[custom][userId]=${userID}`,
                      },
                    },
                    {
                      price: convertedPrices.pro,
                      plan: "Pro",
                      basePrice: 248,
                      benefits: [
                        "25 Daily Idea Refreshes",
                        "500 Spark an Idea requests Per Month",
                        "10 Full 100+ Page Business Plan",
                        "500 Reply Per Month",
                        "Access to Coworking Cafes",
                        "Save/Download Your Report as Docs & PDF",
                      ],
                      linkTo: {
                        local: `https://needfinderai.lemonsqueezy.com/buy/bbdeef9b-7279-4d8b-a8af-27cb4633bad7?checkout[email]=${email}&checkout[custom][idea_refresh]=10&checkout[custom][spark_an_idea]=500&checkout[custom][mini_report]=25&checkout[custom][product_marketing]=500&checkout[custom][userId]=${userID}`,
                        production: `https://needfinderai.lemonsqueezy.com/buy/28fd1124-c622-41f6-b863-c2685cadafd4?checkout[email]=${email}&checkout[custom][idea_refresh]=10&checkout[custom][spark_an_idea]=500&checkout[custom][mini_report]=25&checkout[custom][product_marketing]=500&checkout[custom][userId]=${userID}`,
                      },
                    },
                  ].map((plan, index) => (
                    <PricingCard
                      key={index}
                      {...plan}
                      linkTo={plan.linkTo}
                      environment={environment} // Pass environment as a prop
                      isHighlighted={index === 2}
                      originalPrice={calculatePricing(plan.basePrice).originalPrice}
                      price={convertedPrices.micro}
                      discountInfo={discountInfo}
                      currencySymbol={currencySymbol[currency]}
                    />
                  ))}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

function CreditBox({ title, credit }) {
  return (
    <div className="flex flex-col items-center space-y-4">
      <h2 className="text-xl font-semibold">{title}</h2>
      <div className="text-2xl">{credit}</div>
    </div>
  );
}

function PricingCard({ plan, price, originalPrice, discountInfo, benefits, linkTo, environment, isHighlighted = false, currencySymbol }) {
  const highlightedClasses = isHighlighted ? "border border-green-500 bg-green-50 shadow-2xl" : "shadow-lg";

  // Determine if there's a discount
  const hasDiscount = discountInfo && discountInfo.discount;
  const pricingMessage = hasDiscount
    ? `🇮🇳 Special Pricing for ${discountInfo.countryName} - ${discountInfo.discount}% off (Use Code ${discountInfo.code})`
    : "";

  // Modified linkTo to include the discount code dynamically
  const finalLinkTo = hasDiscount ? `${linkTo[environment]}&checkout%5Bdiscount_code%5D=${discountInfo.code}` : linkTo[environment];

  return (
    <div
      className={`max-w-sm mx-auto bg-white rounded-lg overflow-hidden hover:shadow-2xl transition-shadow duration-300 ${highlightedClasses}`}
    >
      {isHighlighted && (
        <div className="text-center py-2 bg-green-100">
          <span className="text-green-600 font-semibold">Best Value Plan!</span>
        </div>
      )}
      <div className="px-6 py-8">
        <div className="text-center">
          <span className="text-3xl font-bold text-gray-900">{plan}</span>
          <div className="mt-2">
            <span className={`text-2xl font-bold ${isHighlighted ? "text-green-600" : "text-gray-900"}`}>
              {plan === "Free" ? "Free" : `${currencySymbol}${price}`}
            </span>
            {originalPrice && hasDiscount && <span className="ml-2 text-xl font-bold line-through text-gray-500">${originalPrice}</span>}
          </div>
        </div>
        {pricingMessage && (
          <div className="text-center mt-4">
            <span className="text-green-600 font-semibold">{pricingMessage}</span>
          </div>
        )}
        <hr className="my-6 border-gray-200" />
        <ul className="mb-4 space-y-3">
          {benefits.map((benefit, idx) => (
            <Feature key={idx}>{benefit}</Feature>
          ))}
        </ul>
      </div>
      {price !== "$0" && (
        <div className="px-6 pt-6 pb-8 bg-gray-50 rounded-b-lg">
          <Link to={finalLinkTo}>
            <button
              className={`btn-sm block w-full px-4 py-3 text-sm font-semibold text-center text-white ${
                isHighlighted
                  ? "bg-green-600 hover:bg-green-700"
                  : "bg-gradient-to-r from-green-600 to-green-600 hover:bg-gradient-to-r hover:from-green-600 hover:to-green-700 animate-pulse"
              }`}
            >
              {isHighlighted ? "Choose This Plan" : "Buy Now"}
            </button>
          </Link>
        </div>
      )}
    </div>
  );
}

const Feature = ({ children }) => {
  const styleSpecificPhrases = (text) => {
    if (text.includes("Full")) {
      return <span className="font-bold text-black">{text}</span>;
    } else if (text.includes("Feedbacks")) {
      return <span className="font-bold text-black">{text}</span>;
    } else {
      return text;
    }
  };

  return (
    <li className="flex items-center mb-2">
      <svg className="w-3 h-3 fill-current text-green-500 mr-3 shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
        <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
      </svg>
      <span className="text-gray-700">{styleSpecificPhrases(children)}</span>
    </li>
  );
};

export default Credits;
