import React, { useState, useEffect } from "react";
import axios from "./axiosConfig";
import {
  Coffee,
  Users,
  Info,
  Star,
  MessageCircle,
  X,
  Send,
  Play,
  Pause,
  RefreshCw,
  ExternalLink,
  CheckCircle,
  ChevronDown,
  ChevronUp,
  Edit,
  Search,
  Zap,
  Loader,
  AlertCircle,
  Target,
  TrendingUp,
  Copy,
  Sparkles,
  Lightbulb,
  Plus,
} from "lucide-react";
import { motion, AnimatePresence } from "framer-motion";

const AnalysisProgressModal = ({ isOpen, onClose, progress }) => {
  const [status, setStatus] = useState("progress"); // 'progress', 'success', 'error'

  useEffect(() => {
    if (progress === 100) {
      setStatus("success");
      setTimeout(() => onClose(), 2000);
    }
  }, [progress, onClose]);

  if (!isOpen) return null;

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm flex items-center justify-center z-50"
    >
      <motion.div
        initial={{ scale: 0.9, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        className="bg-white rounded-2xl p-8 max-w-md w-full shadow-2xl"
      >
        <div className="text-center">
          {status === "progress" && (
            <>
              <Loader className="w-16 h-16 text-blue-500 animate-spin mx-auto mb-4" />
              <h2 className="text-2xl font-semibold text-gray-800 mb-2">Analyzing Opportunities</h2>
              <p className="text-gray-600 mb-4">Please wait while we find the best matches for your product.</p>
              <div className="w-full bg-gray-200 rounded-full h-2.5 mb-4">
                <div className="bg-blue-600 h-2.5 rounded-full" style={{ width: `${progress}%` }}></div>
              </div>
              <p className="text-sm text-gray-500">{progress}% Complete</p>
            </>
          )}
          {status === "success" && (
            <>
              <CheckCircle className="w-16 h-16 text-green-500 mx-auto mb-4" />
              <h2 className="text-2xl font-semibold text-gray-800 mb-2">Analysis Complete</h2>
              <p className="text-gray-600">Your results are ready to view.</p>
            </>
          )}
          {status === "error" && (
            <>
              <AlertCircle className="w-16 h-16 text-red-500 mx-auto mb-4" />
              <h2 className="text-2xl font-semibold text-gray-800 mb-2">Analysis Failed</h2>
              <p className="text-gray-600">Please try again later.</p>
            </>
          )}
        </div>
      </motion.div>
    </motion.div>
  );
};

const CustomAlert = ({ message, onClose }) => (
  <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4" role="alert">
    <strong className="font-bold mr-2">Error:</strong>
    <span className="block sm:inline">{message}</span>
    <span className="absolute top-0 bottom-0 right-0 px-4 py-3" onClick={onClose}>
      <X size={18} />
    </span>
  </div>
);
const getSourceLabel = (url) => {
  if (typeof url !== "string") return "";
  if (url.includes("x.com") || url.includes("twitter.com")) return "Twitter";
  if (url.includes("reddit.com")) return "Reddit";
  return "";
};

const AIMarketingTab = ({ setActiveTab }) => {
  const [showModal, setShowModal] = useState(false);
  const [productDetails, setProductDetails] = useState({ name: "", url: "", description: "", keywords: [] });

  const [isPremium, setIsPremium] = useState(false);
  const [expandedCard, setExpandedCard] = useState(null);
  const [credits, setCredits] = useState(0);
  // const [credits, setCredits] = useState(userDetails?.ai_marketing_suggestions || 0);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [opportunities, setOpportunities] = useState([]);
  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [loadingProgress, setLoadingProgress] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [refreshKey, setRefreshKey] = useState(0);
  const [analysisProgress, setAnalysisProgress] = useState(0);
  const [userDetails, setUserDetails] = useState(null);
  const [userPlan, setUserPlan] = useState("");
  const [isAnalysisModalOpen, setIsAnalysisModalOpen] = useState(false);

  // Define keyword limits based on plan
  const keywordLimits = {
    Free: 3,
    Starter: 3,
    Innovator: 3,
    Visionary: 5,
    Pioneer: 10,
  };

  // Function to check if all form fields are filled
  const formIsValid = () => {
    const { name, url, description, keywords } = productDetails;
    return name.trim() !== "" && url.trim() !== "" && description.trim() !== "" && keywords.length > 0;
  };
  const fetchUserDetails = async () => {
    try {
      const response = await axios.get("user-details/");
      setUserDetails(response.data);
      setCredits(response.data.ai_marketing_suggestions);
      setUserPlan(response.data.current_plan);
      setIsPremium(response.data.current_plan !== "Free");
    } catch (error) {
      console.error("Failed to fetch user details:", error);
    }
  };
  useEffect(() => {
    fetchUserDetails();
  }, []);

  useEffect(() => {
    if (userDetails) {
      fetchUserProducts(); // Fetch user products when userDetails is available
    }
  }, [userDetails]);

  // Fetch user products and promotion posts based on productId
  const fetchUserProducts = async () => {
    try {
      const response = await axios.get("loggedin-user-products/");
      const fetchedProducts = response.data;

      setProducts(fetchedProducts);

      // Ensure there's a valid product and set it as selected
      if (fetchedProducts.length > 0) {
        const firstProduct = fetchedProducts[0];
        setSelectedProduct(firstProduct); // Set the first product as selected

        // Fetch promotion posts only when there is a valid product ID
        if (firstProduct.id) {
          await fetchUserPromotionPosts(firstProduct.id);
        }
      }
    } catch (error) {
      console.error("Error fetching user products:", error);
      // showAlertMessage("Failed to fetch user products.");
    }
  };

  const maxKeywords = keywordLimits[userPlan] || 3;

  const handleKeywordChange = (e) => {
    const keywords = e.target.value.split(",").map((keyword) => keyword.trim());

    // Validate the number of keywords
    if (keywords.length > maxKeywords) {
      alert(`You can only add up to ${maxKeywords} keywords based on your ${userPlan} plan.`);
      return;
    }

    setProductDetails({ ...productDetails, keywords });
  };

  // Fetch promotion posts by productId
  const fetchUserPromotionPosts = async (productId) => {
    try {
      const response = await axios.get(`fetch-user-promotion-posts/${productId}/`);
      const cleanedOpportunities = response.data; // Apply the cleaning function
      setOpportunities(cleanedOpportunities); // Set the cleaned opportunities
      // Automatically expand the first opportunity if any exist
      if (cleanedOpportunities.length > 0) {
        setExpandedCard(cleanedOpportunities[0].id);
      }
    } catch (error) {
      console.error("Error fetching user promotion posts:", error);
      // showAlertMessage("Failed to fetch promotion posts.");
    }
  };

  const createProduct = async () => {
    setIsLoading(true);
    try {
      const response = await axios.post("create-product/", productDetails);
      setProducts([...products, response.data]);
      setSelectedProduct(response.data);
      setShowModal(false);
      await fetchUserPromotionPosts(response.data.id);
      window.location.reload(); // Reloads the page after product creation

      // Increment refresh key to force re-render
      setRefreshKey((prevKey) => prevKey + 1);
    } catch (error) {
      console.error("Error creating product:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const editProduct = async () => {
    setIsLoading(true); // Set loading state to true
    try {
      const response = await axios.put(`update-product/${selectedProduct.id}/`, productDetails);
      setProducts(products.map((p) => (p.id === selectedProduct.id ? response.data : p)));
      setSelectedProduct(response.data);
      setShowModal(false);
      window.location.reload(); // Reloads the page after product edit

      //     // Increment refresh key to force re-render
      setRefreshKey((prevKey) => prevKey + 1);
    } catch (error) {
      console.error("Error editing product:", error);
      // showAlertMessage("Failed to edit product.");
    } finally {
      setIsLoading(false); // Set loading state to false after the operation
    }
  };

  // Fetch analysis and automatically fetch updated posts after analysis is complete
  // const fetchAnalysis = async () => {
  //   // Check if selectedProduct and selectedProduct.id are valid
  //   if (!selectedProduct || !selectedProduct.id) {
  //     // showAlertMessage("No product selected or invalid product ID.");
  //     return;
  //   }

  //   if (credits < 1) {
  //     showAlertMessage("Not enough credits.Please upgrade your plan to unlock 100  more AI Marketing credits.");
  //     return;
  //   }

  //   setIsRefreshing(true);
  //   try {
  //     const response = await axios.post(`fetch-analysis/${selectedProduct.id}/`);
  //     // setCredits((prevCredits) => prevCredits - 10);

  //     // Once analysis is successful, fetch the updated posts
  //     await fetchUserPromotionPosts(selectedProduct.id);
  //     window.location.reload(); // Reloads the page after fetching analysis

  //     // showAlertMessage("Analysis complete. Updated posts fetched.");
  //   } catch (error) {
  //     console.error("Error fetching analysis:", error);
  //     // showAlertMessage("Failed to fetch analysis.");
  //   } finally {
  //     setIsRefreshing(false);
  //   }
  // };

  const fetchAnalysis = async () => {
    if (!selectedProduct || !selectedProduct.id || credits < 1) {
      showAlertMessage("Not enough credits. Please upgrade your plan.");
      return;
    }

    setIsAnalysisModalOpen(true);
    setIsRefreshing(true);

    try {
      // Simulate progress updates
      const progressInterval = setInterval(() => {
        setAnalysisProgress((prev) => Math.min(prev + 5, 95));
      }, 2000);

      const response = await axios.post(`fetch-analysis/${selectedProduct.id}/`);

      clearInterval(progressInterval);
      setAnalysisProgress(100);

      await fetchUserPromotionPosts(selectedProduct.id);

      // Close modal and refresh data
      setTimeout(() => {
        setIsAnalysisModalOpen(false);
        setIsRefreshing(false);
        // Refresh your data here
      }, 2000);
    } catch (error) {
      console.error("Error fetching analysis:", error);
      setIsAnalysisModalOpen(false);
      setIsRefreshing(false);
      showAlertMessage("Failed to fetch analysis.");
    }
  };

  const togglePostCompletion = async (postId) => {
    try {
      const response = await axios.post(`toggle-post-completion/${postId}/`);
      setOpportunities((prevOpportunities) =>
        prevOpportunities
          .map((opp) => (opp.id === postId ? { ...opp, completed: response.data.open_insight } : opp))
          .sort((a, b) => (a.completed === b.completed ? 0 : a.completed ? 1 : -1))
      );
      setExpandedCard(null);
    } catch (error) {
      console.error("Error toggling post completion:", error);
    }
  };

  const toggleCard = (id) => {
    setExpandedCard(expandedCard === id ? null : id);
  };

  const openEditModal = (product) => {
    setProductDetails({
      name: product.name,
      url: product.product_url,
      description: product.description,
      keywords: Array.isArray(product.keywords) ? product.keywords.join(", ") : product.keywords || "",
    });
    setShowModal(true);
  };

  const showAlertMessage = (message) => {
    setAlertMessage(message);
    setShowAlert(true);
    setTimeout(() => setShowAlert(false), 3000);
  };
  const handleUpgradeClick = () => {
    setActiveTab("subscriptions"); // Switch to the subscriptions tab
  };

  const FeatureIcon = ({ Icon, text }) => (
    <div className="flex items-center space-x-2">
      <Icon className="w-5 h-5" />
      <span className="text-sm">{text}</span>
    </div>
  );

  return (
    <div key={refreshKey} className="bg-gradient-to-b from-blue-50 to-white min-h-screen">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-6">
        <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-4 py-2 space-y-2 mb-16">
          <motion.div
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            className="bg-gradient-to-br from-blue-800 via-indigo-900 to-purple-900 rounded-3xl p-8 sm:p-12 text-white shadow-xl transform transition-all duration-500 hover:shadow-2xl hover:scale-[1.02]"
          >
            <div className="flex items-center space-x-4 mb-8">
              <Sparkles className="w-10 h-10 text-yellow-300 animate-pulse" />
              <h2 className="text-3xl sm:text-3xl font-bold tracking-tight">What to Expect In AI Mention?</h2>
            </div>

            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
              <FeatureIcon
                Icon={Users}
                text="AI  monitors the internet every few hours to identify high-impact conversations where your product naturally fits."
              />
              <FeatureIcon
                Icon={Lightbulb}
                text="AI  crafts subtle, context-appropriate comments that organically introduce your product, avoiding any hint of spam."
              />
              <FeatureIcon
                Icon={Zap}
                text="Within 1-2 weeks, notice an uptick in users acquisition coming from replies that are helpful."
              />
            </div>
          </motion.div>
        </div>

        {showAlert && <CustomAlert message={alertMessage} onClose={() => setShowAlert(false)} />}

        {products.length === 0 ? (
          <div className="text-center p-8 bg-white rounded-3xl shadow-xl mb-12 max-w-2xl mx-auto">
            <Sparkles className="w-16 h-16 text-blue-500 mx-auto mb-4" />
            <h2 className="text-3xl font-bold text-blue-900 mb-4">Welcome to AI Marketing Assistant!</h2>
            <p className="text-lg text-blue-700 mb-6">Let's get started by adding your first product.</p>
            <button
              onClick={() => setShowModal(true)}
              className="bg-blue-600 text-white px-8 py-4 rounded-full hover:bg-blue-700 transition-all duration-300 font-medium text-lg uppercase tracking-wider shadow-lg transform hover:scale-105"
            >
              <Plus size={24} className="inline-block mr-2" />
              Add Your First Product
            </button>
          </div>
        ) : (
          <>
            <div className="flex flex-col sm:flex-row justify-between items-center mb-12 space-y-4 sm:space-y-0">
              <div className="flex items-center space-x-6">
                <div className="text-center">
                  <span className="text-3xl font-bold text-blue-600">{credits}</span>
                  <p className="text-sm text-gray-600">Available Credits</p>
                </div>
                <button
                  onClick={fetchAnalysis}
                  className={`bg-blue-600 text-white px-6 py-3 rounded-full hover:bg-blue-700 transition-colors duration-200 font-medium text-sm uppercase tracking-wider shadow-lg flex items-center ${
                    isRefreshing ? "opacity-50 cursor-not-allowed" : ""
                  }`}
                  disabled={isRefreshing}
                >
                  {isRefreshing ? <Loader size={20} className="animate-spin mr-2" /> : <RefreshCw size={20} className="mr-2" />}
                  {isRefreshing ? "Analyzing.Please wait 2-3 minutes..." : "Fetch More Opportunities"}
                </button>
              </div>
              <button
                onClick={() => openEditModal(selectedProduct || {})}
                className="bg-blue-600 text-white px-8 py-4 rounded-full hover:bg-blue-700 transition-colors duration-200 font-medium text-sm uppercase tracking-wider shadow-lg flex items-center"
              >
                <Edit size={20} className="mr-2" />
                {selectedProduct ? "Edit Product" : "Add New Product"}
              </button>
            </div>

            <div className="space-y-8">
              <AnimatePresence>
                {opportunities.map((opportunity) => (
                  <motion.div
                    key={opportunity.id}
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -20 }}
                    transition={{ duration: 0.3 }}
                    className={`bg-white rounded-2xl shadow-lg overflow-hidden border border-blue-100 transition-all duration-300 ${
                      opportunity.open_insight ? "opacity-50" : ""
                    }`}
                  >
                    <div
                      className="flex flex-col p-6 cursor-pointer hover:bg-blue-50 transition-colors duration-200"
                      onClick={() => toggleCard(opportunity.id)}
                    >
                      <div className="flex justify-between items-start">
                        <div className={`flex-grow ${opportunity.open_insight ? "line-through" : ""}`}>
                          <h3 className="text-2xl font-semibold text-blue-900 mb-2">{opportunity.details.post_title}</h3>
                          <div className="flex items-center space-x-4 mb-4">
                            <div className="flex items-center space-x-1 bg-blue-100 px-3 py-1 rounded-full">
                              <Zap size={16} className="text-blue-600" />
                              <span className="text-sm font-medium text-blue-800">
                                Engagement: {opportunity.details.like_count}, {opportunity.details.timestamp}
                                {/* {opportunity.details.engagement_quality_score} */}
                                {/* <span className="text-blue-600">/100</span> */}
                              </span>
                              {/* <span className="text-blue-600">Engagement: {opportunity.details.like_count}</span> */}
                            </div>
                            {getSourceLabel(opportunity.details.post_url) && (
                              <span
                                className="px-3 py-1 rounded-full text-white text-xs font-semibold"
                                style={{
                                  backgroundColor: getSourceLabel(opportunity.details.post_url) === "Twitter" ? "#1DA1F2" : "#FF4500",
                                }}
                              >
                                {getSourceLabel(opportunity.details.post_url)}
                              </span>
                            )}
                          </div>
                        </div>
                        <motion.div animate={{ rotate: expandedCard === opportunity.id ? 180 : 0 }} transition={{ duration: 0.3 }}>
                          {expandedCard === opportunity.id ? (
                            <ChevronUp size={24} className="text-blue-400" />
                          ) : (
                            <ChevronDown size={24} className="text-blue-400" />
                          )}
                        </motion.div>
                      </div>
                      <div className="bg-blue-50 p-4 rounded-xl">
                        <h4 className="text-lg font-medium text-blue-800 mb-2 flex items-center">
                          <MessageCircle size={20} className="mr-2 text-blue-600" />
                          Excerpt
                        </h4>
                        <p className="text-blue-700">{opportunity.details.post_excerpt}</p>
                      </div>
                    </div>

                    <AnimatePresence>
                      {expandedCard === opportunity.id && !opportunity.open_insight && (
                        <motion.div
                          initial={{ opacity: 0, height: 0 }}
                          animate={{ opacity: 1, height: "auto" }}
                          exit={{ opacity: 0, height: 0 }}
                          transition={{ duration: 0.3 }}
                          className="p-6 border-t border-blue-100"
                        >
                          <div className="bg-gradient-to-r from-blue-50 to-indigo-50 p-6 rounded-xl mb-6">
                            <h4 className="font-semibold mb-3 text-blue-800 flex items-center">
                              <Sparkles size={20} className="mr-2 text-blue-600" />
                              AI-Crafted Response:
                            </h4>
                            <p className="text-blue-700">{opportunity.details.comment}</p>
                          </div>
                          <div className="flex flex-wrap justify-between items-center gap-4">
                            <motion.a
                              whileHover={{ scale: 1.05 }}
                              whileTap={{ scale: 0.95 }}
                              href={opportunity.details.post_url}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="bg-blue-600 text-white px-6 py-3 rounded-full hover:bg-blue-700 transition-colors duration-200 flex items-center font-medium text-sm uppercase tracking-wider shadow-lg"
                            >
                              <ExternalLink size={18} className="mr-2" /> View Original Post
                            </motion.a>
                            <motion.button
                              whileHover={{ scale: 1.05 }}
                              whileTap={{ scale: 0.95 }}
                              onClick={() => togglePostCompletion(opportunity.id)}
                              className="text-green-600 hover:text-green-700 font-medium flex items-center text-sm"
                            >
                              <CheckCircle size={18} className="mr-2" /> Mark as Done
                            </motion.button>
                          </div>
                        </motion.div>
                      )}
                    </AnimatePresence>
                  </motion.div>
                ))}
              </AnimatePresence>
            </div>
          </>
        )}
      </div>

      {showModal && (
        <div className="fixed inset-0 bg-black bg-opacity-40 flex items-center justify-center p-4 z-50">
          <div className="bg-white rounded-3xl p-8 max-w-lg w-full shadow-2xl relative">
            {/* Modal Header */}
            <h3 className="text-2xl font-bold mb-8 text-center text-blue-900">
              {selectedProduct ? "Optimize Your Product" : "Add New Product"}
            </h3>

            {/* Product Name Input */}
            <div className="mb-5">
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Product Name <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                placeholder="e.g., NeedFinderAI"
                className="w-full p-3 border border-blue-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 text-blue-900"
                value={productDetails.name}
                onChange={(e) => setProductDetails({ ...productDetails, name: e.target.value })}
                required
              />
            </div>

            {/* Product URL Input */}
            <div className="mb-5">
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Product URL <span className="text-red-500">*</span>
              </label>
              <input
                type="url"
                placeholder="e.g., https://needfinderai.com"
                className="w-full p-3 border border-blue-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 text-blue-900"
                value={productDetails.url}
                onChange={(e) => setProductDetails({ ...productDetails, url: e.target.value })}
                required
              />
            </div>

            {/* Product Description Input */}
            <div className="mb-5">
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Product Description <span className="text-red-500">*</span>
              </label>

              <textarea
                placeholder="Describe your product in detail"
                className="w-full p-3 border border-blue-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 text-blue-900"
                rows="4"
                value={productDetails.description}
                onChange={(e) => setProductDetails({ ...productDetails, description: e.target.value })}
                required
              ></textarea>
            </div>

            {/* Keywords Input */}
            <div className="mb-5">
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Keywords (Max: {maxKeywords}) <span className="text-red-500">*</span>
              </label>
              <textarea
                placeholder={`Add keywords separated by commas. Higher plans allow more keywords.`}
                className="w-full p-3 border border-blue-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 text-blue-900"
                rows="2"
                // value={(productDetails.keywords || []).join(", ")}
                value={Array.isArray(productDetails.keywords) ? productDetails.keywords.join(", ") : productDetails.keywords || ""} // This line fixes the issue
                onChange={handleKeywordChange}
                required
              />
              <p className="text-xs text-gray-500 mt-2">
                You can add up to <span className="font-semibold">{maxKeywords}</span> keywords based on your current plan.
                <a onClick={handleUpgradeClick} className="text-blue-600 hover:underline cursor-pointer">
                  Upgrade your plan
                </a>
                &nbsp; to add more keywords.
              </p>
            </div>

            {/* Buttons */}
            <div className="flex justify-end space-x-3">
              <button
                onClick={() => setShowModal(false)}
                className="px-6 py-2 border border-gray-300 rounded-md text-gray-700 hover:bg-gray-50 transition-colors duration-150 text-sm"
              >
                Cancel
              </button>
              <button
                onClick={selectedProduct ? editProduct : createProduct}
                className={`px-8 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition-colors duration-150 text-sm flex items-center shadow ${
                  isLoading || !formIsValid() ? "opacity-50 cursor-not-allowed" : ""
                }`}
                disabled={isLoading || !formIsValid()}
              >
                {isLoading ? <Loader size={18} className="animate-spin mr-2" /> : null}
                {selectedProduct ? "Save Changes" : "Add Product"}
              </button>
            </div>
          </div>
        </div>
      )}

      <AnalysisProgressModal isOpen={isAnalysisModalOpen} onClose={() => setIsAnalysisModalOpen(false)} progress={analysisProgress} />
    </div>
  );
};

export default AIMarketingTab;
