// import React, { useState, useEffect } from "react";
// import {
//   Coffee,
//   Users,
//   Info,
//   Star,
//   MessageCircle,
//   X,
//   Send,
//   Play,
//   Pause,
//   RefreshCw,
// } from "lucide-react";

// const BusinessPlanTab = () => (
//   <div className="space-y-8 max-w-4xl mx-auto">
//     <h2 className="text-4xl font-bold text-gray-900 mb-8">
//       Business Plan Generator
//     </h2>
//     <div className="bg-white p-8 rounded-2xl shadow-lg">
//       <h3 className="text-2xl font-bold mb-6 text-gray-800">
//         Selected Insight
//       </h3>
//       <p className="mb-6 text-gray-700 text-lg">
//         AI-driven personalization is becoming a key differentiator in B2B SaaS.
//       </p>
//       <button className="bg-blue-600 text-white px-8 py-4 rounded-xl hover:bg-blue-700 transition-colors duration-200 text-lg font-semibold">
//         Generate In-Depth Business Plan
//       </button>
//     </div>
//     <div className="bg-white p-8 rounded-2xl shadow-lg">
//       <h3 className="text-2xl font-bold mb-6 text-gray-800">Recent Reports</h3>
//       <ul className="space-y-4">
//         <li className="flex justify-between items-center border-b border-gray-200 pb-4">
//           <span className="text-gray-700 text-lg">
//             Sustainable E-commerce Packaging Solutions
//           </span>
//           <button className="text-blue-600 hover:text-blue-700 font-semibold">
//             View
//           </button>
//         </li>
//         <li className="flex justify-between items-center">
//           <span className="text-gray-700 text-lg">
//             Mental Health App for Remote Workers
//           </span>
//           <button className="text-blue-600 hover:text-blue-700 font-semibold">
//             View
//           </button>
//         </li>
//       </ul>
//     </div>
//   </div>
// );

// export default BusinessPlanTab;

import React, { useState, useEffect } from "react";
import { ChevronRight, ChevronDown, RefreshCw, Loader2, FileText, X, ChevronLeft, CreditCard } from "lucide-react";

const DynamicBusinessPlanner = () => {
  const [selectedInsight, setSelectedInsight] = useState("");
  const [customInsight, setCustomInsight] = useState("");
  const [tableOfContents, setTableOfContents] = useState([]);
  const [expandedSections, setExpandedSections] = useState({});
  const [isGenerating, setIsGenerating] = useState(false);
  const [generatingContent, setGeneratingContent] = useState({});
  const [sectionContents, setSectionContents] = useState({});
  const [reports, setReports] = useState([
    {
      id: 1,
      title: "AI-driven B2B SaaS Personalization",
      date: "2024-08-10",
      insight:
        "AI-driven personalization is becoming a key differentiator in B2B SaaS. This trend is revolutionizing how businesses interact with their customers, leading to improved user experiences and higher retention rates.",
    },
    {
      id: 2,
      title: "Sustainable E-commerce Packaging",
      date: "2024-08-05",
      insight:
        "There's a growing demand for sustainable packaging solutions in e-commerce. Consumers are increasingly conscious of environmental impact, pushing businesses to innovate in eco-friendly packaging designs.",
    },
    {
      id: 3,
      title: "Remote Work Mental Health Support",
      date: "2024-07-28",
      insight:
        "Remote workers are increasingly seeking mental health support through digital platforms. The shift to remote work has highlighted the need for accessible mental health resources, driving innovation in teletherapy and digital wellness solutions.",
    },
  ]);
  const [selectedReport, setSelectedReport] = useState(null);
  const [viewMode, setViewMode] = useState("planner"); // 'planner' or 'report'
  const [businessPlanCredits, setBusinessPlanCredits] = useState(5); // Mock initial credits

  const insights = [
    {
      id: 1,
      title: "AI-driven personalization in B2B SaaS",
      description:
        "AI-driven personalization is becoming a key differentiator in B2B SaaS. This trend is revolutionizing how businesses interact with their customers, leading to improved user experiences and higher retention rates. Machine learning algorithms are being employed to analyze user behavior and preferences, allowing for tailored product recommendations, customized user interfaces, and predictive analytics that anticipate customer needs.",
    },
    {
      id: 2,
      title: "Sustainable packaging in e-commerce",
      description:
        "There's a growing demand for sustainable packaging solutions in e-commerce. Consumers are increasingly conscious of environmental impact, pushing businesses to innovate in eco-friendly packaging designs. This trend is driving the development of biodegradable materials, minimalist packaging approaches, and circular economy initiatives in the e-commerce sector.",
    },
    {
      id: 3,
      title: "Digital mental health support for remote workers",
      description:
        "Remote workers are increasingly seeking mental health support through digital platforms. The shift to remote work has highlighted the need for accessible mental health resources, driving innovation in teletherapy and digital wellness solutions. Companies are integrating mental health support into their employee benefits, recognizing its importance for productivity and overall well-being in the distributed workforce.",
    },
  ];

  const generateTableOfContents = async () => {
    if (businessPlanCredits <= 0) {
      alert("You've run out of business plan credits. Please upgrade your plan.");
      return;
    }

    setIsGenerating(true);
    setViewMode("planner");
    // Simulating API call to generate dynamic ToC
    await new Promise((resolve) => setTimeout(resolve, 1500));

    // This is where you'd normally get the response from your backend
    const mockToC = generateMockToC(selectedInsight || customInsight);

    setTableOfContents(mockToC);
    setIsGenerating(false);
    setBusinessPlanCredits((prev) => prev - 1);
  };

  const generateMockToC = (idea) => {
    const baseStructure = [
      { title: "Executive Summary", content: "" },
      { title: "Market Analysis", content: "" },
      { title: "Product/Service Description", content: "" },
      { title: "Marketing Strategy", content: "" },
      { title: "Operations Plan", content: "" },
      { title: "Financial Projections", content: "" },
    ];

    // You can add logic here to customize the ToC based on the idea
    return baseStructure;
  };

  const toggleSection = (index) => {
    setExpandedSections((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  const generateSectionContent = async (index) => {
    setGeneratingContent((prev) => ({ ...prev, [index]: true }));
    // Simulating content generation (2-3 minutes)
    await new Promise((resolve) => setTimeout(resolve, Math.random() * 60000 + 120000));

    // Mock content generation
    const mockContent = `This is the generated content for ${
      tableOfContents[index].title
    }. It would typically include detailed information relevant to this section of the business plan based on the insight: "${
      selectedInsight || customInsight
    }".

    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam auctor, nisi vel ultricies lacinia, nunc nisl aliquam mauris, vel tincidunt nunc nunc vel nunc. Sed euismod, nunc vel ultricies lacinia, nunc nisl aliquam mauris, vel tincidunt nunc nunc vel nunc.

    Praesent vel nisi eget nunc ultricies lacinia. Sed euismod, nunc vel ultricies lacinia, nunc nisl aliquam mauris, vel tincidunt nunc nunc vel nunc. Sed euismod, nunc vel ultricies lacinia, nunc nisl aliquam mauris, vel tincidunt nunc nunc vel nunc.`;

    setSectionContents((prev) => ({ ...prev, [index]: mockContent }));
    setGeneratingContent((prev) => ({ ...prev, [index]: false }));
  };

  const viewReport = (report) => {
    setSelectedReport(report);
    setViewMode("report");
    // Reset expanded sections for the report view
    setExpandedSections({});
  };

  return (
    <div className="max-w-6xl mx-auto p-8 bg-gray-50">
      <h1 className="text-4xl font-bold mb-8 text-center text-blue-600">Business Plan Generator</h1>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
        <div className="col-span-2">
          {viewMode === "planner" ? (
            <>
              <div className="bg-white rounded-2xl shadow-lg p-6 mb-8">
                <div className="flex justify-between items-center mb-4">
                  <h2 className="text-2xl font-semibold text-gray-800">Select or Enter an Insight</h2>
                  <div className="flex items-center bg-blue-100 text-blue-800 px-3 py-1 rounded-full">
                    <CreditCard size={16} className="mr-2" />
                    <span className="font-medium">{businessPlanCredits} credits left</span>
                  </div>
                </div>
                <select
                  className="w-full p-3 mb-4 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                  value={selectedInsight}
                  onChange={(e) => {
                    setSelectedInsight(e.target.value);
                    setCustomInsight("");
                  }}
                >
                  <option value="">Choose an insight...</option>
                  {insights.map((insight) => (
                    <option key={insight.id} value={insight.title}>
                      {insight.title}
                    </option>
                  ))}
                </select>
                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700 mb-2">Or enter your own insight:</label>
                  <textarea
                    value={customInsight}
                    onChange={(e) => {
                      setCustomInsight(e.target.value);
                      setSelectedInsight("");
                    }}
                    className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                    placeholder="Enter your custom insight..."
                    rows={4}
                  />
                </div>
                {(selectedInsight || customInsight) && (
                  <div className="mt-4 p-4 bg-blue-50 rounded-lg">
                    <h3 className="font-semibold text-blue-800 mb-2">Selected Insight:</h3>
                    <p className="text-blue-700">
                      {selectedInsight ? insights.find((i) => i.title === selectedInsight)?.description : customInsight}
                    </p>
                  </div>
                )}
                <button
                  onClick={generateTableOfContents}
                  className="w-full mt-6 bg-blue-600 text-white py-3 px-6 rounded-xl hover:bg-blue-700 transition-colors duration-200 flex items-center justify-center font-semibold"
                  disabled={isGenerating || (!selectedInsight && !customInsight) || businessPlanCredits <= 0}
                >
                  {isGenerating ? <Loader2 className="animate-spin mr-2" size={20} /> : <RefreshCw size={20} className="mr-2" />}
                  {isGenerating ? "Generating..." : "Generate Business Plan"}
                </button>
              </div>

              {tableOfContents.length > 0 && (
                <div className="bg-white rounded-2xl shadow-lg p-6">
                  <h2 className="text-2xl font-semibold mb-4 text-gray-800">Dynamic Business Plan</h2>
                  {tableOfContents.map((section, index) => (
                    <div key={index} className="mb-4">
                      <div
                        className="flex items-center justify-between bg-gray-100 p-3 rounded-md cursor-pointer"
                        onClick={() => toggleSection(index)}
                      >
                        <span className="font-medium">{section.title}</span>
                        {expandedSections[index] ? <ChevronDown size={20} /> : <ChevronRight size={20} />}
                      </div>
                      {expandedSections[index] && (
                        <div className="ml-6 mt-2">
                          {generatingContent[index] ? (
                            <div className="flex items-center text-gray-600">
                              <Loader2 className="animate-spin mr-2" size={16} />
                              Generating...
                            </div>
                          ) : sectionContents[index] ? (
                            <p className="text-gray-700 mt-2 whitespace-pre-line">{sectionContents[index]}</p>
                          ) : (
                            <button
                              onClick={() => generateSectionContent(index)}
                              className="mt-2 text-blue-600 hover:text-blue-800 flex items-center"
                            >
                              <RefreshCw size={16} className="mr-2" />
                              Generate Content
                            </button>
                          )}
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              )}
            </>
          ) : (
            <div className="bg-white rounded-2xl shadow-lg p-6">
              <button onClick={() => setViewMode("planner")} className="mb-4 text-blue-600 hover:text-blue-800 flex items-center">
                <ChevronLeft size={20} className="mr-2" />
                Back to Planner
              </button>
              <h2 className="text-2xl font-bold mb-2">{selectedReport.title}</h2>
              <p className="text-sm text-gray-500 mb-4">{selectedReport.date}</p>
              <div className="mb-6">
                <h3 className="text-xl font-semibold mb-2">Original Insight:</h3>
                <p className="text-gray-700">{selectedReport.insight}</p>
              </div>
              <div>
                <h3 className="text-xl font-semibold mb-4">Generated Business Plan:</h3>
                {tableOfContents.map((section, index) => (
                  <div key={index} className="mb-4">
                    <div
                      className="flex items-center justify-between bg-gray-100 p-3 rounded-md cursor-pointer"
                      onClick={() => toggleSection(index)}
                    >
                      <span className="font-medium">{section.title}</span>
                      {expandedSections[index] ? <ChevronDown size={20} /> : <ChevronRight size={20} />}
                    </div>
                    {expandedSections[index] && (
                      <div className="ml-6 mt-2">
                        <p className="text-gray-700 whitespace-pre-line">
                          {section.content || "Content for this section is not available."}
                        </p>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>

        <div className="bg-white rounded-2xl shadow-lg p-6">
          <h2 className="text-2xl font-semibold mb-4 text-gray-800">Recent Reports</h2>
          <ul className="space-y-4">
            {reports.map((report) => (
              <li key={report.id} className="border-b border-gray-200 pb-4 last:border-b-0">
                <div className="flex items-center justify-between">
                  <div>
                    <h3 className="font-semibold text-gray-800">{report.title}</h3>
                    <p className="text-sm text-gray-500">{report.date}</p>
                    <p className="text-sm text-gray-600 mt-1 line-clamp-2">{report.insight}</p>
                  </div>
                  <button onClick={() => viewReport(report)} className="text-blue-600 hover:text-blue-800 transition-colors duration-200">
                    <FileText size={20} />
                  </button>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default DynamicBusinessPlanner;

// import React, { useState, useEffect } from "react";
// import { ChevronRight, ChevronDown, Loader2, CreditCard } from "lucide-react";
// import Modal from "react-modal";
// import { motion, AnimatePresence } from "framer-motion";

// // Import the old components you mentioned
// import DashboardHeader from "./DashboardHeader";
// import api from "./axiosConfig";
// import { Link, useLocation } from "react-router-dom";

// const CustomModal = ({ isOpen, onRequestClose, content }) => {
//   const [isCopied, setIsCopied] = useState(false);

//   useEffect(() => {
//     if (isCopied) {
//       const timer = setTimeout(() => setIsCopied(false), 2000);
//       return () => clearTimeout(timer);
//     }
//   }, [isCopied]);

//   const handleCopy = async () => {
//     try {
//       await navigator.clipboard.writeText(content);
//       setIsCopied(true);
//     } catch (err) {
//       console.error("Failed to copy text: ", err);
//     }
//   };

//   return (
//     <Modal
//       isOpen={isOpen}
//       onRequestClose={onRequestClose}
//       className="fixed inset-0 z-50 overflow-y-auto flex items-center justify-center"
//       overlayClassName="fixed inset-0 bg-black bg-opacity-50 z-40"
//       closeTimeoutMS={300}
//     >
//       <AnimatePresence>
//         {isOpen && (
//           <motion.div
//             initial={{ opacity: 0, scale: 0.95 }}
//             animate={{ opacity: 1, scale: 1 }}
//             exit={{ opacity: 0, scale: 0.95 }}
//             transition={{ duration: 0.3 }}
//             className="bg-white rounded-lg shadow-xl max-w-lg w-full mx-4"
//           >
//             <div className="p-6">
//               <h3 className="text-lg font-medium text-gray-900 mb-4">Full Input</h3>
//               <div className="mt-2 mb-4">
//                 <div className="text-sm text-gray-500 max-h-96 overflow-y-auto p-4 border rounded-md whitespace-pre-wrap bg-gray-50">
//                   {content}
//                 </div>
//               </div>
//               <div className="flex justify-end space-x-3">
//                 <motion.button
//                   whileHover={{ scale: 1.05 }}
//                   whileTap={{ scale: 0.95 }}
//                   type="button"
//                   className={`inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-sm font-medium text-white focus:outline-none
//                     ${isCopied ? "bg-green-600 hover:bg-green-700" : "bg-blue-600 hover:bg-blue-700"}
//                   `}
//                   onClick={handleCopy}
//                 >
//                   {isCopied ? "Copied!" : "Copy Full Text"}
//                 </motion.button>
//                 <motion.button
//                   whileHover={{ scale: 1.05 }}
//                   whileTap={{ scale: 0.95 }}
//                   type="button"
//                   className="inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none"
//                   onClick={onRequestClose}
//                 >
//                   Close
//                 </motion.button>
//               </div>
//             </div>
//           </motion.div>
//         )}
//       </AnimatePresence>
//     </Modal>
//   );
// };

// export default function DynamicBusinessPlanner() {
//   const location = useLocation();
//   const [businessIdea, setBusinessIdea] = useState("");
//   const [language, setLanguage] = useState("english");
//   const [isSubmitting, setIsSubmitting] = useState(false);
//   const [latestReports, setLatestReports] = useState([]);
//   const [questionCredits, setQuestionCredits] = useState(0);
//   const [message, setMessage] = useState("");
//   const [showRechargeLink, setShowRechargeLink] = useState(false);
//   const [isFetchingReports, setIsFetchingReports] = useState(false);
//   const [hasReports, setHasReports] = useState(false);
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const [selectedReport, setSelectedReport] = useState(null);

//   const openModal = (report) => {
//     setSelectedReport(report);
//     setIsModalOpen(true);
//   };

//   const closeModal = () => {
//     setIsModalOpen(false);
//     setSelectedReport(null);
//   };

//   useEffect(() => {
//     const searchParams = new URLSearchParams(location.search);
//     const ideaKey = searchParams.get("ideaKey");

//     if (ideaKey) {
//       const storedIdeaData = localStorage.getItem(ideaKey);

//       if (storedIdeaData) {
//         const { storedIdea, timestamp } = JSON.parse(storedIdeaData);
//         const parsedIdea = JSON.parse(storedIdea);

//         const formattedIdea = `
// Pain Point: ${parsedIdea.painPoint}

// Market Opportunity: ${parsedIdea.marketOpportunity}

// Competitive Landscape: ${parsedIdea.competitiveLandscape}

// Customer Segmentation: ${parsedIdea.customerSegmentation}

// Revenue Potential: ${parsedIdea.revenuePotential}

// Implementation Roadmap: ${parsedIdea.implementationRoadmap}

// Risk Assessment: ${parsedIdea.riskAssessment}

// Success Metrics: ${parsedIdea.successMetrics}

// Expert Insights: ${parsedIdea.expertInsights}
//         `.trim();

//         setBusinessIdea(formattedIdea);

//         // Remove the item from localStorage after use
//         localStorage.removeItem(ideaKey);
//       }
//     }
//   }, [location]);

//   // const handleReportTypeChange = (event) => {
//   //   setReportType(event.target.value);
//   // };

//   useEffect(() => {
//     async function fetchReports() {
//       setIsFetchingReports(true);
//       try {
//         const fullReportsResponse = await api.get("user-latest-report/");
//         if (fullReportsResponse.status === 200) {
//           setLatestReports(fullReportsResponse.data);
//           setHasReports(fullReportsResponse.data.length > 0);
//         }
//       } catch (error) {
//         console.error("Error fetching reports:", error);
//       } finally {
//         setIsFetchingReports(false);
//       }
//     }

//     fetchReports();
//   }, []);

//   const renderReportsList = (title, reports, isFetching) => (
//     <div>
//       <h3 className="font-bold text-2xl text-gray-900 mb-6 mt-10">My Previous {title}</h3>
//       <p className="text-sm text-gray-500 mb-4 italic">
//         *Opening a new report deducts report credits automatically. Select the correct report if you have multiple reports.
//       </p>
//       {isFetching ? (
//         <p className="text-lg text-gray-700">Loading...</p>
//       ) : (
//         <ul className="list-none space-y-4">
//           {reports.length > 0 ? (
//             reports.map((report) => (
//               <li key={report.report_id} className="border-b pb-4">
//                 <Link to={`/full-report/${report.report_id}`} className="text-blue-500 hover:text-blue-600 transition-colors duration-300">
//                   {report.input.substring(0, 100) + (report.input.length > 100 ? "..." : "")}
//                 </Link>
//                 {report.input.length > 100 && (
//                   <button
//                     onClick={() => openModal(report)}
//                     className="ml-4 text-green-500 hover:text-green-700 transition-colors duration-300"
//                   >
//                     View More
//                   </button>
//                 )}
//               </li>
//             ))
//           ) : (
//             <p className="text-gray-700">No previous reports found. Generate your first comprehensive analysis now!</p>
//           )}
//         </ul>
//       )}
//     </div>
//   );

//   async function handleSubmit(e) {
//     e.preventDefault();
//     setMessage("");
//     setShowRechargeLink(false);
//     setIsSubmitting(true);

//     const confirmReport = window.confirm(
//       "This will deduct one report credit. Please make sure content of your previous business ideas are fully generated."
//     );
//     if (!confirmReport) {
//       setIsSubmitting(false);
//       return;
//     }

//     const endpoint = "create-report/";

//     try {
//       const payload = {
//         business_idea_input: businessIdea,
//         language,
//       };

//       const response = await api.post(endpoint, payload);

//       if (response.status === 200 || response.status === 201) {
//         window.location.href = `/full-report/${response.data.report_id}`;
//       }
//     } catch (error) {
//       if (error.response) {
//         const errorMessage = error.response.data.message;
//         setMessage(errorMessage || "Something went wrong. Please try again later.");
//         if (errorMessage.includes("Not enough credits")) {
//           setShowRechargeLink(true);
//         }
//       } else {
//         setMessage("An unexpected error occurred.");
//       }
//       console.error("Failed to submit form:", error.response?.data);
//     } finally {
//       setIsSubmitting(false);
//     }
//   }

//   return (
//     <div className="font-inter antialiased bg-gradient-to-br from-blue-50 via-blue-100 to-green-50 text-gray-900 tracking-tight min-h-screen">
//       {/* <DashboardHeader /> */}
//       <main className="py-8 px-4 sm:px-6 lg:px-8">
//         <div className="max-w-4xl mx-auto">
//           <h1 className="text-3xl sm:text-4xl md:text-5xl font-extrabold text-blue-800 text-center mb-6 transition-all duration-300 ease-in-out transform hover:scale-105">
//             Generate Business Plan
//           </h1>
//           <p className="text-center text-gray-600 mb-8 max-w-2xl mx-auto">Get comprehensive analysis to refine your business concept.</p>

//           <div className="bg-white rounded-2xl shadow-xl overflow-hidden transition-all duration-300 ease-in-out transform hover:shadow-2xl mb-12">
//             <div className="p-6 sm:p-10">
//               {message && (
//                 <div
//                   className={`mb-6 p-4 rounded-lg ${
//                     showRechargeLink ? "bg-yellow-50 border-l-4 border-yellow-400" : "bg-red-50 border-l-4 border-red-400"
//                   }`}
//                 >
//                   <p className="text-sm font-medium text-gray-800">{message}</p>
//                   {showRechargeLink && (
//                     <Link
//                       to="/credits/"
//                       className="inline-block mt-2 text-green-600 hover:text-green-800 font-semibold transition-colors duration-200"
//                     >
//                       Recharge credits →
//                     </Link>
//                   )}
//                 </div>
//               )}

//               <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-6 space-y-4 sm:space-y-0">
//                 <h2 className="text-2xl font-bold text-green-800">Business Idea Analysis</h2>
//                 <Link
//                   to="/previewx/"
//                   className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 transition-all duration-200"
//                 >
//                   View Sample Report
//                   <svg className="ml-2 -mr-1 h-4 w-4" fill="currentColor" viewBox="0 0 20 20">
//                     <path
//                       fillRule="evenodd"
//                       d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
//                       clipRule="evenodd"
//                     />
//                   </svg>
//                 </Link>
//               </div>

//               <form onSubmit={handleSubmit} className="space-y-6">
//                 <div className="space-y-2">
//                   <label htmlFor="businessIdea" className="block text-sm font-medium text-gray-700">
//                     Describe your business idea
//                   </label>
//                   <textarea
//                     id="businessIdea"
//                     value={businessIdea}
//                     onChange={(e) => setBusinessIdea(e.target.value)}
//                     placeholder="Detail your business concept, including unique features, target market, pricing strategy, and potential challenges. The more comprehensive your description, the more tailored our analysis will be."
//                     rows="7"
//                     className="w-full p-4 text-gray-700 bg-gray-50 border border-gray-300 rounded-lg focus:ring-2 focus:ring-green-500 focus:border-transparent transition-all duration-200 ease-in-out"
//                     required
//                   />
//                 </div>

//                 <div className="space-y-2">
//                   <label htmlFor="language" className="block text-sm font-medium text-gray-700">
//                     Select Report Language
//                   </label>
//                   <div className="relative">
//                     <select
//                       id="language"
//                       value={language}
//                       onChange={(e) => setLanguage(e.target.value)}
//                       className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-sm rounded-lg transition-all duration-200 ease-in-out appearance-none bg-white"
//                     >
//                       <option value="english">English</option>
//                       <option value="spanish">Spanish</option>
//                       <option value="french">French</option>
//                       <option value="arabic">Arabic</option>
//                       <option value="russian">Russian</option>
//                       <option value="portuguese">Portuguese</option>
//                       <option value="indonesian">Indonesian</option>
//                       <option value="german">German</option>
//                       <option value="japanese">Japanese</option>
//                       <option value="swahili">Swahili</option>
//                       <option value="turkish">Turkish</option>
//                       <option value="korean">Korean</option>
//                       <option value="italian">Italian</option>
//                       <option value="vietnamese">Vietnamese</option>
//                       <option value="polish">Polish</option>
//                       <option value="ukrainian">Ukrainian</option>
//                       <option value="dutch">Dutch</option>
//                       <option value="greek">Greek</option>
//                       <option value="hebrew">Hebrew</option>
//                       <option value="thai">Thai</option>
//                       <option value="persian">Persian</option>
//                       <option value="czech">Czech</option>
//                       <option value="swedish">Swedish</option>
//                       <option value="romanian">Romanian</option>
//                       <option value="hungarian">Hungarian</option>
//                       <option value="danish">Danish</option>
//                       <option value="finnish">Finnish</option>
//                       <option value="slovak">Slovak</option>
//                       <option value="norwegian">Norwegian</option>
//                       <option value="serbian">Serbian</option>
//                       <option value="bulgarian">Bulgarian</option>
//                       <option value="mandarin">Mandarin</option>
//                     </select>
//                     <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
//                       <svg className="h-4 w-4" fill="currentColor" viewBox="0 0 20 20">
//                         <path
//                           fillRule="evenodd"
//                           d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
//                           clipRule="evenodd"
//                         />
//                       </svg>
//                     </div>
//                   </div>
//                 </div>

//                 <button
//                   type="submit"
//                   disabled={isSubmitting}
//                   className={`w-full bg-gradient-to-r from-blue-500 to-blue-600 text-white text-lg font-semibold py-3 rounded-lg
//                     ${isSubmitting ? "opacity-75 cursor-not-allowed" : "hover:from-blue-600 hover:to-blue-700"}
//                     focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition-all duration-300 ease-in-out transform hover:-translate-y-1`}
//                 >
//                   {isSubmitting ? (
//                     <span className="flex items-center justify-center">
//                       <svg
//                         className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
//                         xmlns="http://www.w3.org/2000/svg"
//                         fill="none"
//                         viewBox="0 0 24 24"
//                       >
//                         <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
//                         <path
//                           className="opacity-75"
//                           fill="currentColor"
//                           d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
//                         ></path>
//                       </svg>
//                       Generating Your Report...
//                     </span>
//                   ) : (
//                     "Generate My Business Report!"
//                   )}
//                 </button>
//               </form>
//             </div>
//           </div>
//           {renderReportsList("Reports", latestReports, isFetchingReports)}
//         </div>
//       </main>

//       <CustomModal isOpen={isModalOpen} onRequestClose={closeModal} content={selectedReport?.input} />
//     </div>
//   );
// }
