import React, { useState, useEffect } from "react";
import { FaBell } from "react-icons/fa";
import axios from "./axiosConfig";
import MobileMenu from "../components/Mobile-menu";
import { NavLink } from "react-router-dom";
import LogoutButton from "./LogoutButton";
import Logo from "../images/thumbnail.png";

export default function DashboardHeader() {
  const [user, setUser] = useState(null);
  const [top, setTop] = useState(true);
  const [notifications, setNotifications] = useState([]); // Ensure notifications is initialized as an array
  const [showNotifications, setShowNotifications] = useState(false);
  const [notificationWs, setNotificationWs] = useState(null);

  const getBaseUrl = () => {
    if (process.env.REACT_APP_ENV === "production") {
      return "https://beproduction.needfinderai.com";
    }
    return "http://127.0.0.1:8000";
  };

  useEffect(() => {
    const token = localStorage.getItem("access");

    if (!token) {
      window.location.href = "/signin/";
    } else {
      try {
        const decodedToken = JSON.parse(atob(token.split(".")[1]));
        const currentTimestamp = Math.floor(Date.now() / 1000);

        if (decodedToken.exp && decodedToken.exp < currentTimestamp) {
          localStorage.removeItem("access");
          window.location.href = "/signin/";
        } else {
          setUser(true);
          fetchUserDetails();
        }
      } catch (error) {
        console.error("Failed to decode the token:", error);
        window.location.href = "/signin/";
      }
    }
  }, []);

  const fetchUserDetails = async () => {
    try {
      const response = await axios.get("/get-user-email/");
      connectToNotificationWebSocket(response.data.id);
    } catch (error) {
      console.error("Error fetching user details:", error);
    }
  };

  const connectToNotificationWebSocket = (userId) => {
    if (notificationWs) {
      notificationWs.close();
    }

    const token = localStorage.getItem("access");
    const baseUrl = getBaseUrl(); // Get the base URL based on the environment
    const newWs = new WebSocket(
      `${baseUrl}/ws/notifications/${userId}/?token=${token}`
    );
    // const newWs = new WebSocket(
    //   `ws://127.0.0.1:8000/ws/notifications/${userId}/?token=${token}`
    // );

    newWs.onmessage = (event) => {
      const data = JSON.parse(event.data);
      if (data.type === "notification") {
        setNotifications((prevNotifications) => {
          const isDuplicate = prevNotifications.some(
            (notif) => notif.timestamp === data.timestamp
          );
          return isDuplicate ? prevNotifications : [...prevNotifications, data];
        });
      }
    };

    newWs.onclose = () => {
      setTimeout(() => connectToNotificationWebSocket(userId), 1000); // Retry on close
    };

    setNotificationWs(newWs);
  };

  const handleNotificationClick = () => {
    setShowNotifications(!showNotifications);

    if (!showNotifications) {
      setNotifications((prevNotifications) =>
        prevNotifications.map((notif) => ({ ...notif, read: true }))
      );
    }
  };

  const renderLink = (to, text) => (
    <NavLink
      to={to}
      className={({ isActive }) =>
        `bg-green-200 hover:bg-green-300 text-green-800 hover:text-green-900 px-4 py-2 rounded-full flex items-center transition duration-150 ease-in-out shadow ${
          isActive ? "ring ring-green-500 ring-opacity-50" : ""
        }`
      }
    >
      {text}
    </NavLink>
  );

  return (
    <header
      className={`top w-full z-30 md:bg-opacity-90 transition duration-300 ease-in-out ${
        !top ? "bg-white backdrop-blur-sm shadow-lg" : ""
      }`}
    >
      <div className="max-w-7xl mx-auto px-5 sm:px-6">
        <div className="flex items-center justify-between h-16 md:h-20">
          <div className="shrink-0 mr-4">
            <NavLink
              to="/dashboard/"
              className="flex items-center text-2xl font-bold text-gray-800"
            >
              <img src={Logo} alt="Logo" className="h-10 mr-2" />
              <span className="font-bold text-green-600 h-8">NeedfinderAI</span>
            </NavLink>
          </div>

          <nav className="hidden md:flex md:grow">
            <ul className="flex grow justify-end flex-wrap items-center">
              <li className="mx-2">
                {renderLink("/ai-mention/", "AI Mentions")}
              </li>
              <li className="mx-2">
                {renderLink("/reports/", "100+ Page Business Plan")}
              </li>
              <li className="mx-2">
                {renderLink("/credits/", "Credits & Recharge")}
              </li>
              <li className="mx-2">
                <a
                  href="mailto:business@needfinderai.com"
                  className="bg-green-200 hover:bg-green-300 text-green-800 hover:text-green-900 px-4 py-2 rounded-full flex items-center transition duration-150 ease-in-out shadow"
                >
                  Contact Us
                </a>
              </li>
              <li className="mx-2 relative">
                <button onClick={handleNotificationClick} className="relative">
                  <FaBell
                    className="text-green-700 hover:text-gray-900"
                    size={18}
                  />
                  {notifications.length > 0 &&
                    notifications.some((notif) => !notif.read) && (
                      <span className="absolute top-0 right-0 w-2 h-2 bg-red-600 rounded-full"></span>
                    )}
                </button>
                {showNotifications && (
                  <div className="absolute right-0 mt-2 w-64 bg-white shadow-lg rounded-lg overflow-hidden z-10">
                    <ul>
                      {notifications.length === 0 ? (
                        <li className="p-4 text-gray-600">
                          No new notifications
                        </li>
                      ) : (
                        notifications.map((notif, index) => (
                          <li
                            key={index}
                            className={`p-4 text-gray-600 ${
                              notif.read ? "bg-gray-200" : "bg-gray-100"
                            }`}
                          >
                            {notif.content.message} -{" "}
                            <a href={notif.content.link}>View</a>
                          </li>
                        ))
                      )}
                    </ul>
                  </div>
                )}
              </li>
            </ul>

            <ul className="flex grow justify-end flex-wrap items-center">
              <li>
                <a
                  href="/signin/"
                  className="btn-sm text-gray-200 bg-emerald-600 hover:bg-emerald-700 ml-3"
                >
                  <span>
                    {user && (
                      <div className="text-right pr-4">
                        <LogoutButton setUser={setUser} />
                      </div>
                    )}
                  </span>
                </a>
              </li>
            </ul>
          </nav>

          <MobileMenu
            user={user}
            setUser={setUser}
            notifications={notifications}
            handleNotificationClick={handleNotificationClick}
          />
        </div>
      </div>
    </header>
  );
}
