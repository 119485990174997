import React, { useState, useEffect, useRef } from "react";
import {
  FaQuoteLeft,
  FaEdit,
  FaCalendar,
  FaChartLine,
  FaUnlock,
  FaBookmark,
  FaFileAlt,
  FaSync,
  FaPlus,
  FaLockOpen,
  FaClipboard,
  FaExternalLinkAlt,
} from "react-icons/fa";
import Modal from "react-modal";
import DashboardHeader from "./DashboardHeader";
import axios from "./axiosConfig"; // Import axios instance
import moment from "moment";

const AIMention = () => {
  // State management
  const [expandedCards, setExpandedCards] = useState([]);
  const [promotionPosts, setPromotionPosts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [products, setProducts] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const [modalData, setModalData] = useState(null);
  const [lastUpdated, setLastUpdated] = useState(null);
  const [loading, setLoading] = useState(true);
  const [loadingStage, setLoadingStage] = useState(0);
  const [isProfileUpdating, setIsProfileUpdating] = useState(false);
  const [productName, setProductName] = useState("");
  const [productDescription, setProductDescription] = useState("");
  const [productUrl, setProductUrl] = useState("");
  const [lastRefresh, setLastRefresh] = useState(null);
  const [copiedIndex, setCopiedIndex] = useState(null);
  const [copied, setCopied] = useState(false);
  const intervals = [10000, 10000, 10000, 10000, 10000]; // 10 seconds intervals
  const [isFirstTimeUser, setIsFirstTimeUser] = useState(false);
  const [userData, setUserData] = useState(null);

  const loadingMessages = [
    "Searching for relevant posts...",
    "Found relevant posts...",
    "Sending them for analysis...",
    "Processing the analysis...",
    "Polishing final results...",
    "Preparing for final display...",
  ];
  const handleCopyComment = (comment, index) => {
    navigator.clipboard.writeText(comment);
    setCopiedIndex(index);
    setTimeout(() => setCopiedIndex(null), 2000); // Reset after 2 seconds
  };

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get("/get-user-email/");
        setUserData(response.data);
        // Check for first-time access for product marketing
        setIsFirstTimeUser(
          !response.data.accessed_product_marketing_free_preview
        );
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchUserData();
  }, []);

  useEffect(() => {
    if (loading) {
      const intervalId = setInterval(() => {
        setLoadingStage((prevStage) => {
          if (prevStage < loadingMessages.length - 1) {
            return prevStage + 1;
          } else {
            clearInterval(intervalId);
            return prevStage;
          }
        });
      }, 10000); // Update every 10 seconds

      return () => clearInterval(intervalId);
    }
  }, [loading]);

  const fetchUserProducts = async () => {
    try {
      const response = await axios.get("/loggedin-user-products/");
      if (response.data.length === 0) {
        setModalContent("Add New Product");
        setModalIsOpen(true);
      } else {
        setProducts(response.data);
        setSelectedProduct(response.data[0].id);
      }
    } catch (error) {
      console.error("Error fetching user products:", error);
    } finally {
      setLoading(false); // Ensure loading is set to false
    }
  };

  useEffect(() => {
    fetchUserProducts();
  }, []);

  // const handleRefresh = async () => {
  //   setLoading(true);
  //   setLoadingStage(0);

  //   try {
  //     await axios.post(`/fetch-posts/${selectedProduct}/`);
  //     await new Promise((resolve) => setTimeout(resolve, 1000)); // Simulate waiting time
  //     await axios.post(`/fetch-analysis/${selectedProduct}/`);
  //     fetchPromotionPosts(selectedProduct);
  //   } catch (error) {
  //     console.error("Error fetching posts and analysis:", error);
  //   } finally {
  //     setLoading(false); // Ensure loading is set to false
  //   }
  // };

  const handleRefresh = async () => {
    setLoading(true);
    setLoadingStage(0);

    try {
      if (isFirstTimeUser) {
        console.log("First-time access granted for product marketing");
        // Update state to reflect that the user has used their first-time access
        setIsFirstTimeUser(false);
      } else {
        // Check credit balance and decrement credits
        if (userData && userData.plan_details.product_marketing_credits > 0) {
          await axios.post("/update-usage/", { feature: "product_marketing" });
          // Decrement credit count in the frontend state
          setUserData((prevData) => ({
            ...prevData,
            plan_details: {
              ...prevData.plan_details,
              product_marketing_credits:
                prevData.plan_details.product_marketing_credits - 1,
            },
          }));
        } else {
          throw new Error("Insufficient credits");
        }
      }

      await axios.post(`/fetch-posts/${selectedProduct}/`);
      await new Promise((resolve) => setTimeout(resolve, 1000)); // Simulate waiting time
      await axios.post(`/fetch-analysis/${selectedProduct}/`);
      fetchPromotionPosts(selectedProduct);
    } catch (error) {
      console.error("Error fetching posts and analysis:", error);
      if (error.message === "Insufficient credits") {
        alert(
          "Insufficient product marketing credits. Please upgrade your plan."
        );
      }
    } finally {
      setLoading(false); // Ensure loading is set to false
    }
  };

  const handleAddProduct = async () => {
    setIsProfileUpdating(true); // Set loading state
    try {
      const response = await axios.post("/create-product/", {
        name: productName,
        description: productDescription,
        product_url: productUrl,
      });
      if (response.status === 201) {
        setModalIsOpen(false);
        // Fetch user products again
        fetchUserProducts();
      }
    } catch (error) {
      console.error("Error adding product:", error);
    } finally {
      setIsProfileUpdating(false); // Unset loading state
    }
  };

  const fetchPromotionPosts = async (productId) => {
    try {
      const response = await axios.get(
        `/fetch-user-promotion-posts/${productId}/`
      );
      setPromotionPosts(response.data);
      setLastRefresh(new Date()); // Set the last refresh date
    } catch (error) {
      console.error("Error fetching promotion posts:", error);
    }
  };

  const renderLastRefreshTime = () => {
    if (!lastRefresh) return null;
    const now = moment();
    const duration = moment.duration(now.diff(moment(lastRefresh)));

    if (duration.asSeconds() < 60) {
      return `${Math.floor(duration.asSeconds())} seconds ago`;
    } else if (duration.asMinutes() < 60) {
      return `${Math.floor(duration.asMinutes())} minutes ago`;
    } else if (duration.asHours() < 24) {
      return `${Math.floor(duration.asHours())} hours ago`;
    } else if (duration.asDays() < 7) {
      return `${Math.floor(duration.asDays())} days ago`;
    } else {
      return moment(lastRefresh).format("MMMM Do YYYY, h:mm:ss a");
    }
  };

  useEffect(() => {
    const fetchUserProducts = async () => {
      try {
        const response = await axios.get("/loggedin-user-products/");
        if (response.data.length === 0) {
          setModalContent("Add New Product");
          setModalIsOpen(true);
        } else {
          setProducts(response.data);
          setSelectedProduct(response.data[0].id);
        }
      } catch (error) {
        console.error("Error fetching user products:", error);
      }
    };

    fetchUserProducts();
  }, []);

  useEffect(() => {
    const fetchPromotionPosts = async (productId) => {
      try {
        const response = await axios.get(
          `/fetch-user-promotion-posts/${productId}/`
        );
        setPromotionPosts(response.data);
      } catch (error) {
        console.error("Error fetching promotion posts:", error);
      }
    };

    if (selectedProduct) {
      fetchPromotionPosts(selectedProduct);
    }
  }, [selectedProduct]);

  useEffect(() => {
    const unlockPostsInBackground = async () => {
      const postsToUnlock = expandedCards.filter(
        (index) => !promotionPosts[index].open_insight
      );

      await Promise.all(
        postsToUnlock.map(async (index) => {
          const postId = promotionPosts[index].id;
          try {
            await axios.post(`/showposturlandcomment/${postId}/`);
            setPromotionPosts((prevPosts) =>
              prevPosts.map((post, i) =>
                i === index ? { ...post, open_insight: true } : post
              )
            );
          } catch (error) {
            console.error("Error unlocking post:", error);
          }
        })
      );
    };

    unlockPostsInBackground();
  }, [expandedCards, promotionPosts]);

  const renderLastFetchedTime = () => {
    if (!lastUpdated) return null;
    const now = moment();
    const duration = moment.duration(now.diff(moment(lastUpdated)));

    if (duration.asSeconds() < 60) {
      return `${Math.floor(duration.asSeconds())} seconds ago`;
    } else if (duration.asMinutes() < 60) {
      return `${Math.floor(duration.asMinutes())} minutes ago`;
    } else if (duration.asHours() < 24) {
      return `${Math.floor(duration.asHours())} hours ago`;
    } else if (duration.asDays() < 7) {
      return `${Math.floor(duration.asDays())} days ago`;
    } else {
      return moment(lastUpdated).format("MMMM Do YYYY, h:mm:ss a");
    }
  };

  const toggleUnlock = (index, event) => {
    event.preventDefault(); // Prevent the page from jumping to the top
    setExpandedCards((prev) =>
      prev.includes(index) ? prev.filter((i) => i !== index) : [...prev, index]
    );
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setModalContent(null);
    setModalData(null);
  };

  const openModal = (content, data) => {
    setModalContent(content);
    setModalData(data);
    setModalIsOpen(true);
  };
  const handleUpdateProduct = async () => {
    setIsProfileUpdating(true); // Set loading state
    try {
      const response = await axios.put(
        `/update-product/${modalData.productId}/`,
        {
          name: productName,
          description: productDescription,
          product_url: productUrl,
        }
      );
      if (response.status === 200) {
        setModalIsOpen(false);
        // Fetch user products again
        fetchUserProducts();
      }
    } catch (error) {
      console.error("Error updating product:", error);
    } finally {
      setIsProfileUpdating(false); // Unset loading state
    }
  };

  // const renderModalContent = () => {
  //   if (isProfileUpdating) {
  //     return (
  //       <div className="p-8 bg-white rounded-lg shadow-lg text-center">
  //         <div className="animate-spin rounded-full h-16 w-16 border-b-2 border-green-500 mx-auto mb-4"></div>
  //         <h2 className="text-2xl font-bold text-gray-800 mb-2">
  //           Please wait...
  //         </h2>
  //         <p className="text-gray-600">Updating your product details</p>
  //       </div>
  //     );
  //   }

  //   if (modalContent === "Add New Product") {
  //     return (
  //       <div className="p-8 bg-white rounded-lg shadow-lg">
  //         <h2 className="text-3xl font-bold text-green-700 mb-6 text-center">
  //           Add New Product
  //         </h2>
  //         <div className="space-y-4">
  //           <div>
  //             <label
  //               htmlFor="productName"
  //               className="block text-sm font-medium text-gray-700 mb-1"
  //             >
  //               Product Name
  //             </label>
  //             <input
  //               id="productName"
  //               type="text"
  //               placeholder="Enter product name"
  //               value={productName}
  //               onChange={(e) => setProductName(e.target.value)}
  //               className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-green-500 focus:border-transparent transition duration-200"
  //             />
  //           </div>
  //           <div>
  //             <label
  //               htmlFor="productDescription"
  //               className="block text-sm font-medium text-gray-700 mb-1"
  //             >
  //               Product Description
  //             </label>
  //             <textarea
  //               id="productDescription"
  //               placeholder="Describe your product"
  //               value={productDescription}
  //               onChange={(e) => setProductDescription(e.target.value)}
  //               rows="4"
  //               className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-green-500 focus:border-transparent transition duration-200"
  //             ></textarea>
  //           </div>
  //           <div>
  //             <label
  //               htmlFor="productUrl"
  //               className="block text-sm font-medium text-gray-700 mb-1"
  //             >
  //               Product URL
  //             </label>
  //             <input
  //               id="productUrl"
  //               type="text"
  //               placeholder="https://example.com/your-product"
  //               value={productUrl}
  //               onChange={(e) => setProductUrl(e.target.value)}
  //               className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-green-500 focus:border-transparent transition duration-200"
  //             />
  //           </div>
  //         </div>
  //         <button
  //           className="w-full mt-6 bg-gradient-to-r from-green-500 to-green-600 text-white py-3 rounded-lg font-semibold shadow-md hover:shadow-lg transition-all duration-300 transform hover:scale-105"
  //           onClick={handleAddProduct}
  //         >
  //           Save and Continue
  //         </button>
  //       </div>
  //     );
  //   }

  //   return null;
  // };

  // Add this inside the renderExpandedCard function before the return statement
  // const renderExpandedCard = (post, index) => {
  //   const isExpanded = expandedCards.includes(index);
  //   return (
  //     <div className="bg-white text-gray-900 shadow-2xl rounded-3xl p-4 border border-gray-200 transition-all duration-300 hover:shadow-3xl hover:border-green-300 mb-8">
  //       <div className="flex items-start mb-6">
  //         <div className="flex-shrink-0 bg-gradient-to-r from-green-500 to-green-700 text-white rounded-full p-4 shadow-lg">
  //           <FaQuoteLeft className="text-2xl" />
  //         </div>
  //         <div className="ml-6 flex-grow">
  //           <h2 className="text-2xl font-bold text-gray-800 leading-tight mb-3">
  //             {post.details?.post_title || "Title not available"}
  //           </h2>
  //           <div className="flex items-center mb-4">
  //             <FaChartLine className="text-green-600 mr-2 text-xl" />
  //             <span className="text-xl font-semibold text-green-600">
  //               Relevance: {post.details?.relevance_percent ?? "N/A"}%
  //             </span>

  //             <a
  //               href={post.details?.post_url || "#"}
  //               target="_blank"
  //               rel="noopener noreferrer"
  //               className="flex ml-5 items-center text-blue-600 hover:text-blue-800 underline text-lg font-medium"
  //             >
  //               <FaExternalLinkAlt className="mr-2" />
  //               Visit Post
  //             </a>
  //           </div>
  //         </div>
  //       </div>

  //       <div className="bg-gray-50 rounded-xl p-6 shadow-md mb-6">
  //         <h3 className="text-xl font-semibold text-gray-800 mb-3">
  //           💬 AI-Generated Comment:
  //         </h3>
  //         <p className="text-gray-700 text-lg mb-4">
  //           {post.details?.comment || "Comment not available"}
  //         </p>
  //         <button
  //           onClick={handleCopyComment}
  //           className={`flex items-center justify-center w-full bg-gradient-to-r from-green-500 to-green-600 text-white px-6 py-3 rounded-lg transition-all duration-300 hover:shadow-lg focus:outline-none text-lg font-semibold ${
  //             copied ? "bg-green-700" : ""
  //           }`}
  //         >
  //           <FaClipboard className="mr-3 text-xl" />
  //           {copied ? "Copied!" : "Copy Comment"}
  //         </button>
  //       </div>
  //     </div>
  //   );
  // };

  const renderModalContent = () => {
    if (isProfileUpdating) {
      return (
        <div className="p-8 bg-white rounded-lg shadow-lg text-center">
          <div className="animate-spin rounded-full h-16 w-16 border-b-2 border-green-500 mx-auto mb-4"></div>
          <h2 className="text-2xl font-bold text-gray-800 mb-2">
            Please wait...
          </h2>
          <p className="text-gray-600">Updating your product details</p>
        </div>
      );
    }

    if (modalContent === "Update Product" && modalData) {
      return (
        <div className="p-8 bg-white rounded-lg shadow-lg">
          <h2 className="text-3xl font-bold text-green-700 mb-6 text-center">
            Update Product
          </h2>
          <div className="space-y-4">
            <div>
              <label
                htmlFor="productName"
                className="block text-sm font-medium text-gray-700 mb-1"
              >
                Product Name
              </label>
              <input
                id="productName"
                type="text"
                placeholder="Enter product name"
                value={productName}
                onChange={(e) => setProductName(e.target.value)}
                className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-green-500 focus:border-transparent transition duration-200"
              />
            </div>
            <div>
              <label
                htmlFor="productDescription"
                className="block text-sm font-medium text-gray-700 mb-1"
              >
                Product Description
              </label>
              <textarea
                id="productDescription"
                placeholder="Describe your product"
                value={productDescription}
                onChange={(e) => setProductDescription(e.target.value)}
                rows="4"
                className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-green-500 focus:border-transparent transition duration-200"
              ></textarea>
            </div>
            <div>
              <label
                htmlFor="productUrl"
                className="block text-sm font-medium text-gray-700 mb-1"
              >
                Product URL
              </label>
              <input
                id="productUrl"
                type="text"
                placeholder="https://example.com/your-product"
                value={productUrl}
                onChange={(e) => setProductUrl(e.target.value)}
                className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-green-500 focus:border-transparent transition duration-200"
              />
            </div>
          </div>
          <button
            className="w-full mt-6 bg-gradient-to-r from-green-500 to-green-600 text-white py-3 rounded-lg font-semibold shadow-md hover:shadow-lg transition-all duration-300 transform hover:scale-105"
            onClick={handleUpdateProduct}
          >
            Save and Continue
          </button>
        </div>
      );
    }

    if (modalContent === "Add New Product") {
      return (
        <div className="p-8 bg-white rounded-lg shadow-lg">
          <h2 className="text-3xl font-bold text-green-700 mb-6 text-center">
            Add New Product
          </h2>
          <div className="space-y-4">
            <div>
              <label
                htmlFor="productName"
                className="block text-sm font-medium text-gray-700 mb-1"
              >
                Product Name
              </label>
              <input
                id="productName"
                type="text"
                placeholder="Enter product name"
                value={productName}
                onChange={(e) => setProductName(e.target.value)}
                className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-green-500 focus:border-transparent transition duration-200"
              />
            </div>
            <div>
              <label
                htmlFor="productDescription"
                className="block text-sm font-medium text-gray-700 mb-1"
              >
                Product Description
              </label>
              <textarea
                id="productDescription"
                placeholder="Describe your product"
                value={productDescription}
                onChange={(e) => setProductDescription(e.target.value)}
                rows="4"
                className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-green-500 focus:border-transparent transition duration-200"
              ></textarea>
            </div>
            <div>
              <label
                htmlFor="productUrl"
                className="block text-sm font-medium text-gray-700 mb-1"
              >
                Product URL
              </label>
              <input
                id="productUrl"
                type="text"
                placeholder="https://example.com/your-product"
                value={productUrl}
                onChange={(e) => setProductUrl(e.target.value)}
                className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-green-500 focus:border-transparent transition duration-200"
              />
            </div>
          </div>
          <button
            className="w-full mt-6 bg-gradient-to-r from-green-500 to-green-600 text-white py-3 rounded-lg font-semibold shadow-md hover:shadow-lg transition-all duration-300 transform hover:scale-105"
            onClick={handleAddProduct}
          >
            Save and Continue
          </button>
        </div>
      );
    }

    return null;
  };

  const renderExpandedCard = (post, index) => {
    return (
      <div className="bg-white rounded-lg shadow-xl overflow-hidden mb-8 transition-all duration-300 hover:shadow-2xl">
        <div className="bg-gradient-to-r from-green-500 to-green-600 p-6 text-white">
          <div className="flex items-start">
            <div className="flex-shrink-0 bg-white rounded-full p-3 shadow-md">
              <FaQuoteLeft className="text-green-500 text-2xl" />
            </div>
            <div className="ml-4 flex-grow">
              <h2 className="text-2xl font-bold leading-tight mb-2">
                {post.details?.post_title || "Title not available"}
              </h2>
              <div className="flex items-center">
                <FaChartLine className="mr-2 text-xl" />
                <span className="text-xl font-semibold">
                  Relevance: {post.details?.engagement_quality_score ?? "N/A"}%
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="p-6">
          <h3 className="text-xl font-semibold text-gray-800 mb-3">
            💬 AI-Generated Comment:
          </h3>
          <p className="text-gray-700 text-lg mb-4 bg-gray-50 p-4 rounded-lg">
            {post.details?.comment || "Comment not available"}
          </p>
          <div className="flex justify-between items-center">
            <button
              onClick={() => handleCopyComment(post.details?.comment, index)}
              className={`flex items-center justify-center px-6 py-3 rounded-lg transition-all duration-300 focus:outline-none text-lg font-semibold ${
                copiedIndex === index
                  ? "bg-green-600 text-white"
                  : "bg-green-100 text-green-700 hover:bg-green-200"
              }`}
            >
              <FaClipboard className="mr-2" />
              {copiedIndex === index ? "Copied!" : "Copy Comment"}
            </button>
            <a
              href={post.details?.post_url || "#"}
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center text-blue-600 hover:text-blue-800 font-medium"
            >
              <FaExternalLinkAlt className="mr-2" />
              Visit Post
            </a>
          </div>
        </div>
      </div>
    );
  };
  return (
    <div className="flex flex-col min-h-screen bg-gray-50">
      <DashboardHeader />
      <div className="flex flex-1 flex-wrap">
        {/* Side Bar */}
        <div className="w-full md:w-1/4 h-screen bg-white shadow-2xl p-8 overflow-y-auto">
          <h2 className="text-3xl font-bold text-green-700 mb-8">
            Your Product
          </h2>
          <ul className="space-y-6">
            {products.map((product) => (
              <li
                key={product.id}
                className="bg-gray-50 rounded-lg p-4 shadow-md hover:shadow-lg transition-shadow duration-300"
              >
                <div className="flex justify-between items-center">
                  <h3 className="text-xl font-bold text-gray-900">
                    {product.name}
                  </h3>
                  <button
                    className="text-green-500 hover:text-green-700 transition-colors duration-300"
                    onClick={() =>
                      openModal("Update Product", { productId: product.id })
                    }
                  >
                    <FaEdit size={20} />
                  </button>
                </div>
                <p className="text-gray-700 mt-2">{product.description}</p>
              </li>
            ))}
          </ul>
          {/* <button
            className="w-full mt-8 bg-gradient-to-r from-green-500 to-green-600 text-white py-3 rounded-lg flex items-center justify-center shadow-md hover:shadow-lg transform hover:scale-105 transition-all duration-300"
            onClick={() => openModal("Add New Product")}
          >
            <FaPlus className="mr-2" /> Add New Product
          </button> */}
        </div>

        <div className="flex-1 p-8 w-full md:w-3/4 mx-auto">
          <div className="max-w-3xl w-full mx-auto space-y-8">
            <div className="text-center mb-8">
              <h1 className="text-3xl font-bold text-green-700 mb-4">
                Find most relevant conversations happening online to mention
                your product✨
              </h1>
            </div>
            <button
              className="flex items-center justify-center bg-gradient-to-r from-green-500 to-green-600 text-white px-6 py-3 rounded-lg shadow-md hover:shadow-lg transition-all duration-300 transform hover:scale-105 focus:outline-none mx-auto"
              onClick={handleRefresh}
            >
              <FaSync className="mr-2" /> Refresh AI Mentions
              {lastRefresh && (
                <span className="ml-4 text-sm">
                  Last refreshed {renderLastRefreshTime()}
                </span>
              )}
            </button>
            {loading && (
              <div className="mt-4 text-center text-lg text-gray-700 bg-white p-4 rounded-lg shadow-md">
                {loadingMessages[loadingStage]}
              </div>
            )}
            {promotionPosts.map((post, index) =>
              renderExpandedCard(post, index)
            )}
          </div>
        </div>
      </div>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        shouldCloseOnOverlayClick={true}
        className="fixed inset-0 flex items-center justify-center p-4 z-50"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50 z-40"
      >
        <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-2xl relative">
          <button
            className="absolute top-4 right-4 text-gray-500"
            onClick={closeModal}
          >
            ✖
          </button>
          {renderModalContent()}
        </div>
      </Modal>
    </div>
  );
};

export default AIMention;
