import React, { useState, useEffect, useCallback, useRef } from "react";
import {
  Search,
  RefreshCw,
  Loader2,
  Bookmark,
  ThumbsUp,
  Zap,
  Users,
  TrendingUp,
  ChevronUp,
  ChevronDown,
  Send,
  Edit3,
  ChevronRight,
  AlertCircle,
  X,
  Pencil,
  RefreshCcw,
  Bell,
  Sparkles,
  Info,
  DollarSign,
  Lock,
  Lightbulb,
  ArrowRight,
  BarChart,
  Download,
  FileText,
  Minimize2,
  ChevronLeft,
  Maximize2,
  Menu,
  BookOpen,
  Sun,
  Open,
  Moon,
  Share2,
  Printer,
  Briefcase,
  Clock,
  NetworkIcon,
  Network,
  MessageSquare,
  Coffee,
  Target,
  Brain,
} from "lucide-react";
import axios from "./axiosConfig";
import { motion, AnimatePresence } from "framer-motion";
import { Dialog, Transition } from "@headlessui/react";
import DOMPurify from "dompurify";
import { formatDistanceToNow } from "date-fns";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import mermaid from "mermaid";

function removeCodeBlockMarkdown(content) {
  return content ? content.replace(/```markdown\n|```/g, "") : "";
}

// function sanitizeContent(content) {
//   return content
//     .replace(/\\n/g, "\n")
//     .replace(/\\t/g, "\t")
//     .replace(/['"]+/g, "")
//     .replace(/[{}]/g, "")
//     .replace(/answer:\s*/g, "")
//     .replace(/^##\s*/, "")
//     .trim();
// }

const FeatureIcon = ({ Icon, text }) => (
  <div className="flex items-center space-x-2">
    <Icon className="w-5 h-5" />
    <span className="text-sm">{text}</span>
  </div>
);

const FeatureItem = ({ icon: Icon, text }) => (
  <div className="flex items-center mb-3">
    <Icon className="text-green-500 mr-2" size={20} />
    <span>{text}</span>
  </div>
);

function sanitizeContent(content) {
  let parsedContent;
  try {
    parsedContent = JSON.parse(content);
    // If parsing succeeds, extract the relevant content
    content = parsedContent.content || parsedContent;
  } catch (error) {
    // If parsing fails, use the original content
  }

  return content
    .replace(/\\n/g, "\n")
    .replace(/\\t/g, "\t")
    .replace(/['"]+/g, "")
    .replace(/[{}]/g, "")
    .replace(/answer:\s*/g, "")
    .replace(/^##\s*/, "")
    .trim();
}

const SECTION_ORDER = {
  ExecutiveSummary: 1,
  MarketDeepDive: 2,
  UnmetNeed: 3,
  CompetitiveIntelligence: 4,
  CustomerSegmentation: 5,
  SolutionBlueprint: 6,
  MarketForecast: 7,
  GoToMarket: 8,
  SuccessStories: 9,
  Conclusion: 10,
};

const CustomMarkdownRenderer = ({ content }) => {
  useEffect(() => {
    mermaid.contentLoaded();
  }, [content]);

  const components = {
    code({ node, inline, className, children, ...props }) {
      const match = /language-(\w+)/.exec(className || "");
      if (match && match[1] === "mermaid") {
        return <div className="mermaid">{String(children).replace(/\n$/, "")}</div>;
      }
      return (
        <pre className={className} {...props}>
          <code>{children}</code>
        </pre>
      );
    },
    li({ node, children, ...props }) {
      if (node.checked !== undefined) {
        return (
          <li {...props} className="flex items-center">
            <input type="checkbox" checked={node.checked} readOnly className="mr-2" />
            {children}
          </li>
        );
      }
      return <li {...props}>{children}</li>;
    },
  };

  return (
    <ReactMarkdown className="prose" remarkPlugins={[remarkGfm]} components={components}>
      {content}
    </ReactMarkdown>
  );
};

const LoadingExperience = ({ insightTitle }) => {
  const [progress, setProgress] = useState(0);
  const [currentTip, setCurrentTip] = useState(0);

  const tips = [
    "Did you know? Our AI analyzes over 1 million data points to generate each report.",
    "Pro tip: Save interesting insights to your library for quick access later.",
    "Fun fact: The average NeedFinderAI user discovers 5 new business opportunities per month.",
  ];

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        const newProgress = oldProgress + 1;
        if (newProgress === 100) {
          clearInterval(timer);
        }
        return newProgress;
      });
    }, 1800); // 180 seconds (3 minutes) / 100 steps = 1800ms per step

    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    const tipTimer = setInterval(() => {
      setCurrentTip((oldTip) => (oldTip + 1) % tips.length);
    }, 8000);

    return () => clearInterval(tipTimer);
  }, []);

  return (
    <div className="fixed inset-0 bg-white z-50 flex flex-col items-center justify-center p-4">
      <BookOpen className="text-blue-600 mb-4" size={48} />
      <h2 className="text-2xl font-bold text-gray-800 mb-2">Generating Your Full Report</h2>
      <p className="text-lg text-gray-600 mb-6">"{insightTitle}"</p>

      <div className="w-full max-w-md bg-gray-200 rounded-full h-2.5 mb-4">
        <div className="bg-blue-600 h-2.5 rounded-full" style={{ width: `${progress}%` }}></div>
      </div>

      <div className="flex items-center text-gray-600 mb-8">
        <Clock size={20} className="mr-2" />
        <span>Estimated time: 2-3 minutes</span>
      </div>

      <div className="mt-8">
        <Loader2 className="animate-spin text-blue-600 mb-2 mx-auto" size={24} />
        <p className="text-gray-600">Analyzing market trends and opportunities...</p>
      </div>
    </div>
  );
};

const FullReportSidebar = ({ isOpen, closeReport, insightId, refreshCredits, setShowUpgradeModal, insightreportCredits }) => {
  const [activeSection, setActiveSection] = useState("");
  const [loadedSections, setLoadedSections] = useState({});
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [reportId, setReportId] = useState(null);
  const [fontSize, setFontSize] = useState("text-lg");
  const [isLoading, setIsLoading] = useState(true);
  const [insightTitle, setInsightTitle] = useState("");

  useEffect(() => {
    mermaid.initialize({
      startOnLoad: true,
      theme: "default",
      securityLevel: "loose",
      flowchart: { curve: "basis" },
      gantt: { axisFormat: "%m/%d/%Y" },
      sequence: { actorMargin: 50 },
    });
  }, []);

  useEffect(() => {
    if (loadedSections[activeSection]) {
      mermaid.init(undefined, document.querySelectorAll(".mermaid"));
    }
  }, [activeSection, loadedSections]);

  useEffect(() => {
    if (isOpen && insightId) {
      setIsLoading(true);
      // Fetch the insight title
      axios
        .get(`/fetch-insight-title/${insightId}/`)
        .then((response) => setInsightTitle(response.data.title))
        .catch((error) => console.error("Error fetching insight title:", error));

      axios
        .post("/generate-report/", { painpoint_analysis_id: insightId })
        .then((response) => {
          setReportId(response.data.report_id);
          const firstSectionName = Object.keys(SECTION_ORDER)[0];
          setActiveSection(firstSectionName);
          loadSectionContent(response.data.report_id, firstSectionName);
        })
        .catch((error) => console.error("Error generating report:", error));
    }
  }, [isOpen, insightId]);

  const handleClose = () => {
    closeReport();
    refreshCredits();
  };

  const loadSectionContent = (reportId, sectionName) => {
    setIsLoading(true); // Show loader before content starts loading
    if (!loadedSections[sectionName]) {
      axios
        .get(`/fetch-section-content/${reportId}/${sectionName}/`)
        .then((response) => {
          let content = response.data.content;
          let extractedContent = sanitizeContent(content);
          setLoadedSections((prev) => ({
            ...prev,
            [sectionName]: extractedContent,
          }));
          setActiveSection(sectionName);
          setIsLoading(false); // Hide loader once content is loaded
        })
        .catch((error) => {
          console.error("Error fetching section content:", error);
          setIsLoading(false); // Hide loader even if there's an error

          if (error.response && error.response.status === 403) {
            setShowUpgradeModal(true); // Trigger the upgrade modal on 403 error
          }
        });
    } else {
      setActiveSection(sectionName);
      setIsLoading(false); // Hide loader if content was already loaded
    }
  };

  const toggleSidebar = () => setIsSidebarOpen(!isSidebarOpen);

  const changeFontSize = (size) => {
    setFontSize(size);
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-white z-50 overflow-hidden font-serif">
      {isLoading ? (
        <LoadingExperience insightTitle={insightTitle} />
      ) : (
        <>
          <header className="fixed top-0 left-0 right-0 h-16 flex justify-between items-center px-4 bg-white border-b border-gray-200 z-10">
            <button onClick={toggleSidebar} className="p-2 text-gray-600 hover:text-gray-900">
              <Menu size={24} />
            </button>
            <h2 className="text-xl font-semibold text-gray-900 flex items-center">
              <BookOpen className="mr-2" size={24} />
              Full Report
            </h2>
            <div className="flex items-center space-x-2">
              <button
                onClick={() => changeFontSize("text-base")}
                className={`px-2 py-1 rounded ${fontSize === "text-base" ? "bg-gray-200" : "hover:bg-gray-100"}`}
              >
                A
              </button>
              <button
                onClick={() => changeFontSize("text-lg")}
                className={`px-2 py-1 rounded ${fontSize === "text-lg" ? "bg-gray-200" : "hover:bg-gray-100"}`}
              >
                A+
              </button>
              <button
                onClick={() => changeFontSize("text-xl")}
                className={`px-2 py-1 rounded ${fontSize === "text-xl" ? "bg-gray-200" : "hover:bg-gray-100"}`}
              >
                A++
              </button>
              <button onClick={handleClose} className="p-2 text-gray-600 hover:text-gray-900">
                <X size={24} />
              </button>
            </div>
          </header>

          {/* <AnimatePresence>
            {isSidebarOpen && (
              <motion.nav
                initial={{ x: "-100%" }}
                animate={{ x: 0 }}
                exit={{ x: "-100%" }}
                transition={{ type: "tween", duration: 0.3 }}
                className="fixed inset-y-0 left-0 w-64 bg-white border-r border-gray-200 pt-16 z-20 overflow-y-auto"
              >
                {Object.entries(SECTION_ORDER).map(([section, index]) => (
                  <button
                    key={section}
                    onClick={() => {
                      loadSectionContent(reportId, section);
                      setIsSidebarOpen(false);
                    }}
                    className={`w-full text-left py-3 px-6 ${
                      activeSection === section ? "bg-gray-100 text-gray-900 font-semibold" : "text-gray-600 hover:bg-gray-50"
                    } transition-colors duration-200`}
                  >
                    {section}
                  </button>
                ))}
              </motion.nav>
            )}
          </AnimatePresence> */}
          <AnimatePresence>
            {isSidebarOpen && (
              <motion.nav
                initial={{ x: "-100%" }}
                animate={{ x: 0 }}
                exit={{ x: "-100%" }}
                transition={{ type: "tween", duration: 0.3 }}
                className="fixed inset-y-0 left-0 w-64 bg-white border-r border-gray-200 pt-16 z-20 overflow-y-auto"
              >
                {Object.entries(SECTION_ORDER).map(([section, index]) => (
                  <button
                    key={section}
                    onClick={() => {
                      if (section === "ExecutiveSummary" || insightreportCredits > 0) {
                        // Only load content if it's the first section or if credits are available
                        loadSectionContent(reportId, section);
                        setIsSidebarOpen(false);
                      } else {
                        setShowUpgradeModal(true); // Show upgrade modal if section is locked
                      }
                    }}
                    className={`w-full text-left py-3 px-6 flex items-center ${
                      activeSection === section ? "bg-gray-100 text-gray-900 font-semibold" : "text-gray-600 hover:bg-gray-50"
                    } transition-colors duration-200`}
                  >
                    {section}
                    {/* Show lock icon for locked sections, except for "ExecutiveSummary" */}
                    {section !== "ExecutiveSummary" && insightreportCredits <= 0 && <Lock size={18} className="ml-auto text-gray-400" />}
                  </button>
                ))}
              </motion.nav>
            )}
          </AnimatePresence>

          <main className="pt-20 pb-24 px-4 sm:px-6 md:px-8 lg:px-0 max-w-2xl mx-auto overflow-y-auto h-full">
            <AnimatePresence mode="wait">
              <motion.div
                key={activeSection}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                transition={{ duration: 0.3 }}
              >
                <div className={`prose prose-lg prose-gray max-w-none ${fontSize}`}>
                  {loadedSections[activeSection] ? <CustomMarkdownRenderer content={loadedSections[activeSection]} /> : <SkeletonLoader />}
                </div>
              </motion.div>
            </AnimatePresence>
          </main>

          {/* <footer className="fixed bottom-0 left-0 right-0 h-16 flex justify-between items-center px-4 bg-white border-t border-gray-200">
            <button
              onClick={() => {
                const currentIndex = Object.keys(SECTION_ORDER).indexOf(activeSection);
                if (currentIndex > 0) loadSectionContent(reportId, Object.keys(SECTION_ORDER)[currentIndex - 1]);
              }}
              disabled={activeSection === Object.keys(SECTION_ORDER)[0]}
              className="flex items-center text-gray-600 hover:text-gray-900 disabled:text-gray-400 disabled:cursor-not-allowed"
            >
              <ChevronLeft size={20} className="mr-1" />
              <span>Previous</span>
            </button>
            <button
              onClick={() => {
                const currentIndex = Object.keys(SECTION_ORDER).indexOf(activeSection);
                if (currentIndex < Object.keys(SECTION_ORDER).length - 1)
                  loadSectionContent(reportId, Object.keys(SECTION_ORDER)[currentIndex + 1]);
              }}
              disabled={activeSection === Object.keys(SECTION_ORDER)[Object.keys(SECTION_ORDER).length - 1]}
              className="flex items-center text-gray-600 hover:text-gray-900 disabled:text-gray-400 disabled:cursor-not-allowed"
            >
              <span>Next</span>
              <ChevronRight size={20} className="ml-1" />
            </button>
          </footer> */}
          <footer className="fixed bottom-0 left-0 right-0 h-16 flex justify-between items-center px-4 bg-white border-t border-gray-200">
            <button
              onClick={() => {
                const currentIndex = Object.keys(SECTION_ORDER).indexOf(activeSection);
                if (currentIndex > 0) loadSectionContent(reportId, Object.keys(SECTION_ORDER)[currentIndex - 1]);
              }}
              disabled={activeSection === Object.keys(SECTION_ORDER)[0]}
              className="flex items-center text-gray-600 hover:text-gray-900 disabled:text-gray-400 disabled:cursor-not-allowed"
            >
              <ChevronLeft size={20} className="mr-1" />
              <span>Previous</span>
            </button>

            <button
              onClick={() => {
                const currentIndex = Object.keys(SECTION_ORDER).indexOf(activeSection);
                const nextSection = Object.keys(SECTION_ORDER)[currentIndex + 1];
                if (nextSection && (currentIndex === 0 || insightreportCredits > 0)) {
                  // Load next section only if the user has credits or it's the first section
                  loadSectionContent(reportId, nextSection);
                } else {
                  setShowUpgradeModal(true); // Trigger upgrade modal if the section is locked
                }
              }}
              disabled={
                activeSection === Object.keys(SECTION_ORDER)[Object.keys(SECTION_ORDER).length - 1] ||
                (insightreportCredits <= 0 && activeSection !== Object.keys(SECTION_ORDER)[0])
              }
              className="flex items-center text-gray-600 hover:text-gray-900 disabled:text-gray-400 disabled:cursor-not-allowed"
            >
              <span>Next</span>
              <ChevronRight size={20} className="ml-1" />
              {/* Show lock icon if next section is locked */}
              {insightreportCredits <= 0 && activeSection !== Object.keys(SECTION_ORDER)[0] && (
                <Lock size={18} className="ml-2 text-gray-400" />
              )}
            </button>
          </footer>
        </>
      )}
    </div>
  );
};

const SkeletonLoader = () => (
  <div className="space-y-4">
    <div className="h-4 bg-gray-200 rounded w-3/4"></div>
    <div className="h-4 bg-gray-200 rounded"></div>
    <div className="h-4 bg-gray-200 rounded"></div>
    <div className="h-4 bg-gray-200 rounded w-1/2"></div>
  </div>
);
// const NeedFinderAIUpgradeTeaser = ({ setActiveTab, currentPlan }) => {
//   const upgradeFeatures = [
//     {
//       icon: TrendingUp,
//       title: "Realtime AI Market Research",
//       description: "Wake up to fresh game-changing insights every day",
//     },
//     {
//       icon: Briefcase,
//       title: "Lightning-Fast Business Planning",
//       description: "Your AI co-founder crafts comprehensive business plans in minutes.",
//     },
//     {
//       icon: Lightbulb,
//       title: "20 Spark an Idea Credits",
//       description: "Generate innovative solutions on demand.",
//     },
//     {
//       icon: Users, // Use Users icon to represent user feedback
//       title: "Product Feedback Area",
//       description: "Get real feedback on your prototypes from live users.",
//     },
//     {
//       icon: Network, // Replace with correct icon for networking
//       title: "Build Your Network in Virtual Co-Working Space",
//       description: "Work with a new maker daily.",
//     },
//     {
//       icon: Zap, // Use Zap icon to indicate marketing boost
//       title: "AI Marketing Assistant",
//       description: "Discover prime online spots on the internet to showcase your product.",
//     },
//   ];

//   return (
//     <div className="mt-16 mb-12 bg-gradient-to-br from-blue-600 to-indigo-700 rounded-3xl overflow-hidden shadow-2xl">
//       <div className="px-8 py-10 sm:p-12">
//         {/* <h3 className="text-3xl font-bold text-white mb-2">Unlock Full 1000+ More Game-Changing Insights & Full Reports</h3> */}
//         <h3 className="text-3xl font-bold text-white mb-2">Unlock Your AI Co-founder's Full Potential</h3>
//         <p className="text-xl text-blue-100 mb-8">
//           {/* You've seen the potential. Imagine uncovering 200x more opportunities like these EVERY MONTH. Gain the competitive edge, outpace
//           your rivals, and build products your customers crave. */}
//           {/* Uncover 100x more opportunities and full reports like these EVERY MONTH. */}
//           {/* Gain the competitive edge, outpace
//                your rivals, and build products your customers crave. */}
//         </p>

//         <div className="grid grid-cols-1 sm:grid-cols-3 gap-6 mb-10">
//           {upgradeFeatures.map((feature, index) => (
//             <div key={index} className="bg-white bg-opacity-10 rounded-xl p-6 relative overflow-hidden">
//               <feature.icon className="text-blue-300 mb-4" size={32} />
//               <h4 className="text-lg font-semibold text-white mb-2">{feature.title}</h4>
//               <p className="text-sm text-blue-200">{feature.description}</p>
//               <Lock className="absolute top-4 right-4 text-blue-300 opacity-50" size={20} />
//             </div>
//           ))}
//         </div>

//         <div className="flex flex-col sm:flex-row items-center justify-between">
//           <button
//             onClick={() => setActiveTab("subscriptions")}
//             className="bg-green-500 text-white py-3 px-8 rounded-xl font-semibold hover:bg-green-600 transition-all duration-300 transform hover:scale-105 shadow-lg flex items-center mb-4 sm:mb-0"
//           >
//             <Zap className="mr-2" size={20} />
//             Upgrade Now and Dominate Your Market
//           </button>
//           <div className="text-white text-center sm:text-right">
//             <span className="block text-4xl font-bold">$59/mo</span>
//             <span className="text-sm text-blue-200">Most popular plan</span>
//           </div>
//         </div>

//         <p className="text-center text-blue-200 mt-6 text-sm">
//           Money-back guarantee if you don't find at least one game-changing insight in your first week!
//         </p>
//       </div>
//     </div>
//   );
// };

const NeedFinderAIUpgradeTeaser = ({ setActiveTab, currentPlan }) => (
  <div className="bg-white p-8 rounded-lg shadow-lg max-w-2xl mx-auto text-center">
    <h2 className="text-3xl font-bold mb-4">Unlock Your AI Co-founder's Full Potential!</h2>
    <p className="text-xl text-gray-600 mb-6"> Uncover 50x more opportunities and full reports like these EVERY MONTH.</p>

    <div className="bg-blue-50 p-6 rounded-lg mb-8">
      <h3 className="text-2xl font-semibold mb-4">With the Innovator Plan, you'll get:</h3>
      <div className="text-left">
        <FeatureItem icon={Zap} text="250 AI Insights per month" />
        <FeatureItem icon={Brain} text="5 Full Business Reports" />
        <FeatureItem icon={Users} text="10 Feedback Submissions" />
        <FeatureItem icon={Coffee} text="14-Day Access to Virtual Coworking Space" />
        <FeatureItem icon={Target} text="50 AI Marketing Credits" />
      </div>
    </div>

    <div className="mb-8">
      <p className="text-3xl font-bold mb-2">
        $59<span className="text-xl font-normal">/month</span>
      </p>
      <p className="text-green-600 font-semibold">Most popular plan</p>
    </div>

    <button
      onClick={() => setActiveTab("subscriptions")}
      className="bg-green-500 text-white px-8 py-3 rounded-full text-xl font-semibold hover:bg-green-700 transition duration-300 mb-6"
    >
      Upgrade Now
    </button>

    <p className="text-sm text-gray-500 mb-4">
      {" "}
      Money-back guarantee if you don't find at least one game-changing insight in your first week!
    </p>
  </div>
);

const InsightsTab = ({ userDetails, setUserDetails, setShowUpgradeModal, showUpgradeModal, setActiveTab, fetchUserDetails }) => {
  const [isNewUser, setIsNewUser] = useState(true);
  const [isEditingKeywords, setIsEditingKeywords] = useState(false);
  const [monitoringConfig, setMonitoringConfig] = useState({
    keywords: "",
    frequency: "daily",
    sources: [],
    notifications: true,
  });
  const [isGeneratingInsights, setIsGeneratingInsights] = useState(false);
  const [generationProgress, setGenerationProgress] = useState(0);
  const [insights, setInsights] = useState([]);
  const [nextPageUrl, setNextPageUrl] = useState(null);
  const [loadingMore, setLoadingMore] = useState(false);
  const [expandedCards, setExpandedCards] = useState([]);
  const [currentPlan, setCurrentPlan] = useState(userDetails?.current_plan || "Free");
  const [showKeywordModal, setShowKeywordModal] = useState(false);
  const [likedPainPoints, setLikedPainPoints] = useState(new Set());
  const [bookmarkedPainPoints, setBookmarkedPainPoints] = useState(new Set());
  const [sparkedIdea, setSparkedIdea] = useState(null);
  const [showSparkIdeaModal, setShowSparkIdeaModal] = useState(false);
  const [isSparkingIdea, setIsSparkingIdea] = useState(false);
  const [insightCredits, setInsightCredits] = useState(userDetails?.insight_credits || 0);
  const [sparkIdeaCredits, setSparkIdeaCredits] = useState(userDetails?.spark_idea_credits || 0);
  const [insightreportCredits, setInsightreportCredits] = useState(userDetails?.ai_insights_reports || 0);

  const hasInsights = Array.isArray(insights) && insights.length > 0;
  const [lastUpdated, setLastUpdated] = useState("");
  const [suggestedKeywords, setSuggestedKeywords] = useState([]);
  const [alertsEnabled, setAlertsEnabled] = useState(false);
  const [reachedFreePreviewEnd, setReachedFreePreviewEnd] = useState(false);
  const suggestedKeywords1 = ["business idea validation", "remote work tools", "youtube advertising", "real estate developer"];

  const [showFullReportModal, setShowFullReportModal] = useState(false);
  const [selectedInsightId, setSelectedInsightId] = useState(null);
  const [userReports, setUserReports] = useState([]);
  const [loading, setLoading] = useState(true);
  const [generatedReports, setGeneratedReports] = useState(new Set());

  const handleDownloadReport = (insightId) => {
    // Implement the download functionality here
    console.log(`Downloading report for insight ${insightId}`);
    // You might want to call an API endpoint or generate a PDF here
  };

  useEffect(() => {
    axios
      .get("/fetch-user-reports/")
      .then((response) => {
        const generatedReportIds = new Set(response.data.reports.map((report) => report.painpoint_analysis_id));
        setGeneratedReports(generatedReportIds);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching user reports:", error);
        setLoading(false);
      });
  }, []);

  const handleViewFullReport = (insightId) => {
    setSelectedInsightId(insightId);
    setShowFullReportModal(true);
  };

  useEffect(() => {
    // Fetch insights or other relevant data
    axios
      .get("insights/")
      .then((response) => setInsights(response.data))
      .catch((error) => console.error(error));
  }, []);

  const fetchUserInsights = async (url = "fetch-user-insights/") => {
    try {
      const response = await axios.get(url);
      setInsights((prev) => [...prev, ...response.data.results.insights]);
      setMonitoringConfig((prev) => ({
        ...prev,
        keywords: response.data.results.monitored_keywords.join(", "),
      }));
      setNextPageUrl(response.data.next);
      setLoadingMore(false);
    } catch (error) {
      console.error("Error fetching user insights:", error);
      setLoadingMore(false);
    }
  };
  const fetchSuggestedKeywords = async (keywords) => {
    try {
      const response = await axios.get(`https://api.datamuse.com/words?ml=${encodeURIComponent(keywords)}`);
      setSuggestedKeywords(response.data.slice(0, 5));
    } catch (error) {
      console.error("Error fetching suggested keywords:", error);
    }
  };

  useEffect(() => {
    if (monitoringConfig.keywords.trim().length > 2) {
      fetchSuggestedKeywords(monitoringConfig.keywords);
    }
  }, [monitoringConfig.keywords]);

  const checkAndPerformAction = (currentCredits, actionCallback) => {
    if (currentCredits <= 0 && userDetails?.current_plan === "Free") {
      setShowUpgradeModal(true);
      return;
    }
    actionCallback();
  };

  useEffect(() => {
    if (insights && insights.length > 0) {
      // Find the most recent `created_at` timestamp
      const mostRecentInsight = insights.reduce((latest, insight) => {
        const insightDate = new Date(insight.created_at);
        return insightDate > latest ? insightDate : latest;
      }, new Date(insights[0].created_at));

      // Format the date to something like "3 minutes ago"
      const formattedDate = formatDistanceToNow(new Date(mostRecentInsight), { addSuffix: true });
      setLastUpdated(formattedDate);
    }
  }, [insights]);

  useEffect(() => {
    if (insights && insights.length > 0 && !loadingMore && !nextPageUrl && userDetails?.current_plan === "Free") {
      setReachedFreePreviewEnd(true);
    }
  }, [insights, loadingMore, nextPageUrl, userDetails]);

  const handleSparkIdea = (painpointId) => {
    checkAndPerformAction(sparkIdeaCredits, async () => {
      setIsSparkingIdea(true);
      setShowSparkIdeaModal(true);
      try {
        const response = await axios.post("/spark-idea/", { painpoint_id: painpointId });
        setSparkedIdea(response.data.sparked_idea);
      } catch (error) {
        console.error("Error sparking idea:", error);
        // Handle error (e.g., show an error message to the user)
      } finally {
        setIsSparkingIdea(false);
      }
    });
  };

  const handleMonitoringSetup = async (e) => {
    e.preventDefault();
    if (insightCredits <= 0 && currentPlan !== "Pioneer") {
      setShowUpgradeModal(true);
      return;
    }
    setIsGeneratingInsights(true);
    setGenerationProgress(0);
    const progressInterval = setInterval(() => {
      setGenerationProgress((prev) => (prev >= 90 ? prev : prev + 1));
    }, 1500);
    try {
      const keywords = monitoringConfig.keywords
        .split(",")
        .map((kw) => kw.trim())
        .filter((kw) => kw.length > 0);
      if (keywords.length === 0) {
        setIsGeneratingInsights(false);
        clearInterval(progressInterval);
        return alert("Please enter at least one valid keyword.");
      }
      const response = await axios.post("generate-insights/", { interests: keywords });
      if (response.status === 200) {
        await fetchUserInsights();
        const creditsResponse = await axios.get("/user-details/");
        setUserDetails(creditsResponse.data);
        setInsightCredits(creditsResponse.data.insight_credits);
        setSparkIdeaCredits(creditsResponse.data.spark_idea_credits);
        setGenerationProgress(100);
        setTimeout(() => window.location.reload(), 500);
      }
    } catch (error) {
      console.error("Error generating insights or updating credits:", error);
      setIsGeneratingInsights(false);
      clearInterval(progressInterval);
      alert("Something went wrong. We have been notified.");
    } finally {
      clearInterval(progressInterval);
    }
  };

  const handleNewInsight = () => {
    checkAndPerformAction(insightCredits, async () => {
      // Existing logic for generating insights
      setIsGeneratingInsights(true);
      setGenerationProgress(0);
      const progressInterval = setInterval(() => {
        setGenerationProgress((prev) => (prev >= 90 ? prev : prev + 1));
      }, 1500);
      try {
        const keywords = monitoringConfig.keywords
          .split(",")
          .map((kw) => kw.trim())
          .filter((kw) => kw.length > 0);
        if (keywords.length === 0) {
          setIsGeneratingInsights(false);
          clearInterval(progressInterval);
          return alert("Please enter at least one valid keyword.");
        }
        const response = await axios.post("generate-insights/", { interests: keywords });
        if (response.status === 200) {
          await fetchUserInsights();
          const creditsResponse = await axios.get("/user-details/");
          setUserDetails(creditsResponse.data);
          setInsightCredits(creditsResponse.data.insight_credits);
          setSparkIdeaCredits(creditsResponse.data.spark_idea_credits);
          setGenerationProgress(100);
          setTimeout(() => window.location.reload(), 500);
        }
      } catch (error) {
        console.error("Error generating insights or updating credits:", error);
        setIsGeneratingInsights(false);
        clearInterval(progressInterval);
        alert("Something went wrong. We have been notified.");
      } finally {
        clearInterval(progressInterval);
      }
    });
  };

  const handleConfigChange = (e) => {
    const { name, value } = e.target;
    setMonitoringConfig((prev) => ({ ...prev, [name]: value }));
  };

  const toggleExpand = (index) => {
    setExpandedCards((prev) => (prev.includes(index) ? prev.filter((i) => i !== index) : [...prev, index]));
  };

  const loadMoreInsights = useCallback(() => {
    if (nextPageUrl && !loadingMore) {
      setLoadingMore(true);
      fetchUserInsights(nextPageUrl);
    }
  }, [nextPageUrl, loadingMore]);

  useEffect(() => {
    if (userDetails) {
      fetchUserInsights();
      setInsightCredits(userDetails.insight_credits || 0);
      setSparkIdeaCredits(userDetails.spark_idea_credits || 0);
      setInsightreportCredits(userDetails.ai_insights_reports || 0);
      setCurrentPlan(userDetails.current_plan || "Free");

      // Fetch liked pain points
      axios
        .get("/list-liked/")
        .then((response) => {
          const likedIds = new Set(response.data.map((item) => item.id));
          setLikedPainPoints(likedIds);
        })
        .catch((error) => console.error("Error fetching liked pain points:", error));

      // Fetch bookmarked pain points
      axios
        .get("/list-bookmarks/")
        .then((response) => {
          const bookmarkedIds = new Set(response.data.map((item) => item.id));
          setBookmarkedPainPoints(bookmarkedIds);
        })
        .catch((error) => console.error("Error fetching bookmarked pain points:", error));
    }
  }, [userDetails]);

  const handleToggleLike = async (painpointId) => {
    try {
      const response = await axios.post(`/toggle-like/${painpointId}/`);
      setLikedPainPoints((prev) => {
        const newSet = new Set(prev);
        if (response.data.liked) {
          newSet.add(painpointId);
        } else {
          newSet.delete(painpointId);
        }
        return newSet;
      });
    } catch (error) {
      console.error("Error toggling like:", error);
    }
  };

  const handleToggleBookmark = async (painpointId) => {
    try {
      const response = await axios.post(`/toggle-bookmark/${painpointId}/`);
      setBookmarkedPainPoints((prev) => {
        const newSet = new Set(prev);
        if (response.data.bookmarked) {
          newSet.add(painpointId);
        } else {
          newSet.delete(painpointId);
        }
        return newSet;
      });
    } catch (error) {
      console.error("Error toggling bookmark:", error);
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.innerHeight + document.documentElement.scrollTop >= document.documentElement.offsetHeight - 100) {
        loadMoreInsights();
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [loadMoreInsights]);

  const KeywordSetupForm = () => {
    const [localKeywords, setLocalKeywords] = useState(monitoringConfig.keywords);

    const handleLocalChange = (e) => {
      setLocalKeywords(e.target.value);
    };

    const handleBlur = (e) => {
      handleConfigChange(e);
    };

    return (
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -20 }}
        className="bg-white rounded-2xl shadow-lg p-4 sm:p-8 mb-8"
      >
        <form onSubmit={handleMonitoringSetup} className="space-y-6">
          <div>
            <label htmlFor="keywords" className="block text-sm font-medium text-gray-700 mb-1">
              Keywords to monitor (min 2 keywords)
            </label>
            <input
              type="text"
              id="keywords"
              name="keywords"
              value={localKeywords}
              onChange={handleLocalChange}
              onBlur={handleBlur}
              placeholder="Eg: Meal delivery service, Food subscription box"
              className="w-full px-4 py-3 border border-gray-300 rounded-xl focus:outline-none focus:ring-2 focus:ring-blue-500 text-base sm:text-lg"
            />
          </div>
          <button
            type="submit"
            className="w-full bg-blue-600 text-white py-3 px-6 rounded-xl hover:bg-blue-700 transition-colors duration-200 text-base sm:text-lg font-semibold flex items-center justify-center"
          >
            <Search className="mr-2" size={20} />
            {isNewUser ? "Start Monitoring" : "Update Keywords"}
          </button>
        </form>
        <div className="mt-6 p-4 bg-blue-50 rounded-xl flex items-start">
          <AlertCircle className="text-blue-500 mr-2 flex-shrink-0" size={20} />
          <p className="text-sm text-blue-700">We also monitor related keywords for comprehensive insights.</p>
        </div>
      </motion.div>
    );
  };
  const UpgradeModal = ({ show, onClose }) => {
    if (!show) return null;

    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4">
        <div className="bg-white rounded-lg p-6 w-full max-w-md">
          <h3 className="text-lg font-medium leading-6 text-gray-900 mb-4">Upgrade Your Plan</h3>
          <p className="text-gray-600 mb-4">
            You've run out of credits. Upgrade your plan to continue generating insights and sparking ideas!
          </p>
          <div className="flex justify-end space-x-2">
            <button
              onClick={() => {
                console.log("Closing modal with Maybe Later");
                onClose(); // Close the modal
              }}
              className="w-full mt-4 text-gray-600 hover:text-gray-800 transition-colors"
            >
              Maybe Later
            </button>
            <button
              onClick={() => {
                console.log("Navigating to subscriptions and closing modal");
                onClose(); // Close the modal
                setActiveTab("subscriptions"); // Navigate to the Subscriptions tab
              }}
              className="w-full bg-blue-600 text-white py-3 rounded-xl font-semibold hover:bg-blue-700 transition-colors"
            >
              Upgrade Now
            </button>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="space-y-8 max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
      <AnimatePresence>
        {isGeneratingInsights && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4"
          >
            <motion.div
              initial={{ scale: 0.9, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              exit={{ scale: 0.9, opacity: 0 }}
              className="bg-white rounded-2xl p-6 sm:p-8 w-full max-w-md shadow-xl"
            >
              <h3 className="text-xl sm:text-2xl font-semibold mb-4 text-gray-900">Generating Premium Insights</h3>
              <div className="w-full bg-gray-200 rounded-full h-3 mb-4">
                <motion.div
                  className="bg-blue-600 h-3 rounded-full"
                  initial={{ width: 0 }}
                  animate={{ width: `${generationProgress}%` }}
                  transition={{ duration: 0.5 }}
                />
              </div>
              <p className="text-sm sm:text-base text-gray-600">Analyzing market trends, user needs, and potential opportunities...</p>
              <p className="text-sm text-gray-500 mt-2">This may take up to 2-3 minutes. Please don't close this window.</p>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>

      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-4 py-2 space-y-2">
        {/* <motion.div
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          className="bg-gradient-to-br from-blue-800 to-purple-900 rounded-3xl p-6 sm:p-8 text-white shadow-lg hover:shadow-xl transition-all duration-300"
        >
          <div className="flex items-center space-x-3 mb-6">
            <Sparkles className="w-8 h-8 text-yellow-300 animate-pulse" />
            <h2 className="text-2xl sm:text-3xl font-bold">What to Expect In Realtime Market Research ?</h2>
          </div>

          <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-6">
            <FeatureIcon
              Icon={Zap}
              text="Based on keyword we bring unmet needs, feature requests,product idea which users are currently discussing online."
            />
            <FeatureIcon Icon={Users} text="Generate in-depth, AI-powered business reports to validate and refine your concepts." />
            <FeatureIcon Icon={Lightbulb} text="Spark an Idea button ignites your creativity with a game-changing business idea." />
          </div>

          <p className="mt-6 text-sm sm:text-base">Discover your next feature or find an idea aligned with your skills and experience.</p>
        </motion.div> */}
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          className="bg-gradient-to-br from-blue-800 via-indigo-900 to-purple-900 rounded-3xl p-8 sm:p-12 text-white shadow-xl transform transition-all duration-500 hover:shadow-2xl hover:scale-[1.02]"
        >
          {/* Section Title */}
          <div className="flex items-center space-x-4 mb-8">
            <Sparkles className="w-10 h-10 text-yellow-300 animate-pulse" />
            <h2 className="text-3xl sm:text-3xl font-bold tracking-tight">What to Expect In Realtime Market Research?</h2>
          </div>

          {/* Feature Grid */}
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
            <FeatureIcon
              Icon={Zap}
              text=" We analyze online discussions 24/7, delivering real-time insights on customer needs,feature requests and emerging trends."
              // text="Every few hours, AI Cofounder scans online discussions, delivering fresh insights on customer needs, feature requests, and emerging trends directly to your dashboard."
            />
            <FeatureIcon
              Icon={Users}
              // text="Every few hours, AI Cofounder scans online discussions, delivering fresh insights on customer needs, feature requests, and emerging trends directly to your dashboard."
              text=" Transform concepts into action plans with in-depth, AI-powered business reports to validate and refine your concepts."
            />
            <FeatureIcon
              Icon={Lightbulb}
              // text="Every few hours, AI Cofounder scans online discussions, delivering fresh insights on customer needs, feature requests, and emerging trends directly to your dashboard."
              text='The "Spark an Idea" feature is your on-demand brainstorming partner, ready to kickstart your creativity whenever you need it.'
            />
            {/* Optional: Uncomment for additional features */}
            {/* 
    <FeatureIcon Icon={MessageSquare} text="Engage in product feedback discussions" />
    <FeatureIcon Icon={Coffee} text="Collaborate in our virtual co-working space" />
    <FeatureIcon Icon={Target} text="Leverage AI-driven marketing insights" />
    */}
          </div>
        </motion.div>

        <div className="bg-white rounded-2xl shadow-lg border border-gray-100 overflow-hidden">
          <div className="p-6 sm:p-8 space-y-6">
            <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center space-y-4 sm:space-y-0">
              <h2 className="text-2xl sm:text-3xl font-semibold text-gray-900">Keywords Monitoring</h2>
            </div>

            <div className="relative z-10">
              <input
                type="text"
                value={monitoringConfig.keywords || "No keywords set"}
                readOnly
                className="w-full bg-gray-50 border border-gray-200 rounded-xl py-3 px-4 pr-12 text-gray-700 focus:ring-0 focus:ring-blue-100 focus:outline-none transition-all duration-300 cursor-pointer"
                onClick={() => setShowKeywordModal(true)}
              />
              <button
                className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400 hover:text-blue-600 transition-all duration-300"
                onClick={() => setShowKeywordModal(true)}
              >
                <Pencil size={18} />
              </button>
            </div>

            <div className="bg-blue-50 rounded-xl p-4 flex items-start space-x-3">
              <Info size={18} className="text-blue-400 flex-shrink-0 mt-0.5" />
              <div>
                <p className="text-sm text-gray-700 font-medium mb-2">Example Keywords:</p>
                <div className="flex flex-wrap gap-2">
                  {suggestedKeywords1.map((keyword, index) => (
                    <span key={index} className="bg-blue-100 text-blue-800 px-2 py-1 rounded-full text-xs font-medium">
                      {keyword}
                    </span>
                  ))}
                </div>
              </div>
            </div>

            <button
              className="w-full flex items-center justify-center bg-blue-600 hover:bg-blue-700 text-white text-base font-medium py-3 px-6 rounded-xl transition-all duration-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 shadow-md"
              onClick={handleNewInsight}
              disabled={isGeneratingInsights}
            >
              <Sparkles size={18} className="mr-2" />
              {isGeneratingInsights ? "Generating..." : "New Insight"}
            </button>
          </div>

          <div className="bg-gray-50 px-6 sm:px-8 py-4 flex flex-col sm:flex-row justify-between items-start sm:items-center space-y-2 sm:space-y-0 border-t border-gray-200">
            <div className="text-sm">
              <span className="text-gray-500">Insight Credits: </span>
              <span className="font-semibold text-gray-900">{insightCredits}</span>
            </div>
            {/* <div className="text-sm">
              <span className="text-gray-500">Spark Idea Credits: </span>
              <span className="font-semibold text-gray-900">{sparkIdeaCredits}</span>
            </div> */}
            <div className="text-sm">
              <span className="text-gray-500">Full Report Credits: </span>
              <span className="font-semibold text-gray-900">{insightreportCredits}</span>
            </div>
          </div>
        </div>
      </div>

      <>
        {insights && insights.length > 0 ? (
          <div>
            <h3 className="text-2xl font-bold text-gray-900 mb-6">Your AI-Discovered Opportunities</h3>
            {/* {lastUpdated && (
              <p className="text-gray-500 text-sm">
                Last Updated: <span className="font-medium">{lastUpdated}</span>
              </p>
            )} */}
            <div className="space-y-8">
              {insights.map((insight, index) => (
                <motion.div
                  key={insight.id}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: index * 0.1 }}
                  className="bg-white rounded-2xl shadow-lg overflow-hidden transition-all duration-300 hover:shadow-xl border border-gray-100"
                >
                  <div className="p-4 sm:p-8">
                    <div className="flex flex-col sm:flex-row justify-between items-start mb-6">
                      <h3 className="text-xl sm:text-2xl font-bold text-gray-900 leading-tight pr-4 mb-2 sm:mb-0">
                        {insight.details?.pain_point || "No Pain Point Provided"}
                      </h3>

                      <div className="flex items-center space-x-2">
                        <span className="text-xs text-gray-500 bg-gray-100 px-2 py-1 rounded-full flex items-center">
                          <RefreshCw size={12} className="mr-1" />
                          {formatDistanceToNow(new Date(insight.created_at), { addSuffix: true })}
                        </span>
                        <button
                          className={`p-2 rounded-full ${
                            bookmarkedPainPoints.has(insight.id) ? "bg-blue-100 text-blue-600" : "bg-gray-100 text-gray-600"
                          } hover:bg-blue-200 transition-colors duration-200`}
                          title="Save to My Library"
                          onClick={() => handleToggleBookmark(insight.id)}
                        >
                          <Bookmark size={20} />
                        </button>
                      </div>
                    </div>

                    <div className="mb-8">
                      <p className="text-gray-700 bg-gray-50 p-4 sm:p-6 rounded-xl border-l-4 border-blue-500 text-base sm:text-lg italic">
                        "{insight.details?.problem_synopsis || "No problem synopsis available."}"
                      </p>
                    </div>

                    <div className="grid grid-cols-1 sm:grid-cols-3 gap-4 sm:gap-6 mb-8">
                      <div className="bg-blue-50 p-4 rounded-xl text-center">
                        <Zap className="text-blue-500 text-3xl mx-auto mb-2" />
                        <span className="block text-sm font-medium text-gray-600 mb-1">Market Opportunity</span>
                        <span className="block text-xl font-bold text-gray-900"> {insight.details.market_opportunity[0] || "N/A"}</span>
                      </div>
                      <div className="bg-green-50 p-4 rounded-xl text-center">
                        <Users className="text-green-500 text-3xl mx-auto mb-2" />
                        <span className="block text-sm font-medium text-gray-600 mb-1">Users Affected</span>
                        <span className="block text-xl font-bold text-gray-900">{insight.details?.users_affected || "N/A"}</span>
                      </div>
                      {/* <div className="bg-purple-50 p-4 rounded-xl text-center">
                          <TrendingUp className="text-purple-500 text-3xl mx-auto mb-2" />
                          <span className="block text-sm font-medium text-gray-600 mb-1">Frequency</span>
                          <span className="block text-xl font-bold text-gray-900">{insight.details?.frequency || "N/A"}</span>
                        </div> */}

                      <div className="bg-purple-50 p-4 rounded-xl text-center">
                        <TrendingUp className="text-purple-500 text-3xl mx-auto mb-2" />
                        <span className="block text-sm font-medium text-gray-600 mb-1">Tag</span>
                        <span className="block text-xl font-bold text-gray-900">{insight.details?.tag || "N/A"}</span>
                      </div>
                    </div>

                    {insight.details?.expert_insights && (
                      <div className="mb-8">
                        <h4 className="text-lg font-semibold mb-2 text-gray-800">Expert Insights</h4>
                        <p className="text-gray-700">{insight.details.expert_insights}</p>
                      </div>
                    )}

                    {/* <div className="grid grid-cols-1 sm:grid-cols-2 gap-8 mb-8">
                      {insight.details?.market_opportunity && (
                        <div>
                          <h4 className="text-lg font-semibold mb-2 text-gray-800">Market Opportunity</h4>
                          <ul className="list-disc pl-6 text-gray-700 space-y-2">
                            {insight.details.market_opportunity.map((item, idx) => (
                              <li key={idx}>{item}</li>
                            ))}
                          </ul>
                        </div>
                      )}
                      {insight.details?.revenue_potential && (
                        <div>
                          <h4 className="text-lg font-semibold mb-2 text-gray-800">Revenue Potential</h4>
                          <ul className="list-disc pl-6 text-gray-700 space-y-2">
                            {insight.details.revenue_potential.map((item, idx) => (
                              <li key={idx}>{item}</li>
                            ))}
                          </ul>
                        </div>
                      )}
                    </div> */}

                    <div className="flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-4 mb-8">
                      <button
                        onClick={() => toggleExpand(index)}
                        className="flex-1 bg-blue-600 text-white py-3 px-6 rounded-xl hover:bg-blue-700 transition-all duration-200 flex items-center justify-center shadow-md text-base sm:text-lg font-semibold"
                      >
                        {expandedCards.includes(index) ? (
                          <>
                            <ChevronUp size={24} className="mr-2" />
                            Hide Additional Details
                          </>
                        ) : (
                          <>
                            <ChevronDown size={24} className="mr-2" />
                            View Additional Details
                          </>
                        )}
                      </button>
                      <button
                        onClick={() => {
                          if (sparkIdeaCredits <= 0) {
                            setShowUpgradeModal(true);
                          } else {
                            handleSparkIdea(insight.id);
                          }
                        }}
                        className={`flex-1 py-3 px-6 rounded-xl transition-all duration-200 flex items-center justify-center shadow-md text-base sm:text-lg font-semibold ${
                          sparkIdeaCredits > 0 ? "bg-green-600 text-white hover:bg-green-700" : "bg-gray-400 cursor-not-allowed"
                        }`}
                        disabled={sparkIdeaCredits <= 0}
                      >
                        <Zap size={24} className="mr-2" /> Spark an Idea
                      </button>

                      <button
                        onClick={() => {
                          if (insightreportCredits <= 0 && !generatedReports.has(insight.id)) {
                            // If the user has no credits and hasn't generated the report, show the upgrade modal
                            setShowUpgradeModal(true);
                          } else {
                            // Otherwise, proceed with viewing the full report
                            handleViewFullReport(insight.id);
                          }
                        }}
                        className={`flex-1 py-3 px-6 rounded-xl transition-all duration-200 flex items-center justify-center shadow-md text-base sm:text-lg font-semibold ${
                          generatedReports.has(insight.id)
                            ? "bg-green-600 text-white hover:bg-green-700" // Style for already generated reports
                            : insightreportCredits > 0
                            ? "bg-purple-600 text-white hover:bg-purple-700" // Style for generating a new report with available credits
                            : "bg-gray-400 cursor-not-allowed" // Style for no credits and no report generated
                        }`}
                        disabled={insightreportCredits <= 0 && !generatedReports.has(insight.id)} // Disable if no credits and no report generated
                      >
                        <FileText size={24} className="mr-2" />
                        {generatedReports.has(insight.id) ? "View Generated Business Report" : "View Full Business Report"}{" "}
                        {/* Button text */}
                      </button>
                    </div>

                    <AnimatePresence>
                      {expandedCards.includes(index) && (
                        <motion.div
                          initial={{ opacity: 0, height: 0 }}
                          animate={{ opacity: 1, height: "auto" }}
                          exit={{ opacity: 0, height: 0 }}
                          transition={{ duration: 0.3 }}
                          className="space-y-8 overflow-hidden"
                        >
                          {insight.details?.competitive_landscape && (
                            <div className="bg-blue-50 p-4 sm:p-6 rounded-xl">
                              <h4 className="text-lg font-semibold mb-3 text-gray-800">Competitive Landscape</h4>
                              <ul className="list-disc pl-6 text-gray-700 space-y-2">
                                {insight.details.competitive_landscape.map((item, idx) => (
                                  <li key={idx}>{item}</li>
                                ))}
                              </ul>
                            </div>
                          )}
                          {insight.details?.customer_segmentation && (
                            <div className="bg-green-50 p-4 sm:p-6 rounded-xl">
                              <h4 className="text-lg font-semibold mb-3 text-gray-800">Customer Segmentation</h4>
                              <ul className="list-disc pl-6 text-gray-700 space-y-2">
                                {insight.details.customer_segmentation.map((item, idx) => (
                                  <li key={idx}>{item}</li>
                                ))}
                              </ul>
                            </div>
                          )}
                          {insight.details?.implementation_roadmap && (
                            <div className="bg-yellow-50 p-4 sm:p-6 rounded-xl">
                              <h4 className="text-lg font-semibold mb-3 text-gray-800">Implementation Roadmap</h4>
                              <ol className="list-decimal pl-6 text-gray-700 space-y-2">
                                {insight.details.implementation_roadmap.map((item, idx) => (
                                  <li key={idx}>{item}</li>
                                ))}
                              </ol>
                            </div>
                          )}
                          {insight.details?.risk_assessment && (
                            <div className="bg-red-50 p-4 sm:p-6 rounded-xl">
                              <h4 className="text-lg font-semibold mb-3 text-gray-800">Risk Assessment</h4>
                              <ul className="list-disc pl-6 text-gray-700 space-y-2">
                                {insight.details.risk_assessment.map((item, idx) => (
                                  <li key={idx}>{item}</li>
                                ))}
                              </ul>
                            </div>
                          )}
                          {insight.details?.success_metrics && (
                            <div className="bg-indigo-50 p-4 sm:p-6 rounded-xl">
                              <h4 className="text-lg font-semibold mb-3 text-gray-800">Success Metrics</h4>
                              <ul className="list-disc pl-6 text-gray-700 space-y-2">
                                {insight.details.success_metrics.map((item, idx) => (
                                  <li key={idx}>{item}</li>
                                ))}
                              </ul>
                            </div>
                          )}
                        </motion.div>
                      )}
                    </AnimatePresence>
                  </div>
                </motion.div>
              ))}
            </div>
            {loadingMore && (
              <div className="text-center py-4">
                <Loader2 className="animate-spin mx-auto" size={24} />
                <p className="text-gray-600 mt-2">Loading more insights...</p>
              </div>
            )}
            {/* {!loadingMore && !nextPageUrl && <p className="text-center text-gray-600 mt-8">That's all the insights for now.</p>} */}
          </div>
        ) : (
          <div className="text-center py-12">
            <p className="text-gray-600 text-lg">
              No insights available at the moment. Please start monitoring a trend or keyword to generate insights.
            </p>
          </div>
        )}
      </>

      {showKeywordModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4">
          <div className="bg-white rounded-lg p-6 w-full max-w-md">
            <h3 className="text-lg font-medium leading-6 text-gray-900 mb-4">Edit Monitoring Keywords</h3>
            <input
              type="text"
              value={monitoringConfig.keywords}
              onChange={handleConfigChange}
              name="keywords"
              className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none focus:border-blue-500 mb-4"
              placeholder="Enter keywords separated by commas"
            />
            <div className="flex justify-end space-x-2">
              <button
                type="button"
                className="px-4 py-2 text-sm font-medium text-gray-700 bg-gray-100 rounded-md hover:bg-gray-200 focus:outline-none focus:ring-2 focus-ring-offset-2 focus:ring-blue-500"
                onClick={() => setShowKeywordModal(false)}
              >
                Cancel
              </button>
              <button
                type="button"
                className="px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                onClick={() => {
                  handleMonitoringSetup({ preventDefault: () => {} });
                  setShowKeywordModal(false);
                }}
              >
                Save Changes
              </button>
            </div>
          </div>
        </div>
      )}
      {/* <UpgradeModal show={insightCredits <= 0 || sparkIdeaCredits <= 0} onClose={() => setShowUpgradeModal(false)} /> */}
      <UpgradeModal
        show={showUpgradeModal} // Correctly using showUpgradeModal here
        onClose={() => {
          console.log("onClose called, setting showUpgradeModal to false");
          setShowUpgradeModal(false);
        }}
      />
      {showSparkIdeaModal && (
        <SparkIdeaModal
          idea={sparkedIdea}
          onClose={() => {
            setShowSparkIdeaModal(false);
            setSparkedIdea(null);
            window.location.reload();
          }}
          isLoading={isSparkingIdea}
        />
      )}

      {reachedFreePreviewEnd && <NeedFinderAIUpgradeTeaser setActiveTab={setActiveTab} />}

      {/* <FullReportSidebar isOpen={showFullReportModal} closeReport={() => setShowFullReportModal(false)} insightId={selectedInsightId} /> */}
      {/* <FullReportSidebar isOpen={showFullReportModal} closeReport={() => setShowFullReportModal(false)} insightId={selectedInsightId} /> */}
      <FullReportSidebar
        isOpen={showFullReportModal}
        closeReport={() => setShowFullReportModal(false)}
        insightId={selectedInsightId}
        refreshCredits={() => fetchUserDetails()}
        setShowUpgradeModal={setShowUpgradeModal}
        insightreportCredits={insightreportCredits}
      />
    </div>
  );
};

const SparkIdeaModal = ({ idea, onClose, isLoading }) => {
  if (!idea && !isLoading) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-80 z-50 flex items-center justify-center p-4 overflow-y-auto">
      <div className="bg-white rounded-2xl p-6 sm:p-8 w-full max-w-4xl max-h-[90vh] overflow-y-auto">
        {isLoading ? (
          <div className="flex flex-col items-center justify-center h-64">
            <Loader2 className="animate-spin text-blue-600 mb-4" size={48} />
            <p className="text-lg text-gray-700">Generating your brilliant idea...</p>
          </div>
        ) : (
          <div className="sparked-idea space-y-6 sm:space-y-8">
            <div>
              <h1 className="text-3xl sm:text-4xl font-bold mb-2 text-blue-600">{idea.idea_name}</h1>
              <p className="text-xl text-gray-600 italic">{idea.tagline}</p>
            </div>

            <section>
              <h2 className="text-2xl font-semibold mb-2 text-gray-800">Vision</h2>
              <p className="text-gray-700">{idea.vision}</p>
            </section>

            <section>
              <h2 className="text-2xl font-semibold mb-2 text-gray-800">Core Concept</h2>
              <p className="text-gray-700">{idea.core_concept}</p>
            </section>

            <section>
              <h2 className="text-2xl font-semibold mb-2 text-gray-800">Key Features</h2>
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                {idea.key_features.map((feature, index) => (
                  <div key={index} className="bg-gray-50 p-4 rounded-xl">
                    <h3 className="text-lg font-semibold text-gray-800 mb-2">{feature.feature_name}</h3>
                    <p className="text-gray-600">{feature.description}</p>
                  </div>
                ))}
              </div>
            </section>

            <section>
              <h2 className="text-2xl font-semibold mb-2 text-gray-800">Market Potential</h2>
              <p className="text-gray-700">{idea.market_potential}</p>
            </section>

            <section>
              <h2 className="text-2xl font-semibold mb-2 text-gray-800">Execution Challenges</h2>
              {idea.execution_challenges.map((challenge, index) => (
                <div key={index} className="mb-4">
                  <h3 className="text-lg font-semibold text-gray-800">{challenge.challenge_name}</h3>
                  <p className="text-gray-600">{challenge.description}</p>
                </div>
              ))}
            </section>

            <section>
              <h2 className="text-2xl font-semibold mb-2 text-gray-800">Future Implications</h2>
              <p className="text-gray-700">{idea.future_implications}</p>
            </section>

            <button
              onClick={onClose}
              className="mt-8 w-full bg-blue-600 text-white py-3 px-6 rounded-xl hover:bg-blue-700 transition-all duration-200 flex items-center justify-center shadow-sm text-lg font-medium"
            >
              Close
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default InsightsTab;
