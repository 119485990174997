import React from "react";
import Header from "../components/Header";

const ShippingAndDelivery = () => {
  return (
    <div className="font-inter antialiased bg-white text-gray-900 tracking-tight">
      <Header />
      <div className="grow p-4">
        <section className="bg-gradient-to-b from-gray-50 to-blue-100 min-h-screen">
          <div className="max-w-4xl mx-auto py-12">
            <h1 className="text-2xl font-bold mb-4">Shipping and Delivery</h1>
            <p className="mb-4">
              At Ingena technology Private Limited, we aim to provide efficient
              and timely delivery of all our digital products and services.
              Below you will find important information about our shipping and
              delivery policies.
            </p>
            <h2 className="text-xl font-semibold mb-3">
              Digital Product Delivery
            </h2>
            <p className="mb-4">
              Since all our products and services are delivered digitally, there
              are no physical items to ship. Upon purchase, you will receive an
              email with instructions on how to access your digital products and
              services. This typically includes access to your account, product
              download links, or any relevant activation codes.
            </p>
            <h2 className="text-xl font-semibold mb-3">Delivery Time</h2>
            <p className="mb-4">
              Digital products and services are available immediately after
              purchase. In the rare case of any delay, please check your
              spam/junk email folders or contact our support team for
              assistance.
            </p>
            <h2 className="text-xl font-semibold mb-3">Order Confirmation</h2>
            <p className="mb-4">
              After placing an order, you will receive an order confirmation
              email. This email will include details of your purchase and
              instructions on how to access your digital products. If you do not
              receive this email within a few minutes of your purchase, please
              contact our support team.
            </p>
            <h2 className="text-xl font-semibold mb-3">Refund Policy</h2>
            <p className="mb-4">
              Please review our{" "}
              <a href="/refunds/" className="text-blue-600 underline">
                Refund Policy
              </a>{" "}
              for detailed information regarding refunds and returns.
            </p>
            <h2 className="text-xl font-semibold mb-3">Contact Us</h2>
            <p className="mb-4">
              If you have any questions about our shipping and delivery process,
              feel free to reach out to our support team at{" "}
              <a
                href="mailto: business@needfinderai.com"
                className="text-blue-600 underline"
              >
                business@needfinderai.com
              </a>
              .
            </p>
          </div>
        </section>
      </div>
    </div>
  );
};

export default ShippingAndDelivery;
