import React, { useState, useEffect } from "react";
import axios from "./axiosConfig";
import { Zap, CreditCard, Menu, Bookmark, X, Bell, LogOut, Coffee, Radio, ChevronRight, Brain, Briefcase } from "lucide-react";
import InsightsTab from "./InsightsTab";
import SubscriptionsTab from "./SubscriptionsTab";
import CoworkingTab from "./CoworkingTab";
import ReconnectingWebSocket from "reconnecting-websocket";
import AIProductPlanner from "./AIProductRoadmapTab";
import BookmarksTab from "./BookmarksTab";
import DynamicBusinessPlanner from "./BusinessPlanTab";
import ProductShowcase from "./ProductShowCase";
import AIMarketing from "./AIMarketingTab";
import TopBanner from "./TopBanner";

const getWebSocketUrl = (path) => {
  const baseWsUrl =
    process.env.NODE_ENV === "production"
      ? "wss://beproduction.needfinderai.com" // Replace with your production WebSocket URL
      : "ws://localhost:8000"; // Local development WebSocket URL

  return `${baseWsUrl}${path}`;
};

const NeedFinderAI = () => {
  // const [activeTab, setActiveTab] = useState("insights");
  const [activeTab, setActiveTab] = useState("marketing");
  const [menuOpen, setMenuOpen] = useState(false);
  const [isPremium, setIsPremium] = useState(false);
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  const [userDetails, setUserDetails] = useState(null);
  const [notifications, setNotifications] = useState([]);
  const [showNotifications, setShowNotifications] = useState(false);
  const [notificationWs, setNotificationWs] = useState(null);

  const fetchUserDetails = async () => {
    try {
      const response = await axios.get("user-details/");
      setUserDetails(response.data);
      setIsPremium(response.data.current_plan !== "Free");
    } catch (error) {
      console.error("Failed to fetch user details:", error);
    }
  };

  // Fetch unread notifications on page load
  const fetchUnreadNotifications = async () => {
    try {
      const response = await axios.get("/notifications/unread/");
      setNotifications(response.data); // Load unread notifications
    } catch (error) {
      console.error("Failed to fetch unread notifications:", error);
    }
  };

  useEffect(() => {
    fetchUserDetails();
    fetchUnreadNotifications(); // Fetch unread notifications on page load
  }, []);

  // Connect to WebSocket for notifications
  useEffect(() => {
    if (userDetails) {
      const token = localStorage.getItem("access");
      const wsUrl = getWebSocketUrl(`/ws/notifications/${userDetails.id}/?token=${token}`);
      const ws = new ReconnectingWebSocket(wsUrl);

      ws.onmessage = (event) => {
        const data = JSON.parse(event.data);
        if (data.type === "notification") {
          setNotifications((prev) => [...prev, data]);
        }
      };

      ws.onclose = () => {
        setTimeout(() => {
          setNotificationWs(new ReconnectingWebSocket(wsUrl)); // Reconnect on close
        }, 1000); // Retry connection after 1 second
      };

      setNotificationWs(ws);

      return () => {
        if (ws) ws.close();
      };
    }
  }, [userDetails]);

  // Mark all notifications as read when closing the notification panel
  const handleNotificationPanelClose = async () => {
    try {
      await axios.post("/notifications/mark_all_as_read/");
      setNotifications((prevNotifications) => prevNotifications.map((notif) => ({ ...notif, read: true })));
    } catch (error) {
      console.error("Failed to mark notifications as read:", error);
    }
    setShowNotifications(false);
  };

  const handleLogout = () => {
    localStorage.clear();
    window.location.href = "/signin/";
  };

  const renderContent = () => {
    switch (activeTab) {
      case "insights":
        return (
          <InsightsTab
            userDetails={userDetails}
            setUserDetails={setUserDetails}
            setShowUpgradeModal={setShowUpgradeModal}
            showUpgradeModal={showUpgradeModal}
            // setActiveTab={setActiveTab}
            fetchUserDetails={fetchUserDetails}
          />
        );
      case "coworking":
        return <CoworkingTab userDetails={userDetails} />;
      case "bookmarks":
        return <BookmarksTab userDetails={userDetails} />;
      case "ai-product-planner":
        return <AIProductPlanner userDetails={userDetails} />;
      case "feedback":
        return <ProductShowcase userDetails={userDetails} setUserDetails={setUserDetails} />;
      case "subscriptions":
        return <SubscriptionsTab userDetails={userDetails} />;
      case "marketing":
        return <AIMarketing userDetails={userDetails} setUserDetails={setUserDetails} setActiveTab={setActiveTab} />;
      case "businessplan":
        return <DynamicBusinessPlanner userDetails={userDetails} setUserDetails={setUserDetails} />;
      default:
        return null;
    }
  };

  return (
    <div>
      <TopBanner />

      <div className="flex flex-col sm:flex-row min-h-screen bg-gray-50">
        {/* Header for small screens */}
        <div className="sm:hidden bg-white p-4 flex justify-between items-center shadow-md">
          <h1 className="text-2xl font-bold text-blue-600">NeedFinderAI</h1>
          <button onClick={() => setMenuOpen(!menuOpen)}>
            <Menu size={28} />
          </button>
        </div>

        {/* Sidebar */}
        <div className={`${menuOpen ? "block" : "hidden"} sm:block w-full sm:w-72 bg-white shadow-xl p-6 sm:h-screen sm:sticky sm:top-0`}>
          <h1 className="hidden sm:block text-3xl font-bold text-green-600 mb-12">NeedFinderAI</h1>

          <nav className="space-y-4">
            {[
              // { name: "insights", icon: Zap, label: "Market Research" },
              { name: "marketing", icon: Radio, label: "AI Marketing" },

              // { name: "coworking", icon: Coffee, label: "Coworking" },
              // { name: "feedback", icon: Briefcase, label: "Feedback" },

              // { name: "ai-product-planner", icon: Brain, label: "Plan" },

              // { name: "bookmarks", icon: Bookmark, label: "Bookmarks" },
              { name: "subscriptions", icon: CreditCard, label: "Subscription" },
            ].map(({ name, icon: Icon, label }) => (
              <button
                key={name}
                className={`flex items-center w-full px-6 py-4 rounded-xl transition duration-200 ${
                  activeTab === name ? "bg-blue-100 text-blue-600" : "text-gray-600 hover:bg-gray-100"
                }`}
                onClick={() => {
                  setActiveTab(name);
                  setMenuOpen(false);
                }}
              >
                <Icon className="mr-4" size={24} />
                <span className="text-lg font-medium">{label}</span>
              </button>
            ))}
          </nav>

          {/* Notifications button */}
          <div className="mt-8 relative">
            <button
              onClick={() => setShowNotifications(!showNotifications)}
              className="w-full flex items-center justify-center bg-gray-50 text-gray-700 py-3 rounded-lg font-semibold hover:bg-gray-200 transition-all shadow-md"
            >
              <Bell className="mr-2" size={24} />
              Notifications
              {notifications.filter((notif) => !notif.read).length > 0 && (
                <span className="ml-2 px-2 py-1 text-xs bg-red-500 text-white rounded-full">
                  {notifications.filter((notif) => !notif.read).length}
                </span>
              )}
            </button>

            {/* Notifications Panel */}
            {/* Notifications Panel */}
            {showNotifications && (
              <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
                {/* Notification Modal */}
                <div className="bg-white shadow-2xl rounded-xl max-w-md w-full mx-4 sm:mx-0 relative z-60">
                  {/* Close button in the top-right corner */}
                  <button
                    onClick={() => setShowNotifications(false)}
                    className="absolute top-4 right-4 text-gray-400 hover:text-gray-600 z-[1020]"
                  >
                    <X size={24} />
                  </button>

                  {/* Header */}
                  <div className="p-6 bg-blue-500 text-white text-lg font-bold flex justify-between items-center rounded-t-xl">
                    <span>Notifications</span>
                  </div>

                  {/* Notification List */}
                  <ul className="divide-y divide-gray-200 max-h-96 overflow-auto">
                    {notifications.length === 0 ? (
                      <li className="p-6 text-gray-500 text-center">No new notifications</li>
                    ) : (
                      notifications.map((notif, index) => (
                        <li key={index} className={`p-6 hover:bg-gray-100 transition ${notif.read ? "bg-gray-50" : ""}`}>
                          <span className="text-gray-800">{notif.content}</span>
                          <br />
                          <span className="text-xs text-gray-500">{new Date(notif.timestamp).toLocaleString()}</span>
                        </li>
                      ))
                    )}
                  </ul>

                  {/* Footer with "Mark all as read" */}
                  <button
                    onClick={handleNotificationPanelClose}
                    className="w-full p-4 bg-gray-100 text-center text-sm text-gray-700 hover:bg-gray-200 rounded-b-xl transition-all"
                  >
                    Mark all as read & Close
                  </button>
                </div>
              </div>
            )}
          </div>

          {/* Logout button */}
          <div className="mt-8">
            <button
              onClick={handleLogout}
              className="w-full flex items-center justify-center bg-red-600 text-white py-2 rounded-lg font-semibold hover:bg-red-700 transition-colors"
            >
              <LogOut className="mr-2" size={24} />
              Logout
            </button>
          </div>
        </div>

        {/* Main Content Area */}
        <div className="flex-1 overflow-auto p-6 sm:p-10">
          <main>{renderContent()}</main>
        </div>
      </div>
    </div>
  );
};

export default NeedFinderAI;
