import React from "react";
import Header from "../components/Header";

const styles = {
  fontFamily: "Inter, sans-serif",
};

const Terms = () => {
  return (
    <div className="font-inter antialiased bg-white text-gray-900 tracking-tight">
      <Header />
      <div className="grow p-4">
        <section className="bg-gradient-to-b from-gray-50 to-blue-100 min-h-screen">
          <div className="max-w-4xl mx-auto py-12">
            <h1 className="text-2xl font-bold mb-4">Terms of Service</h1>
            <p className="mb-2">
              <strong>Last updated on August 3, 2024.</strong>
            </p>
            <p className="mb-4">
              This website is operated by Ingena Technology Private Limited.
              Throughout the site, the terms "we", "us", and "our" refer to
              Ingena Technology Private Limited. By accessing or using our
              website, you agree to comply with and be bound by the following
              terms and conditions.
            </p>

            <h2 className="text-xl font-semibold mb-3">Purchasing</h2>
            <p className="mb-4">
              If you choose to purchase a subscription or paid plan on our
              website, you will be required to provide certain information,
              including your payment details and personal data. By proceeding
              with the purchase, you consent to the processing of your personal
              data for the purpose of fulfilling your order.
            </p>

            <h2 className="text-xl font-semibold mb-3">Services</h2>
            <p className="mb-4">
              As a subscriber to Ingena Technology Private Limited services, you
              are entitled to support for any issues and to contact our team as
              needed. We strive to respond to all requests promptly. You agree
              to receive service-related notifications and updates at the email
              address provided. You can opt out of newsletters and promotional
              communications but not from essential service notifications.
            </p>

            <h2 className="text-xl font-semibold mb-3">Accounts</h2>
            <p className="mb-4">
              Accounts can be accessed by multiple users within your team.
              However, if we detect any suspicious activity, we reserve the
              right to suspend or terminate your account pending further review.
            </p>

            <h2 className="text-xl font-semibold mb-3">Termination</h2>
            <p className="mb-4">
              We may terminate or suspend your access to our services at any
              time, without prior notice or liability, for any reason, including
              if you breach these Terms. Provisions that by their nature should
              survive termination shall survive, including but not limited to
              ownership clauses, warranty disclaimers, indemnity, and liability
              limitations.
            </p>

            <h2 className="text-xl font-semibold mb-3">Taxes</h2>
            <p className="mb-4">
              Prices for our services do not include taxes. You are responsible
              for any applicable taxes or fees associated with your activities.
            </p>

            <h2 className="text-xl font-semibold mb-3">Refunds</h2>
            <p className="mb-4">
              We do not offer refunds. If you are dissatisfied with our service,
              please contact us at business@needfinderai.com, and we will do our
              best to address your concerns.
            </p>

            <h2 className="text-xl font-semibold mb-3">Links to Other Sites</h2>
            <p className="mb-4">
              Our website may contain links to third-party sites. We have no
              control over the content or policies of these sites and are not
              responsible for their terms or privacy practices.
            </p>

            <h2 className="text-xl font-semibold mb-3">Modifications</h2>
            <p className="mb-4">
              We reserve the right to modify these Terms of Service at any time.
              Changes will take effect immediately upon posting on our website.
              Please check this page periodically for updates. We will notify
              you of significant changes.
            </p>

            <h2 className="text-xl font-semibold mb-3">Contact</h2>
            <p className="mb-4">
              For any questions or feedback regarding these Terms of Service,
              please contact us at business@needfinderai.com.
            </p>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Terms;
