// import React, { useState, useEffect } from "react";
// import { Bookmark, ThumbsUp, Zap, Users, TrendingUp, ChevronDown, ChevronUp, Send, Brain, Plus, X, Search } from "lucide-react";
// import axios from "./axiosConfig";

// const StreamlinedAIProductPlanner = ({ userDetails }) => {
//   const [bookmarks, setBookmarks] = useState([]);
//   const [roadmap, setRoadmap] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [expandedCards, setExpandedCards] = useState([]);
//   const [selectedBookmark, setSelectedBookmark] = useState(null);
//   const [aiSuggestion, setAiSuggestion] = useState(null);
//   const [monitoredKeyword] = useState("AI-powered productivity tools"); // Mock monitored keyword

//   useEffect(() => {
//     fetchBookmarks();
//   }, []);

//   const fetchBookmarks = async () => {
//     try {
//       const response = await axios.get("/list-bookmarks/");
//       setBookmarks(response.data);
//       setLoading(false);
//     } catch (error) {
//       console.error("Error fetching bookmarks:", error);
//       setLoading(false);
//     }
//   };

//   const toggleExpand = (index) => {
//     setExpandedCards((prev) => (prev.includes(index) ? prev.filter((i) => i !== index) : [...prev, index]));
//   };

//   const handleToggleLike = async (painpointId) => {
//     try {
//       await axios.post(`/toggle-like/${painpointId}/`);
//       setBookmarks(bookmarks.map((bookmark) => (bookmark.id === painpointId ? { ...bookmark, is_liked: !bookmark.is_liked } : bookmark)));
//     } catch (error) {
//       console.error("Error toggling like:", error);
//     }
//   };

//   const handleRemoveBookmark = async (painpointId) => {
//     try {
//       await axios.post(`/toggle-bookmark/${painpointId}/`);
//       setBookmarks(bookmarks.filter((bookmark) => bookmark.id !== painpointId));
//     } catch (error) {
//       console.error("Error removing bookmark:", error);
//     }
//   };

//   const selectBookmarkForPlanning = (bookmark) => {
//     setSelectedBookmark(bookmark);
//     // Simulate AI processing
//     setTimeout(() => {
//       setAiSuggestion({
//         title: `Implement solution for "${bookmark.details.pain_point}"`,
//         description: `Based on the bookmarked insight and our monitored keyword "${monitoredKeyword}", we recommend addressing this user need. This could potentially increase user satisfaction and market share.`,
//         priority: ["Low", "Medium", "High"][Math.floor(Math.random() * 3)],
//         effort: ["Easy", "Moderate", "Challenging"][Math.floor(Math.random() * 3)],
//         impact: ["Small", "Moderate", "Large"][Math.floor(Math.random() * 3)],
//       });
//     }, 1500);
//   };

//   const addToRoadmap = () => {
//     if (aiSuggestion) {
//       setRoadmap([...roadmap, { ...aiSuggestion, id: Date.now() }]);
//       setSelectedBookmark(null);
//       setAiSuggestion(null);
//     }
//   };

//   const removeFromRoadmap = (id) => {
//     setRoadmap(roadmap.filter((item) => item.id !== id));
//   };

//   if (loading) {
//     return (
//       <div className="flex justify-center items-center h-64">
//         <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-blue-500"></div>
//       </div>
//     );
//   }

//   return (
//     <div className="flex flex-col lg:flex-row min-h-screen bg-gray-100">
//       <div className="w-full lg:w-1/2 p-6 bg-white overflow-auto">
//         <div className="bg-blue-100 p-4 rounded-lg mb-8 flex items-center">
//           <Search className="text-blue-500 mr-2" size={24} />
//           <div>
//             <h2 className="text-lg font-semibold text-blue-800">Currently Monitoring:</h2>
//             <p className="text-blue-600">{monitoredKeyword}</p>
//           </div>
//         </div>

//         <h2 className="text-3xl font-bold text-gray-900 mb-8">Your Bookmarked Insights</h2>
//         {bookmarks.length === 0 ? (
//           <div className="text-center py-12">
//             <Bookmark className="mx-auto text-gray-400 mb-4" size={48} />
//             <p className="text-gray-600 text-lg">You haven't bookmarked any insights yet.</p>
//           </div>
//         ) : (
//           <div className="space-y-8">
//             {bookmarks.map((bookmark, index) => (
//               <div
//                 key={bookmark.id}
//                 className="bg-white rounded-2xl shadow-lg overflow-hidden transition-all duration-300 hover:shadow-xl border border-gray-100"
//               >
//                 <div className="p-6">
//                   <div className="flex justify-between items-start mb-4">
//                     <h3 className="text-xl font-bold text-gray-900 leading-tight pr-4">{bookmark.details.pain_point}</h3>
//                     <div className="flex space-x-2">
//                       <button
//                         className={`p-2 rounded-full ${
//                           bookmark.is_liked ? "bg-green-100 text-green-600" : "bg-gray-100 text-gray-600"
//                         } hover:bg-green-200 transition-colors duration-200`}
//                         onClick={() => handleToggleLike(bookmark.id)}
//                       >
//                         <ThumbsUp size={20} />
//                       </button>
//                       <button
//                         className="p-2 rounded-full bg-red-100 text-red-600 hover:bg-red-200 transition-colors duration-200"
//                         onClick={() => handleRemoveBookmark(bookmark.id)}
//                       >
//                         <X size={20} />
//                       </button>
//                     </div>
//                   </div>

//                   <p className="text-gray-700 mb-4">{bookmark.details.problem_synopsis}</p>

//                   <div className="grid grid-cols-3 gap-4 mb-4">
//                     <div className="bg-blue-50 p-3 rounded-xl text-center">
//                       <Zap className="text-blue-500 text-2xl mx-auto mb-1" />
//                       <span className="block text-sm font-medium text-gray-600">AI Confidence</span>
//                       <span className="block text-lg font-bold text-gray-900">{bookmark.details.mentions}</span>
//                     </div>
//                     <div className="bg-green-50 p-3 rounded-xl text-center">
//                       <Users className="text-green-500 text-2xl mx-auto mb-1" />
//                       <span className="block text-sm font-medium text-gray-600">Users Affected</span>
//                       <span className="block text-lg font-bold text-gray-900">{bookmark.details.users_affected}</span>
//                     </div>
//                     <div className="bg-purple-50 p-3 rounded-xl text-center">
//                       <TrendingUp className="text-purple-500 text-2xl mx-auto mb-1" />
//                       <span className="block text-sm font-medium text-gray-600">Frequency</span>
//                       <span className="block text-lg font-bold text-gray-900">{bookmark.details.frequency}</span>
//                     </div>
//                   </div>

//                   <button
//                     onClick={() => selectBookmarkForPlanning(bookmark)}
//                     className="w-full bg-blue-600 text-white py-2 px-4 rounded-xl hover:bg-blue-700 transition-all duration-200 flex items-center justify-center shadow-md text-lg font-semibold"
//                   >
//                     <Brain size={24} className="mr-2" /> Get AI Suggestion
//                   </button>
//                 </div>
//               </div>
//             ))}
//           </div>
//         )}
//       </div>

//       <div className="w-full lg:w-1/2 p-6 bg-gray-50 overflow-auto">
//         <div className="bg-white rounded-lg p-6 mb-6">
//           <h2 className="text-2xl font-bold mb-4 flex items-center">
//             <Brain className="mr-2 text-purple-500" />
//             AI Suggestion
//           </h2>
//           {selectedBookmark ? (
//             aiSuggestion ? (
//               <div>
//                 <h3 className="text-xl font-semibold mb-2">{aiSuggestion.title}</h3>
//                 <p className="mb-4">{aiSuggestion.description}</p>
//                 <div className="flex flex-wrap gap-2 mb-4">
//                   <span className="px-2 py-1 bg-blue-100 text-blue-800 rounded-full text-sm">Priority: {aiSuggestion.priority}</span>
//                   <span className="px-2 py-1 bg-green-100 text-green-800 rounded-full text-sm">Effort: {aiSuggestion.effort}</span>
//                   <span className="px-2 py-1 bg-yellow-100 text-yellow-800 rounded-full text-sm">Impact: {aiSuggestion.impact}</span>
//                 </div>
//                 <button
//                   className="bg-purple-600 text-white px-4 py-2 rounded-lg hover:bg-purple-700 transition-colors"
//                   onClick={addToRoadmap}
//                 >
//                   Add to Roadmap
//                 </button>
//               </div>
//             ) : (
//               <div className="flex items-center justify-center h-32">
//                 <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-purple-500"></div>
//               </div>
//             )
//           ) : (
//             <p className="text-gray-500">Select a bookmarked insight to see AI suggestions</p>
//           )}
//         </div>

//         <div className="bg-white rounded-lg p-6">
//           <h2 className="text-2xl font-bold mb-4 flex items-center">
//             <TrendingUp className="mr-2 text-green-500" />
//             AI-Generated Roadmap
//           </h2>
//           {roadmap.length === 0 ? (
//             <p className="text-gray-500">Your roadmap is empty. Add suggestions to start building your roadmap.</p>
//           ) : (
//             <div className="space-y-4">
//               {roadmap.map((item) => (
//                 <div key={item.id} className="bg-gray-50 p-4 rounded-lg flex justify-between items-start">
//                   <div>
//                     <h3 className="font-semibold">{item.title}</h3>
//                     <p className="text-sm text-gray-600 mb-2">{item.description}</p>
//                     <div className="flex flex-wrap gap-2">
//                       <span className="px-2 py-1 bg-blue-100 text-blue-800 rounded-full text-xs">Priority: {item.priority}</span>
//                       <span className="px-2 py-1 bg-green-100 text-green-800 rounded-full text-xs">Effort: {item.effort}</span>
//                       <span className="px-2 py-1 bg-yellow-100 text-yellow-800 rounded-full text-xs">Impact: {item.impact}</span>
//                     </div>
//                   </div>
//                   <button className="text-red-500 hover:text-red-700" onClick={() => removeFromRoadmap(item.id)}>
//                     <X size={20} />
//                   </button>
//                 </div>
//               ))}
//             </div>
//           )}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default StreamlinedAIProductPlanner;

// import React, { useState, useEffect } from "react";
// import { Bookmark, ThumbsUp, Zap, Users, TrendingUp, ChevronDown, ChevronUp, Send, Brain, Plus, X, Edit, RefreshCw } from "lucide-react";
// import axios from "./axiosConfig";

// const RefinedUserCentricAIProductPlanner = ({ userDetails }) => {
//   const [bookmarks, setBookmarks] = useState([]);
//   const [roadmap, setRoadmap] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [expandedCards, setExpandedCards] = useState([]);
//   const [productDetails, setProductDetails] = useState({
//     name: "",
//     description: "",
//     aim: "increase_revenue", // Default aim
//   });
//   const [isEditingProduct, setIsEditingProduct] = useState(true);

//   useEffect(() => {
//     fetchBookmarks();
//   }, []);

//   const fetchBookmarks = async () => {
//     try {
//       const response = await axios.get("/list-bookmarks/");
//       setBookmarks(response.data);
//       setLoading(false);
//     } catch (error) {
//       console.error("Error fetching bookmarks:", error);
//       setLoading(false);
//     }
//   };

//   const toggleExpand = (index) => {
//     setExpandedCards((prev) => (prev.includes(index) ? prev.filter((i) => i !== index) : [...prev, index]));
//   };

//   const handleToggleLike = async (painpointId) => {
//     try {
//       await axios.post(`/toggle-like/${painpointId}/`);
//       setBookmarks(bookmarks.map((bookmark) => (bookmark.id === painpointId ? { ...bookmark, is_liked: !bookmark.is_liked } : bookmark)));
//     } catch (error) {
//       console.error("Error toggling like:", error);
//     }
//   };

//   const handleRemoveBookmark = async (painpointId) => {
//     try {
//       await axios.post(`/toggle-bookmark/${painpointId}/`);
//       setBookmarks(bookmarks.filter((bookmark) => bookmark.id !== painpointId));
//     } catch (error) {
//       console.error("Error removing bookmark:", error);
//     }
//   };

//   const addToRoadmap = (bookmark) => {
//     const newRoadmapItem = {
//       id: Date.now(),
//       title: `Implement solution for "${bookmark.details.pain_point}"`,
//       description: `Address the user need: ${bookmark.details.problem_synopsis}`,
//       priority: ["Low", "Medium", "High"][Math.floor(Math.random() * 3)],
//       effort: ["Easy", "Moderate", "Challenging"][Math.floor(Math.random() * 3)],
//       impact: ["Small", "Moderate", "Large"][Math.floor(Math.random() * 3)],
//     };
//     setRoadmap([...roadmap, newRoadmapItem]);
//   };

//   const removeFromRoadmap = (id) => {
//     setRoadmap(roadmap.filter((item) => item.id !== id));
//   };

//   const sortRoadmap = () => {
//     const sortedRoadmap = [...roadmap].sort((a, b) => {
//       const priorities = { High: 3, Medium: 2, Low: 1 };
//       const impacts = { Large: 3, Moderate: 2, Small: 1 };

//       let scoreA = priorities[a.priority] * impacts[a.impact];
//       let scoreB = priorities[b.priority] * impacts[b.impact];

//       // Adjust scores based on product aim
//       if (productDetails.aim === "increase_revenue" && a.impact === "Large") scoreA *= 1.5;
//       if (productDetails.aim === "increase_revenue" && b.impact === "Large") scoreB *= 1.5;
//       if (productDetails.aim === "improve_engagement" && a.priority === "High") scoreA *= 1.5;
//       if (productDetails.aim === "improve_engagement" && b.priority === "High") scoreB *= 1.5;
//       if (productDetails.aim === "enhance_retention" && a.effort === "Easy") scoreA *= 1.5;
//       if (productDetails.aim === "enhance_retention" && b.effort === "Easy") scoreB *= 1.5;

//       return scoreB - scoreA;
//     });

//     setRoadmap(sortedRoadmap);
//   };

//   if (loading) {
//     return (
//       <div className="flex justify-center items-center h-64">
//         <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-blue-500"></div>
//       </div>
//     );
//   }

//   return (
//     <div className="flex flex-col lg:flex-row min-h-screen bg-gray-100">
//       <div className="w-full lg:w-1/2 p-6 bg-white overflow-auto">
//         <div className="bg-blue-100 p-4 rounded-lg mb-8">
//           {isEditingProduct ? (
//             <div className="space-y-4">
//               <input
//                 type="text"
//                 placeholder="Product Name"
//                 value={productDetails.name}
//                 onChange={(e) => setProductDetails({ ...productDetails, name: e.target.value })}
//                 className="w-full p-2 border rounded"
//               />
//               <textarea
//                 placeholder="Product Description"
//                 value={productDetails.description}
//                 onChange={(e) => setProductDetails({ ...productDetails, description: e.target.value })}
//                 className="w-full p-2 border rounded"
//               />
//               <select
//                 value={productDetails.aim}
//                 onChange={(e) => setProductDetails({ ...productDetails, aim: e.target.value })}
//                 className="w-full p-2 border rounded"
//               >
//                 <option value="increase_revenue">Increase Revenue</option>
//                 <option value="improve_engagement">Improve Engagement</option>
//                 <option value="enhance_retention">Enhance User Retention</option>
//                 <option value="expand_user_base">Expand User Base</option>
//               </select>
//               <button onClick={() => setIsEditingProduct(false)} className="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700">
//                 Save Product Details
//               </button>
//             </div>
//           ) : (
//             <div>
//               <h2 className="text-lg font-semibold text-blue-800">Your Product: {productDetails.name}</h2>
//               <p className="text-blue-600 mb-2">{productDetails.description}</p>
//               <p className="text-blue-600">Aim: {productDetails.aim.replace("_", " ")}</p>
//               <button onClick={() => setIsEditingProduct(true)} className="mt-2 text-blue-600 hover:text-blue-800">
//                 <Edit size={16} className="inline mr-1" /> Edit
//               </button>
//             </div>
//           )}
//         </div>

//         <h2 className="text-3xl font-bold text-gray-900 mb-8">Your Bookmarked Insights</h2>
//         {bookmarks.length === 0 ? (
//           <div className="text-center py-12">
//             <Bookmark className="mx-auto text-gray-400 mb-4" size={48} />
//             <p className="text-gray-600 text-lg">You haven't bookmarked any insights yet.</p>
//           </div>
//         ) : (
//           <div className="space-y-8">
//             {bookmarks.map((bookmark, index) => (
//               <div
//                 key={bookmark.id}
//                 className="bg-white rounded-2xl shadow-lg overflow-hidden transition-all duration-300 hover:shadow-xl border border-gray-100"
//               >
//                 <div className="p-6">
//                   <div className="flex justify-between items-start mb-4">
//                     <h3 className="text-xl font-bold text-gray-900 leading-tight pr-4">{bookmark.details.pain_point}</h3>
//                     <div className="flex space-x-2">
//                       <button
//                         className={`p-2 rounded-full ${
//                           bookmark.is_liked ? "bg-green-100 text-green-600" : "bg-gray-100 text-gray-600"
//                         } hover:bg-green-200 transition-colors duration-200`}
//                         onClick={() => handleToggleLike(bookmark.id)}
//                       >
//                         <ThumbsUp size={20} />
//                       </button>
//                       <button
//                         className="p-2 rounded-full bg-red-100 text-red-600 hover:bg-red-200 transition-colors duration-200"
//                         onClick={() => handleRemoveBookmark(bookmark.id)}
//                       >
//                         <X size={20} />
//                       </button>
//                     </div>
//                   </div>

//                   <p className="text-gray-700 mb-4">{bookmark.details.problem_synopsis}</p>

//                   <div className="grid grid-cols-3 gap-4 mb-4">
//                     <div className="bg-blue-50 p-3 rounded-xl text-center">
//                       <Zap className="text-blue-500 text-2xl mx-auto mb-1" />
//                       <span className="block text-sm font-medium text-gray-600">AI Confidence</span>
//                       <span className="block text-lg font-bold text-gray-900">{bookmark.details.mentions}</span>
//                     </div>
//                     <div className="bg-green-50 p-3 rounded-xl text-center">
//                       <Users className="text-green-500 text-2xl mx-auto mb-1" />
//                       <span className="block text-sm font-medium text-gray-600">Users Affected</span>
//                       <span className="block text-lg font-bold text-gray-900">{bookmark.details.users_affected}</span>
//                     </div>
//                     <div className="bg-purple-50 p-3 rounded-xl text-center">
//                       <TrendingUp className="text-purple-500 text-2xl mx-auto mb-1" />
//                       <span className="block text-sm font-medium text-gray-600">Frequency</span>
//                       <span className="block text-lg font-bold text-gray-900">{bookmark.details.frequency}</span>
//                     </div>
//                   </div>

//                   <button
//                     onClick={() => addToRoadmap(bookmark)}
//                     className="w-full bg-blue-600 text-white py-2 px-4 rounded-xl hover:bg-blue-700 transition-all duration-200 flex items-center justify-center shadow-md text-lg font-semibold"
//                   >
//                     <Plus size={24} className="mr-2" /> Add to Product Roadmap
//                   </button>
//                 </div>
//               </div>
//             ))}
//           </div>
//         )}
//       </div>

//       <div className="w-full lg:w-1/2 p-6 bg-gray-50 overflow-auto">
//         <div className="bg-white rounded-lg p-6">
//           <div className="flex justify-between items-center mb-4">
//             <h2 className="text-2xl font-bold flex items-center">
//               <TrendingUp className="mr-2 text-green-500" />
//               AI-Sorted Roadmap
//             </h2>
//             <button
//               onClick={sortRoadmap}
//               className="bg-green-500 text-white p-2 rounded-full hover:bg-green-600 transition-colors"
//               title="Re-sort Roadmap"
//             >
//               <RefreshCw size={20} />
//             </button>
//           </div>
//           {roadmap.length === 0 ? (
//             <p className="text-gray-500">Your roadmap is empty. Add suggestions from bookmarks to start building your roadmap.</p>
//           ) : (
//             <div className="space-y-4">
//               {roadmap.map((item, index) => (
//                 <div key={item.id} className="bg-gray-50 p-4 rounded-lg flex justify-between items-start">
//                   <div>
//                     <h3 className="font-semibold">{item.title}</h3>
//                     <p className="text-sm text-gray-600 mb-2">{item.description}</p>
//                     <div className="flex flex-wrap gap-2">
//                       <span className="px-2 py-1 bg-blue-100 text-blue-800 rounded-full text-xs">Priority: {item.priority}</span>
//                       <span className="px-2 py-1 bg-green-100 text-green-800 rounded-full text-xs">Effort: {item.effort}</span>
//                       <span className="px-2 py-1 bg-yellow-100 text-yellow-800 rounded-full text-xs">Impact: {item.impact}</span>
//                     </div>
//                   </div>
//                   <button className="text-red-500 hover:text-red-700" onClick={() => removeFromRoadmap(item.id)}>
//                     <X size={20} />
//                   </button>
//                 </div>
//               ))}
//             </div>
//           )}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default RefinedUserCentricAIProductPlanner;

import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {
  Bookmark,
  ThumbsUp,
  Zap,
  Users,
  TrendingUp,
  ChevronDown,
  ChevronUp,
  Send,
  Brain,
  Plus,
  X,
  Edit,
  RefreshCw,
  Info,
  Sparkles,
} from "lucide-react";
import axios from "./axiosConfig";

const AIProductPlanner = ({ userDetails }) => {
  const [bookmarks, setBookmarks] = useState([]);
  const [roadmap, setRoadmap] = useState([]);
  const [loading, setLoading] = useState(true);
  //   const [productDetails, setProductDetails] = useState({
  //     name: "",
  //     description: "",
  //     aim: "increase_revenue",
  //   });
  const [productDetails, setProductDetails] = useState({
    name: "FitPro",
    description: "A fitness tracking app ",
    aim: "improve_engagement",
  });
  const [isEditingProduct, setIsEditingProduct] = useState(false);
  const [isSorting, setIsSorting] = useState(false);

  useEffect(() => {
    fetchBookmarks();
  }, []);

  // const fetchBookmarks = async () => {
  //   try {
  //     const response = await axios.get("/list-bookmarks/");
  //     setBookmarks(response.data);
  //     setLoading(false);
  //   } catch (error) {
  //     console.error("Error fetching bookmarks:", error);
  //     setLoading(false);
  //   }
  // };

  const fetchBookmarks = async () => {
    try {
      const response = await axios.get("/list-bookmarks/");
      if (response.data.length === 0) {
        // If no bookmarks are returned, add mock data
        setBookmarks([
          {
            id: 1,
            details: {
              pain_point: "Subscription Fatigue",
              problem_synopsis:
                "Users are overwhelmed by the multiple subscription-based services for features, leading to dissatisfaction and perceived nickel-and-diming.",
              mentions: 8,
              users_affected: 10000,
              frequency: "Weekly",
              is_liked: false,
            },
          },
          {
            id: 2,
            details: {
              pain_point: "Range Anxiety",
              problem_synopsis:
                "Persistent fear among users about running out of battery before reaching a charging station, limiting the perceived usability of EVs.",
              mentions: 18,
              users_affected: 22000,
              frequency: "Weekly",
              is_liked: false,
            },
          },
          {
            id: 3,
            details: {
              pain_point: "Integration of Renewable Energy Sources",
              problem_synopsis:
                "Difficulty in integrating solar and other renewable energy sources for home charging, limiting the environmental benefits of EV ownership.",
              mentions: 12,
              users_affected: 14000,
              frequency: "Monthly",
              is_liked: false,
            },
          },
          // Add more mock bookmarks if needed
        ]);
      } else {
        setBookmarks(response.data);
      }
      setLoading(false);
    } catch (error) {
      console.error("Error fetching bookmarks:", error);
      setLoading(false);
    }
  };

  const handleToggleLike = async (painpointId) => {
    try {
      await axios.post(`/toggle-like/${painpointId}/`);
      setBookmarks(bookmarks.map((bookmark) => (bookmark.id === painpointId ? { ...bookmark, is_liked: !bookmark.is_liked } : bookmark)));
    } catch (error) {
      console.error("Error toggling like:", error);
    }
  };

  const handleRemoveBookmark = async (painpointId) => {
    try {
      await axios.post(`/toggle-bookmark/${painpointId}/`);
      setBookmarks(bookmarks.filter((bookmark) => bookmark.id !== painpointId));
    } catch (error) {
      console.error("Error removing bookmark:", error);
    }
  };

  const addToRoadmap = (bookmark) => {
    const newRoadmapItem = {
      id: Date.now(),
      title: `Implement solution for "${bookmark.details.pain_point}"`,
      description: `Address the user need: ${bookmark.details.problem_synopsis}`,
      priority: ["Low", "Medium", "High"][Math.floor(Math.random() * 3)],
      effort: ["Easy", "Moderate", "Challenging"][Math.floor(Math.random() * 3)],
      impact: ["Small", "Moderate", "Large"][Math.floor(Math.random() * 3)],
    };
    setRoadmap([...roadmap, newRoadmapItem]);
    handleAISort([...roadmap, newRoadmapItem]);
  };

  const removeFromRoadmap = (id) => {
    const updatedRoadmap = roadmap.filter((item) => item.id !== id);
    setRoadmap(updatedRoadmap);
    handleAISort(updatedRoadmap);
  };

  const handleAISort = (currentRoadmap = roadmap) => {
    setIsSorting(true);
    const priorities = { High: 3, Medium: 2, Low: 1 };
    const impacts = { Large: 3, Moderate: 2, Small: 1 };
    const efforts = { Easy: 3, Moderate: 2, Challenging: 1 };

    setTimeout(() => {
      const sortedRoadmap = currentRoadmap
        .map((item) => {
          let score = priorities[item.priority] * impacts[item.impact] * efforts[item.effort];

          if (productDetails.aim === "increase_revenue" && item.impact === "Large") score *= 1.5;
          if (productDetails.aim === "improve_engagement" && item.priority === "High") score *= 1.5;
          if (productDetails.aim === "enhance_retention" && item.effort === "Easy") score *= 1.5;
          if (productDetails.aim === "expand_user_base" && (item.impact === "Large" || item.priority === "High")) score *= 1.3;

          return { ...item, score };
        })
        .sort((a, b) => b.score - a.score)
        .map((item, index) => ({
          ...item,
          rankExplanation: `Ranked #${index + 1} due to ${item.priority} priority, ${item.impact} impact, and ${item.effort} effort.${
            item.score > priorities[item.priority] * impacts[item.impact] * efforts[item.effort]
              ? ` Boosted importance due to alignment with ${productDetails.aim.replace("_", " ")} goal.`
              : ""
          }`,
        }));

      setRoadmap(sortedRoadmap);
      setIsSorting(false);
    }, 1500);
  };

  const onDragEnd = (result) => {
    if (!result.destination) return;

    const items = Array.from(roadmap);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setRoadmap(items);
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  return (
    <div className="flex flex-col h-screen bg-gray-50">
      <header className="relative bg-white shadow-sm p-4 sticky top-0 z-10">
        <ProductSummary productDetails={productDetails} onEdit={() => setIsEditingProduct(true)} />
      </header>

      <div className="flex-grow overflow-hidden flex flex-col lg:flex-row">
        <section className="w-full lg:w-1/2 p-4 overflow-auto">
          <h2 className="text-2xl font-bold mb-4">Bookmarked Insights</h2>
          <BookmarkList
            bookmarks={bookmarks}
            onAddToRoadmap={addToRoadmap}
            onToggleLike={handleToggleLike}
            onRemoveBookmark={handleRemoveBookmark}
          />
        </section>

        <section className="w-full lg:w-1/2 p-4 overflow-auto bg-gray-100">
          <div className="flex justify-between items-center mb-4">
            <ComingSoonBanner />
            <h2 className="text-2xl font-bold">AI-Driven Roadmap</h2>
            <button
              onClick={() => handleAISort()}
              className="bg-blue-600 text-white px-4 py-2 rounded-full flex items-center"
              disabled={isSorting}
            >
              {isSorting ? <RefreshCw className="animate-spin mr-2" size={20} /> : <Brain className="mr-2" size={20} />}
              {isSorting ? "Sorting..." : "AI Sort"}
            </button>
          </div>
          <RoadmapBoard roadmap={roadmap} onRemoveItem={removeFromRoadmap} onDragEnd={onDragEnd} />
        </section>
      </div>

      <AnimatePresence>
        {isEditingProduct && (
          <ProductEditModal
            productDetails={productDetails}
            onSave={(newDetails) => {
              setProductDetails(newDetails);
              setIsEditingProduct(false);
            }}
            onClose={() => setIsEditingProduct(false)}
          />
        )}
      </AnimatePresence>
    </div>
  );
};

const ProductSummary = ({ productDetails, onEdit }) => (
  <div className="flex justify-between items-center">
    <div>
      <h1 className="text-xl font-bold">{productDetails.name || "Unnamed Product"}</h1>
      <p className="text-sm text-gray-600">{productDetails.aim.replace("_", " ")}</p>
    </div>
    <button onClick={onEdit} className="text-blue-600 hover:text-blue-800">
      <Edit size={20} />
    </button>
  </div>
);

const ComingSoonBanner = () => (
  <motion.div
    initial={{ opacity: 0, y: -20 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.5 }}
    className="absolute top-0 right-0 mt-4 mr-4 bg-gradient-to-r from-blue-500 to-purple-600 text-white px-4 py-2 rounded-full shadow-lg flex items-center space-x-2 z-20"
  >
    <Sparkles size={16} />
    <span className="font-semibold text-sm">Coming Soon Feature</span>
  </motion.div>
);

const BookmarkList = ({ bookmarks, onAddToRoadmap, onToggleLike, onRemoveBookmark }) => (
  <div className="space-y-4">
    {bookmarks.map((bookmark) => (
      <motion.div
        key={bookmark.id}
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -20 }}
        className="bg-white rounded-lg shadow p-4"
      >
        <div className="flex justify-between items-start mb-2">
          <h3 className="text-lg font-semibold">{bookmark.details.pain_point}</h3>
          <div className="flex space-x-2">
            <button
              onClick={() => onToggleLike(bookmark.id)}
              className={`p-1 rounded-full ${bookmark.is_liked ? "bg-red-100 text-red-600" : "bg-gray-100 text-gray-600"}`}
            >
              <ThumbsUp size={16} />
            </button>
            <button onClick={() => onRemoveBookmark(bookmark.id)} className="p-1 rounded-full bg-gray-100 text-gray-600">
              <X size={16} />
            </button>
          </div>
        </div>
        <p className="text-sm text-gray-600 mb-2">{bookmark.details.problem_synopsis}</p>
        <div className="flex justify-between text-sm mb-2">
          <span className="flex items-center">
            <Zap size={14} className="mr-1 text-yellow-500" />
            {bookmark.details.mentions}
          </span>
          <span className="flex items-center">
            <Users size={14} className="mr-1 text-blue-500" />
            {bookmark.details.users_affected}
          </span>
          <span className="flex items-center">
            <TrendingUp size={14} className="mr-1 text-green-500" />
            {bookmark.details.frequency}
          </span>
        </div>
        <button
          onClick={() => onAddToRoadmap(bookmark)}
          className="w-full bg-blue-600 text-white py-2 rounded-lg hover:bg-blue-700 transition-colors"
        >
          <Plus size={14} className="inline mr-2" />
          Add to Roadmap
        </button>
      </motion.div>
    ))}
  </div>
);

const RoadmapBoard = ({ roadmap, onRemoveItem, onDragEnd }) => (
  <DragDropContext onDragEnd={onDragEnd}>
    <Droppable droppableId="roadmap">
      {(provided) => (
        <div {...provided.droppableProps} ref={provided.innerRef} className="space-y-4">
          {roadmap.map((item, index) => (
            <Draggable key={item.id} draggableId={item.id.toString()} index={index}>
              {(provided) => (
                <div
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                  className="bg-white rounded-lg shadow p-4"
                >
                  <div className="flex justify-between items-start mb-2">
                    <h3 className="text-lg font-semibold">{item.title}</h3>
                    <button onClick={() => onRemoveItem(item.id)} className="text-red-500 hover:text-red-700">
                      <X size={16} />
                    </button>
                  </div>
                  <p className="text-sm text-gray-600 mb-2">{item.description}</p>
                  <div className="flex space-x-2 mb-2">
                    <span className="px-2 py-1 bg-blue-100 text-blue-800 rounded-full text-xs">Priority: {item.priority}</span>
                    <span className="px-2 py-1 bg-green-100 text-green-800 rounded-full text-xs">Effort: {item.effort}</span>
                    <span className="px-2 py-1 bg-yellow-100 text-yellow-800 rounded-full text-xs">Impact: {item.impact}</span>
                  </div>
                  <div className="text-xs text-gray-500 italic flex items-center">
                    <Info size={12} className="mr-1" />
                    {item.rankExplanation}
                  </div>
                </div>
              )}
            </Draggable>
          ))}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  </DragDropContext>
);

const ProductEditModal = ({ productDetails, onSave, onClose }) => (
  <motion.div
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    exit={{ opacity: 0 }}
    className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
  >
    <motion.div
      initial={{ y: 50, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      exit={{ y: 50, opacity: 0 }}
      className="bg-white rounded-lg p-6 w-full max-w-md"
    >
      <h2 className="text-2xl font-bold mb-4">Edit Product Details</h2>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          onSave(productDetails);
        }}
      >
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700 mb-1">Product Name</label>
          <input
            type="text"
            value={productDetails.name}
            onChange={(e) => onSave({ ...productDetails, name: e.target.value })}
            className="w-full p-2 border rounded"
          />
        </div>
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700 mb-1">Description</label>
          <textarea
            value={productDetails.description}
            onChange={(e) => onSave({ ...productDetails, description: e.target.value })}
            className="w-full p-2 border rounded"
            rows="3"
          />
        </div>
        <div className="mb-6">
          <label className="block text-sm font-medium text-gray-700 mb-1">Primary Aim</label>
          <select
            value={productDetails.aim}
            onChange={(e) => onSave({ ...productDetails, aim: e.target.value })}
            className="w-full p-2 border rounded"
          >
            <option value="increase_revenue">Increase Revenue</option>
            <option value="improve_engagement">Improve Engagement</option>
            <option value="enhance_retention">Enhance User Retention</option>
            <option value="expand_user_base">Expand User Base</option>
          </select>
        </div>
        <div className="flex justify-end space-x-4">
          <button type="button" onClick={onClose} className="px-4 py-2 text-gray-600 hover:text-gray-800">
            Cancel
          </button>
          <button type="submit" className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700">
            Save Changes
          </button>
        </div>
      </form>
    </motion.div>
  </motion.div>
);

export default AIProductPlanner;
