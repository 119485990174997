import { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Transition } from "@headlessui/react";
import { FaBell } from "react-icons/fa";
import LogoutButton from "./LogoutButton";
import { jwtDecode } from "jwt-decode"; // Corrected import statement

export default function MobileMenu({
  user,
  setUser,
  notifications,
  handleNotificationClick,
}) {
  const navigate = useNavigate();
  const [mobileNavOpen, setMobileNavOpen] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const trigger = useRef(null);
  const mobileNav = useRef(null);

  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!mobileNav.current || !trigger.current) return;
      if (
        !mobileNavOpen ||
        mobileNav.current.contains(target) ||
        trigger.current.contains(target)
      )
        return;
      setMobileNavOpen(false);
    };
    document.addEventListener("click", clickHandler);
    return () => document.removeEventListener("click", clickHandler);
  });

  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!mobileNavOpen || keyCode !== 27) return;
      setMobileNavOpen(false);
    };
    document.addEventListener("keydown", keyHandler);
    return () => document.removeEventListener("keydown", keyHandler);
  });

  const handleNavigation = (path) => {
    navigate(path);
    setMobileNavOpen(false);
  };

  useEffect(() => {
    const token = localStorage.getItem("access");
    if (token && isTokenValid(token)) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
  }, []);

  const isTokenValid = (token) => {
    try {
      const decodedToken = jwtDecode(token);
      const currentDate = new Date();
      return decodedToken.exp * 1000 > currentDate.getTime();
    } catch (error) {
      return false;
    }
  };

  const handleLogout = () => {
    localStorage.removeItem("access");
    setIsLoggedIn(false);
    navigate("/signin/");
    setMobileNavOpen(false);
  };

  return (
    <div className="flex md:hidden">
      <button
        ref={trigger}
        className={`hamburger ${mobileNavOpen ? "active" : ""}`}
        aria-controls="mobile-nav"
        aria-expanded={mobileNavOpen}
        onClick={() => setMobileNavOpen(!mobileNavOpen)}
      >
        <span className="sr-only">Menu</span>
        <svg
          className="w-6 h-6 fill-current text-gray-900"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect y="4" width="24" height="2" />
          <rect y="11" width="24" height="2" />
          <rect y="18" width="24" height="2" />
        </svg>
      </button>

      <div ref={mobileNav}>
        <Transition
          show={mobileNavOpen}
          enter="transition ease-out duration-200 transform"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="transition ease-in duration-75 transform"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
          className="fixed inset-0 z-50 bg-white shadow-lg"
        >
          <div className="flex flex-col h-full">
            <div className="px-5 py-4 flex items-center justify-between">
              <span className="text-xl font-bold text-gray-900">Menu</span>
              <button
                className="text-gray-900"
                onClick={() => setMobileNavOpen(false)}
              >
                <svg
                  className="w-6 h-6 fill-current"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect y="11" width="24" height="2" />
                  <rect x="11" y="0" width="2" height="24" />
                </svg>
              </button>
            </div>
            <ul className="px-5 py-2 flex-grow overflow-y-auto">
              <li>
                <a
                  href="/feedback/"
                  className="text-gray-600 hover:text-gray-900 block px-3 py-2 transition duration-150 ease-in-out"
                >
                  AI Character Feedback
                </a>
              </li>
              <li>
                <a
                  href="/dashboard/"
                  className="text-gray-600 hover:text-gray-900 block px-3 py-2 transition duration-150 ease-in-out"
                >
                  100+ Page Report
                </a>
              </li>
              <li>
                <a
                  href="/credits/"
                  className="text-gray-600 hover:text-gray-900 block px-3 py-2 transition duration-150 ease-in-out"
                >
                  Credits & Recharge
                </a>
              </li>
              <li>
                <a
                  href="mailto:business@needfinderai.com"
                  className="text-gray-600 hover:text-gray-900 block px-3 py-2 transition duration-150 ease-in-out"
                >
                  Contact Us
                </a>
              </li>
              <li className="relative mt-2">
                <button
                  onClick={handleNotificationClick}
                  className="relative block w-full text-left px-3 py-2"
                >
                  <FaBell
                    className="text-gray-600 hover:text-gray-900"
                    size={24}
                  />
                  {notifications &&
                    notifications.length > 0 &&
                    notifications.some((notif) => !notif.read) && (
                      <span className="absolute top-0 right-0 w-2 h-2 bg-red-600 rounded-full"></span>
                    )}
                </button>
                {mobileNavOpen && (
                  <div className="absolute right-0 mt-2 w-full bg-white shadow-lg rounded-lg overflow-hidden z-10">
                    <ul>
                      {notifications.length === 0 ? (
                        <li className="p-4 text-gray-600">No notifications</li>
                      ) : (
                        notifications.map((notif, index) => (
                          <li
                            key={index}
                            className={`p-4 text-gray-600 ${
                              notif.read ? "bg-gray-200" : "bg-gray-100"
                            }`}
                          >
                            {notif.content.message} -{" "}
                            <a href={notif.content.link}>View</a>
                          </li>
                        ))
                      )}
                    </ul>
                  </div>
                )}
              </li>
              {user ? (
                <li className="mt-2">
                  <button
                    onClick={handleLogout}
                    className="block w-full text-left px-3 py-2 text-gray-600 hover:text-gray-900 bg-green-600 hover:bg-green-700 transition duration-150 ease-in-out rounded"
                  >
                    Logout
                  </button>
                </li>
              ) : (
                <li className="mt-2">
                  <button
                    onClick={() => navigate("/signin/")}
                    className="block w-full text-left px-3 py-2 text-gray-600 hover:text-gray-900 bg-green-600 hover:bg-green-700 transition duration-150 ease-in-out rounded"
                  >
                    Login
                  </button>
                </li>
              )}
            </ul>
          </div>
        </Transition>
      </div>
    </div>
  );
}
