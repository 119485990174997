import React, { useState, useEffect, useRef } from "react";
import { Play, Pause, Send, Star, X, RefreshCw, MessageCircle, Sparkles, Lightbulb, Users, Zap } from "lucide-react";
import axios from "./axiosConfig";
import ReconnectingWebSocket from "reconnecting-websocket";
import ding from "../sound/ding.mp3";
import flute from "../sound/pan_flute.mp3";
import { motion, AnimatePresence } from "framer-motion";

const getWebSocketUrl = (path) => {
  const baseWsUrl =
    process.env.NODE_ENV === "production"
      ? "wss://beproduction.needfinderai.com" // Replace with your production WebSocket URL
      : "ws://localhost:8000"; // Local development WebSocket URL

  return `${baseWsUrl}${path}`;
};

const CoworkingTab = ({ userDetails }) => {
  const [task, setTask] = useState("");
  const [firstName, setFirstName] = useState(userDetails.first_name);
  const [activeFounders, setActiveFounders] = useState([]);
  const [savedFounders, setSavedFounders] = useState([]);
  const [pomodoroTime, setPomodoroTime] = useState(25 * 60);
  const [isTimerRunning, setIsTimerRunning] = useState(false);
  const [chatMessage, setChatMessage] = useState("");
  const [groupChatMessages, setGroupChatMessages] = useState([]);
  const [privateChatMessages, setPrivateChatMessages] = useState({});
  const [activeChat, setActiveChat] = useState(null);
  const [ws, setWs] = useState(null);
  const [notifications, setNotifications] = useState([]);
  const [teamId, setTeamId] = useState(null);
  const userId = userDetails.id; // Correctly assign userId
  const token = localStorage.getItem("access");
  const [groupWs, setGroupWs] = useState(null); // For group chat WebSocket
  const [directWs, setDirectWs] = useState(null); // For 1-1 direct chat WebSocket
  const chatWindowRef = useRef(null);
  const [participants, setParticipants] = useState([]);
  const wsRef = useRef(null); // Store WebSocket instance in a ref
  // Add this state to track the selected pomodoro duration
  const [selectedTime, setSelectedTime] = useState(25 * 60); // Default to 25 min
  const [completedPomodoros, setCompletedPomodoros] = useState(0);
  const [totalFocusedTime, setTotalFocusedTime] = useState(0);
  const [dailyFocusedTime, setdailyFocusedTime] = useState(0);
  const [showCongratsMessage, setShowCongratsMessage] = useState(false);

  // Add this useEffect to handle scrolling on new messages and page load
  useEffect(() => {
    if (chatWindowRef.current) {
      chatWindowRef.current.scrollTop = chatWindowRef.current.scrollHeight;
    }
  }, [groupChatMessages, privateChatMessages, activeChat]); // Trigger scroll when group or direct messages change

  // Fetch the user's current Pomodoro count when the component loads
  useEffect(() => {
    const fetchPomodoroCount = async () => {
      try {
        const response = await axios.get("profiles/get_pomodoro_count/");
        setCompletedPomodoros(response.data.total_completed);
        setTotalFocusedTime(response.data.total_focused_time);
        setdailyFocusedTime(response.data.daily_focused_time); // Fetch daily focused time
      } catch (error) {
        console.error("Failed to fetch Pomodoro count:", error);
      }
    };

    fetchPomodoroCount();
  }, []);

  useEffect(() => {
    if (isTimerRunning && pomodoroTime > 0) {
      const timer = setTimeout(() => setPomodoroTime(pomodoroTime - 1), 1000);
      return () => clearTimeout(timer);
    } else if (pomodoroTime === 0) {
      handlePomodoroComplete();
    }
  }, [isTimerRunning, pomodoroTime]);

  useEffect(() => {
    const fetchSavedFounders = async () => {
      try {
        const response = await axios.get("profiles/get_saved_founders/");
        setSavedFounders(response.data);
      } catch (error) {
        console.error("Failed to fetch saved founders:", error);
      }
    };

    fetchSavedFounders(); // Fetch saved founders on component load
  }, []);

  useEffect(() => {
    if (teamId && !wsRef.current) {
      console.log(`Creating WebSocket for teamId: ${teamId}`);
      const ws = new ReconnectingWebSocket(getWebSocketUrl(`/ws/chat/team_chat/${teamId}/?token=${token}`));

      // const ws = new ReconnectingWebSocket(`ws://localhost:8000/ws/chat/team_chat/${teamId}/?token=${token}`);

      setGroupWs(ws); // Set groupWs here

      wsRef.current = ws; // Still store it in wsRef if needed

      ws.onopen = () => {
        console.log(`WebSocket connection opened for teamId: ${teamId}`);
        ws.send(JSON.stringify({ type: "fetch_previous_team_messages" }));
      };

      ws.onmessage = (event) => {
        const data = JSON.parse(event.data);

        if (data.type === "new_team_message") {
          setGroupChatMessages((prev) => {
            const isDuplicate = prev.some((msg) => msg.message === data.message && msg.timestamp === data.timestamp);
            if (!isDuplicate) {
              return [...prev, data];
            }
            return prev;
          });
        } else if (data.type === "fetch_previous_team_messages") {
          setGroupChatMessages(data.messages);
        } else if (data.type === "user_joined") {
          // Log the data when a user joins
          console.log("User joined event received:", data);

          // Update the group chat messages with the system message
          setGroupChatMessages((prevMessages) => [
            ...prevMessages,
            { sender: "System", message: `${data.user} just joined the cafe.`, timestamp: new Date(data.timestamp).toLocaleString() },
          ]);

          // Log the active members to verify the update
          if (data.active_members) {
            console.log("Updated active members list:", data.active_members);
            setActiveFounders(data.active_members); // Dynamically update active coworkers without page reload
          }
        }
      };

      ws.onclose = () => {
        console.log(`WebSocket connection closed for teamId: ${teamId}`);
        wsRef.current = null;
      };

      return () => {
        if (wsRef.current) {
          console.log(`Cleaning up WebSocket for teamId: ${teamId}`);
          wsRef.current.close();
          wsRef.current = null;
        }
      };
    }
  }, [teamId, token]);

  useEffect(() => {
    // WebSocket connection for notifications
    const notificationSocket = new ReconnectingWebSocket(getWebSocketUrl(`/ws/notifications/${userId}/?token=${token}`));

    // const notificationSocket = new ReconnectingWebSocket(`ws://localhost:8000/ws/notifications/${userId}/?token=${token}`);

    notificationSocket.onmessage = (event) => {
      const data = JSON.parse(event.data);
      if (data.type === "notification") {
        setNotifications((prev) => [...prev, data]);
        alert(data.content);
      } else if (data.type === "fetch_notifications") {
        setNotifications(data.notifications);
      }
    };

    setWs(notificationSocket);

    // Fetch initial notifications
    notificationSocket.onopen = () => {
      notificationSocket.send(JSON.stringify({ type: "fetch_notifications" }));
    };

    return () => notificationSocket.close();
  }, [userId]);

  const fetchUserTeam = async () => {
    try {
      const response = await axios.get("teams/fetch_user_team/");
      if (response.data.team_id) {
        setTeamId(response.data.team_id);
        await handleJoinSession(response.data.team_id);
      } else {
        await handleJoinSession(); // Call handleJoinSession to assign the team
      }
    } catch (error) {
      console.error("Failed to fetch user team:", error);
    }
  };
  const handleJoinSession = async (existingTeamId = null) => {
    if (!existingTeamId) {
      const response = await axios.post("teams/assign_team/"); // No room_id needed
      const teamId = response.data.team_id;
      setTeamId(teamId);
      existingTeamId = teamId; // Ensure correct assignment here
    }

    // Fetch team details to get members
    try {
      const teamDetailsResponse = await axios.get(`teams/${existingTeamId}/fetch_team_details/`);
      setActiveFounders(teamDetailsResponse.data);
    } catch (error) {
      console.error("Failed to fetch team details:", error);
    }
  };

  const toggleTimer = () => {
    const startSound = document.getElementById("start-sound");
    const endSound = document.getElementById("end-sound");

    if (!isTimerRunning) {
      // Play start music and ensure it loops
      startSound.play();
    } else {
      // Stop the start sound
      startSound.pause();
      startSound.currentTime = 0; // Reset the sound
    }

    setPomodoroTime(selectedTime);
    setIsTimerRunning(!isTimerRunning);
    if (ws) {
      ws.send(JSON.stringify({ action: isTimerRunning ? "complete_pomodoro" : "start_pomodoro" }));
    }
  };

  // Modify `handlePomodoroComplete` to play the "ding" sound
  const handlePomodoroComplete = async () => {
    setIsTimerRunning(false);

    const startSound = document.getElementById("start-sound");
    const endSound = document.getElementById("end-sound");

    // Stop the background sound and play the ending sound
    startSound.pause();
    startSound.currentTime = 0;
    endSound.play();

    // Show the congratulatory message
    setShowCongratsMessage(true);

    // Hide the message after 3-5 seconds
    setTimeout(() => {
      setShowCongratsMessage(false);
    }, 3000); // Adjust the time if needed

    try {
      const response = await axios.post("profiles/track_pomodoro/", {
        session_time: selectedTime / 60, // Send the session duration in minutes
      });

      setCompletedPomodoros(response.data.total_completed); // Update Pomodoro count
      setTotalFocusedTime(response.data.total_focused_time); // Update total focused time
      setdailyFocusedTime(response.data.daily_focused_time); // Update daily focused time
    } catch (error) {
      console.error("Failed to track Pomodoro:", error);
    }
  };

  const resetTimer = () => {
    setPomodoroTime(25 * 60);
    setIsTimerRunning(false);
  };

  const handleChatSubmit = (e) => {
    e.preventDefault();

    if (chatMessage.trim()) {
      if (activeChat === null && groupWs) {
        // Sending message to group chat
        groupWs.send(
          JSON.stringify({
            type: "new_team_message",
            message: chatMessage,
            sender: userId,
          })
        );
      } else if (activeChat !== null && directWs) {
        // Sending message to direct chat
        directWs.send(
          JSON.stringify({
            type: "new_direct_message",
            message: chatMessage,
            sender: userId,
          })
        );
      }

      // Clear the message input field after sending
      setChatMessage("");
    }
  };

  // const toggleSaveFounder = async (founder) => {
  //   try {
  //     const response = await axios.post("profiles/toggle_save_member/", { member_id: founder.id });

  //     if (response.data.status === "member saved") {
  //       setSavedFounders([...savedFounders, founder]); // Add to savedFounders
  //       setActiveFounders(activeFounders.filter((f) => f.id !== founder.id)); // Remove from activeFounders
  //     } else if (response.data.status === "member unsaved") {
  //       setSavedFounders(savedFounders.filter((f) => f.id !== founder.id)); // Remove from savedFounders
  //       setActiveFounders([...activeFounders, founder]); // Add back to activeFounders
  //     }
  //   } catch (error) {
  //     console.error("Failed to toggle save founder:", error);
  //   }
  // };

  // Start a 1-1 chat with a specific founder

  const toggleSaveFounder = async (founder) => {
    try {
      const response = await axios.post("profiles/toggle_save_member/", { member_id: founder.id });

      if (response.data.status === "member saved") {
        // Using functional updates to prevent issues when state is based on the previous state
        setSavedFounders((prevSaved) => [...prevSaved, founder]);
        setActiveFounders((prevActive) => prevActive.filter((f) => f.id !== founder.id));
      } else if (response.data.status === "member unsaved") {
        // Again using functional updates to prevent duplicates
        setSavedFounders((prevSaved) => prevSaved.filter((f) => f.id !== founder.id));
        setActiveFounders((prevActive) => {
          // Only add back to Active if they aren't already there to prevent duplication
          if (!prevActive.some((f) => f.id === founder.id)) {
            return [...prevActive, founder];
          }
          return prevActive; // Return the same list if they already exist
        });
      }
    } catch (error) {
      console.error("Failed to toggle save founder:", error);
    }
  };

  const startPrivateChat = (founderId) => {
    setActiveChat(founderId);

    // WebSocket connection for direct chat
    const ws = new ReconnectingWebSocket(getWebSocketUrl(`/ws/direct_chat/${userId}_${founderId}/?token=${token}`));

    // const ws = new ReconnectingWebSocket(`ws://localhost:8000/ws/direct_chat/${userId}_${founderId}/?token=${token}`);

    // Set directWs here
    setDirectWs(ws);

    // Fetch previous direct messages when WebSocket opens
    ws.onopen = () => {
      ws.send(JSON.stringify({ type: "fetch_previous_direct_messages" }));
    };

    ws.onmessage = (event) => {
      const data = JSON.parse(event.data);

      if (data.type === "new_direct_message") {
        setPrivateChatMessages((prev) => {
          const messages = prev[founderId] || [];
          const isDuplicate = messages.some((msg) => msg.message === data.message && msg.timestamp === data.timestamp);
          if (!isDuplicate) {
            return {
              ...prev,
              [founderId]: [...messages, data],
            };
          }
          return prev;
        });
      } else if (data.type === "fetch_previous_direct_messages") {
        setPrivateChatMessages((prev) => ({
          ...prev,
          [founderId]: data.messages,
        }));
      }
    };

    // Clean up WebSocket when switching chats
    return () => ws.close();
  };

  // Return to the group chat from a direct 1-1 chat
  const returnToGroupChat = () => {
    if (directWs) directWs.close(); // Close the direct chat WebSocket
    setActiveChat(null);

    // Reconnect to group chat WebSocket if not connected already
    if (teamId && groupWs === null) {
      const groupWsInstance = new ReconnectingWebSocket(`ws://localhost:8000/ws/chat/team_chat/${teamId}/?token=${token}`);
      setGroupWs(groupWsInstance);
    }
  };

  const removeSavedFounder = (founderId) => {
    setSavedFounders(savedFounders.filter((f) => f.id !== founderId));
    const { [founderId]: _, ...rest } = privateChatMessages;
    setPrivateChatMessages(rest);
    if (activeChat === founderId) {
      setActiveChat(null);
    }
  };

  const handleTaskUpdate = async () => {
    try {
      const response = await axios.post("profiles/update_task/", { task });
      setTask(response.data.task);
    } catch (error) {
      console.error("Failed to update task:", error);
    }
  };

  useEffect(() => {
    fetchUserTeam();
  }, []);

  const FeatureIcon = ({ Icon, text }) => (
    <div className="flex items-center space-x-2">
      <Icon className="w-5 h-5" />
      <span className="text-sm">{text}</span>
    </div>
  );

  return (
    <div className="max-w-6xl mx-auto mt-8 p-3 bg-white rounded-2xl shadow-xl">
      {/* Audio for join sound */}
      {/* <audio id="join-sound" src={ding}></audio> */}
      <audio id="start-sound" src={flute} loop></audio>
      <audio id="end-sound" src={ding}></audio>

      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-4 py-2 space-y-2 mb-8">
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          className="bg-gradient-to-br from-blue-800 via-indigo-900 to-purple-900 rounded-3xl p-8 sm:p-12 text-white shadow-xl transform transition-all duration-500 hover:shadow-2xl hover:scale-[1.02]"
        >
          {/* Section Title */}
          <div className="flex items-center space-x-4 mb-8">
            <Sparkles className="w-10 h-10 text-yellow-300 animate-pulse" />
            <h2 className="text-3xl sm:text-3xl font-bold tracking-tight">What to Expect In Virtual Coworking Cafe ?</h2>
          </div>

          {/* Feature Grid */}
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
            {/* <FeatureIcon Icon={Zap} text="Add your product" /> */}
            <FeatureIcon Icon={Users} text=" Connect with 5 new makers every day, expanding your professional circle effortlessly." />
            <FeatureIcon
              Icon={Lightbulb}
              text="Work together for the whole day. The built-in Pomodoro timer helps each of you stay focused and productive, even when working remotely. "
            />
            <FeatureIcon
              Icon={Zap}
              text="Build your network by saving contacts of inspiring makers, fostering long-term professional relationships and potential collaborations. "
            />
            {/* <FeatureIcon Icon={Zap} text="Add your product" /> */}
          </div>
        </motion.div>
      </div>

      {activeFounders.length === 0 ? (
        <div className="max-w-md mx-auto mb-8">
          <h2 className="text-2xl font-semibold mb-6 text-gray-800">What's your name?</h2>
          <input
            type="text"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            placeholder="E.g., John"
            className="w-full px-6 py-4 border border-gray-300 rounded-full focus:outline-none focus:ring-2 focus:ring-blue-500 text-lg mb-4"
          />
          <h2 className="text-2xl font-semibold mb-6 text-gray-800">What are you working on today?</h2>
          <input
            type="text"
            value={task}
            onChange={(e) => setTask(e.target.value)}
            placeholder="E.g., Customer interviews"
            className="w-full px-6 py-4 border border-gray-300 rounded-full focus:outline-none focus:ring-2 focus:ring-blue-500 text-lg mb-4"
          />
          <button
            onClick={handleJoinSession}
            disabled={!task || !firstName}
            className="w-full px-6 py-4 bg-blue-600 text-white rounded-full hover:bg-blue-700 transition-colors disabled:bg-gray-300 text-lg font-semibold"
          >
            Join Session
          </button>
          <button
            onClick={handleTaskUpdate}
            className="w-full mt-4 px-6 py-4 bg-green-600 text-white rounded-full hover:bg-green-700 transition-colors text-lg font-semibold"
          >
            Update Task
          </button>
        </div>
      ) : (
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
          <div className="lg:col-span-2 space-y-8">
            <div className="bg-blue-50 p-8 rounded-2xl shadow-sm">
              <h2 className="text-3xl font-semibold mb-6 text-gray-900">Focus Session</h2>
              <div className="text-7xl font-bold mb-8 text-center text-blue-600">
                {Math.floor(pomodoroTime / 60)}:{(pomodoroTime % 60).toString().padStart(2, "0")}
              </div>
              {/* <div className="flex justify-center space-x-6 mb-8">
                <button
                  onClick={toggleTimer}
                  className={`px-10 py-4 rounded-full text-white text-xl font-semibold ${
                    isTimerRunning ? "bg-red-500 hover:bg-red-600" : "bg-green-500 hover:bg-green-600"
                  } transition-colors`}
                >
                  {isTimerRunning ? <Pause size={28} /> : <Play size={28} />}
                </button>
                <button
                  onClick={resetTimer}
                  className="px-10 py-4 bg-gray-200 text-gray-700 rounded-full hover:bg-gray-300 transition-colors text-xl font-semibold"
                >
                  <RefreshCw size={28} />
                </button>
              </div> */}
              {/* // Modify the JSX where you render the Pomodoro timer controls: */}

              <div className="flex justify-center space-x-6 mb-8">
                <select
                  value={selectedTime}
                  onChange={(e) => setSelectedTime(Number(e.target.value))}
                  className="px-4 py-2 border rounded-full text-lg bg-gray-100"
                >
                  <option value={1 * 60}>1 min</option>
                  <option value={5 * 60}>5 min</option>
                  <option value={10 * 60}>10 min</option>
                  <option value={25 * 60}>25 min (Default)</option>
                  <option value={30 * 60}>30 min</option>
                  <option value={50 * 60}>50 min</option>
                  <option value={90 * 60}>90 min</option>
                </select>
                <button
                  onClick={toggleTimer}
                  className={`px-10 py-4 rounded-full text-white text-xl font-semibold ${isTimerRunning ? "bg-red-500" : "bg-green-500"}`}
                >
                  {isTimerRunning ? <Pause size={28} /> : <Play size={28} />}
                </button>
                {/* <button onClick={resetTimer} className="px-10 py-4 bg-gray-200 rounded-full">
                  <RefreshCw size={28} />
                </button> */}
              </div>
              {/* <div className="flex justify-center items-center space-x-3 text-gray-700 text-lg">
                <span>
                  Break in: {Math.floor((selectedTime - pomodoroTime) / 60)}:{(pomodoroTime % 60).toString().padStart(2, "0")}
                </span>
              </div> */}

              {/* Great work message */}
              <div className="mt-2 text-center text-lg text-green-600">
                {showCongratsMessage && <div className="animate-pulse transition-opacity duration-500">Great work 👍</div>}
              </div>
              {/* <div className="mt-6 text-center text-gray-600 text-lg">{completedPomodoros} Pomodoros completed</div> */}
              {/* <div className="mt-2 text-center text-gray-600 text-lg">{totalFocusedTime} minutes focused overall</div> */}
              <div className="mt-2 text-center text-gray-600 text-lg">{dailyFocusedTime} minutes focused today</div>
            </div>

            <div className="bg-blue-50 p-8 rounded-2xl shadow-sm">
              <h2 className="text-3xl font-semibold mb-6 text-gray-900" ref={chatWindowRef}>
                {activeChat === null ? "Group Chat" : `Chat with ${savedFounders.find((f) => f.id === activeChat)?.first_name}`}
              </h2>
              {activeChat !== null && (
                <button
                  onClick={returnToGroupChat}
                  className="mb-6 px-6 py-3 bg-blue-200 text-blue-700 rounded-full hover:bg-blue-300 transition-colors text-lg font-semibold"
                >
                  Return to Group Chat
                </button>
              )}
              <div className="bg-white p-6 rounded-xl h-80 overflow-y-auto mb-6 border border-blue-100 shadow-inner" ref={chatWindowRef}>
                {(activeChat === null ? groupChatMessages : privateChatMessages[activeChat] || []).map((msg, index) => (
                  <div key={index} className="mb-4">
                    <span className="font-bold text-gray-900">{msg.sender}: </span>
                    <span className="text-gray-700">{msg.message}</span>
                    <span className="text-xs text-gray-500 ml-2">{new Date(msg.timestamp).toLocaleString()}</span>
                  </div>
                ))}
              </div>
              <form onSubmit={handleChatSubmit} className="flex">
                <input
                  type="text"
                  value={chatMessage}
                  onChange={(e) => setChatMessage(e.target.value)}
                  placeholder="Type your message..."
                  className="flex-grow px-6 py-4 border border-blue-300 rounded-l-full focus:outline-none focus:ring-2 focus:ring-blue-500 text-lg"
                />
                <button type="submit" className="px-8 py-4 bg-blue-600 text-white rounded-r-full hover:bg-blue-700">
                  <Send size={24} />
                </button>
              </form>
            </div>
          </div>

          <div className="space-y-8">
            <div className="bg-blue-50 p-8 rounded-2xl shadow-sm">
              <h2 className="text-3xl font-semibold mb-6 text-gray-900">Today's Coworkers</h2>
              <div className="flex items-center mb-4 text-sm text-blue-600 bg-blue-100 p-3 rounded-xl">
                <span>Coworkers change daily. Save them to stay connected!</span>
              </div>
              {/* <div className="mb-4">
                <input
                  type="text"
                  placeholder="Search coworkers..."
                  className="w-full px-4 py-2 border border-blue-300 rounded-full focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
              </div> */}
              <div className="space-y-4">
                {activeFounders.map((founder) => (
                  <div
                    key={founder.id}
                    className="flex items-center justify-between bg-white p-4 rounded-xl border border-blue-100 shadow-sm"
                  >
                    <div>
                      <div className="font-semibold text-gray-900">{founder.first_name}</div>
                      <div className="text-sm text-gray-600">{founder.task}</div>
                    </div>
                    {founder.name !== firstName && (
                      <button
                        onClick={() => toggleSaveFounder(founder)}
                        className={`text-blue-500 hover:text-blue-700 ${
                          savedFounders.some((f) => f.id === founder.id) ? "text-yellow-500" : ""
                        }`}
                        title={savedFounders.some((f) => f.id === founder.id) ? "Unsave coworker" : "Save coworker"}
                      >
                        <Star size={24} />
                      </button>
                    )}
                  </div>
                ))}
              </div>
            </div>

            <div className="bg-green-50 p-8 rounded-2xl shadow-sm">
              <h2 className="text-3xl font-semibold mb-6 text-gray-900">Saved Contacts</h2>

              {/* Scrollable section for saved founders */}
              <div className="max-h-64 overflow-y-auto space-y-4">
                {" "}
                {/* Set height limit and make it scrollable */}
                {savedFounders.map((founder) => (
                  <div
                    key={founder.id}
                    className="flex items-center justify-between bg-white p-4 rounded-xl border border-green-100 shadow-sm"
                  >
                    <span className="text-gray-900 font-medium">{founder.first_name}</span>
                    <div className="space-x-3">
                      <button onClick={() => startPrivateChat(founder.id)} className="text-blue-500 hover:text-blue-700">
                        <MessageCircle size={24} />
                      </button>
                      <button onClick={() => toggleSaveFounder(founder)} className="text-red-500 hover:text-red-700">
                        <X size={24} />
                      </button>
                    </div>
                  </div>
                ))}
              </div>
              {/* <div className="space-y-4">
                {savedFounders.map((founder) => (
                  <div
                    key={founder.id}
                    className="flex items-center justify-between bg-white p-4 rounded-xl border border-green-100 shadow-sm"
                  >
                    <span className="text-gray-900 font-medium">{founder.first_name}</span>
                    <div className="space-x-3">
                      <button onClick={() => startPrivateChat(founder.id)} className="text-blue-500 hover:text-blue-700">
                        <MessageCircle size={24} />
                      </button>
                      <button onClick={() => removeSavedFounder(founder.id)} className="text-red-500 hover:text-red-700">
                        <X size={24} />
                      </button>
                    </div>
                  </div>
                ))}
              </div> */}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CoworkingTab;
