import React, { useState, useEffect } from "react";
import { jwtDecode } from "jwt-decode";

const LogoutButton = (props) => {
  const [isLoggedOut, setIsLoggedOut] = useState(false);
  const [isTokenValid, setIsTokenValid] = useState(false);

  const checkTokenValidity = () => {
    const token = localStorage.getItem("access");
    if (token) {
      const decodedToken = jwtDecode(token);
      const currentDate = new Date();

      // If the token has not expired
      if (decodedToken.exp * 1000 >= currentDate.getTime()) {
        return true;
      }
      return false;
    }
    return false;
  };
  useEffect(() => {
    setIsTokenValid(checkTokenValidity());
  }, []);

  const handleLogout = () => {
    // Clear local storage
    localStorage.clear();

    // Reset user state
    if (props.setUser) {
      props.setUser(null);
    }

    // Redirect to the login page
    window.location.href = "/signin/";
  };

  return (
    <button
      className="bg-green-700 hover:bg-green-500 text-white hover:text-white px-4 py-2  flex items-center transition duration-150 ease-in-out shadow"
      onClick={handleLogout}
      {...props}
    >
      {isTokenValid ? "Logout" : "Log In"}
    </button>
  );
};

export default LogoutButton;
